<ng-container *ngIf="parentForm" [formGroup]="parentForm">
    <input type="hidden" [formControlName]="data.name"/>
</ng-container>
<div class="custom-slider">
    <ngx-slider 
        [(value)]="data.min" 
        [(highValue)]="data.max" 
        [options]="options"
        (userChangeEnd)="change($event)"
    ></ngx-slider>
</div>
<!-- <ng-container *ngIf="!parentForm">
    <div class="custom-slider">
        <ngx-slider 
            [(value)]="data.min" 
            [(highValue)]="data.max" 
            [options]="options"
            (userChangeEnd)="change($event)"
        ></ngx-slider>
    </div>
</ng-container> -->