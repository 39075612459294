import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabbedComponent } from './tabbed.component';
import { RoutingService } from '../../backbone/routing.service';
import { MatTabsModule } from '@angular/material/tabs';
import { SlotModule } from '../slot/slot.module';
import { PipesModule } from '../../backbone/pipes/pipes.module';
import { MatBadgeModule } from '@angular/material/badge';
import { PrimitiveModule } from '../primitive/primitive.module';



@NgModule({
  declarations: [TabbedComponent],
  imports: [
    CommonModule,
    SlotModule,
    PipesModule,
    PrimitiveModule,
    MatTabsModule,
    MatBadgeModule
  ]
})
export class TabbedModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('TabbedComponent', TabbedComponent);
  }
}
