import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ApiService } from '../../backbone/api.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss']
})
export class AddressAutocompleteComponent implements OnInit {
  autocomplete: google.maps.places.Autocomplete;
  @Input() public data: any;
  @Input() public parentForm: UntypedFormGroup;

  constructor(
    httpClient: HttpClient,
    api: ApiService
  ) {
    const environment = api.getEnv();
    httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key='
    + environment.mapApiToken + '&libraries=places', 'callback')
      .pipe(map(() => {
            if (this.data) {
              this.initAutocomplete();
            }
          }
        ),
        catchError(() => of(false))
      ).subscribe(() => {});
   }

  ngOnInit() {
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete') as HTMLInputElement,
      {
        types: ['establishment'],
        componentRestrictions: {country: this.data.searchByCountries},
        fields: ['place_id', 'geometry', 'name']
      }
    );

    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place = this.autocomplete.getPlace();
      const myLatlng = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      this.parentForm.get(this.data.name).setValue(myLatlng);
    });
  }

}
