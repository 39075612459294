import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, map } from 'rxjs';

export const GOOGLE_TRANSLATE_API_KEY: InjectionToken<string> = new InjectionToken('google-key');

@Injectable({
  providedIn: 'root'
})
export class GoogleTranslateService {
  private googleEndPoint = 'https://translation.googleapis.com/language/translate/v2';
  constructor(private http: HttpClient,
              @Inject(GOOGLE_TRANSLATE_API_KEY) private apiKey: string) { }

  public translate(
      sourceLang: string,
      targetLang: string,
      text: string
    ): Observable<any> {
    return this.http.post(`${this.googleEndPoint}?key=${this.apiKey}`, {
      source: sourceLang,
      target: targetLang,
      q: text
    }).pipe(
      map(response => (response as any).data.translations[0].translatedText)
    );
  }
}
