import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { CommunicationService } from '../../../backbone/communication.service';
import { EventBusService } from '../../../backbone/event-bus.service';
import { LanguageService } from '../../../backbone/language.service';
import { MetaService } from '../../../backbone/meta.service';
import { SlotItem } from '../../slot/slot-item';
import { BaseLayoutComponent } from '../base-layout.component';


interface Slots {
  header: SlotItem[];
  menu: SlotItem[];
  main: SlotItem[];
  footer: SlotItem[];
}

@Component({
  selector: 'app-centered-header-main-footer',
  templateUrl: './centered-header-main-footer.component.html',
  styleUrls: ['./centered-header-main-footer.component.scss']
})
export class CenteredHeaderMainFooterComponent extends BaseLayoutComponent implements OnInit, OnDestroy {
  public slots: Slots;
  public classes: {
    header?: string;
    menu?: string;
    main?: string;
    footer?: string;
  };

  constructor(
    public route: ActivatedRoute,
    public comm: CommunicationService,
    public metaService: MetaService,
    public language: LanguageService,
    public eventBus: EventBusService,
  ) {
    super(route, comm, metaService, language, eventBus);
  }

  ngOnInit(): void {
    this.route.data.pipe(take(1)).subscribe(data => {
      this.slots = data.slots;
      this.classes = data.classes;
    });
  }
}
