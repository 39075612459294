<div class="page">
  <app-slot *ngIf="slots.top" [items]="slots.top"></app-slot>
  <app-slot *ngIf="slots.left" [items]="slots.left"></app-slot>
  <app-slot
    *ngIf="slots.subtop"
    [items]="slots.subtop"
    class="shadowed"
  ></app-slot>
  <mat-sidenav-container>
    <mat-sidenav-content>
      <div class="content">
        <app-slot [items]="slots.main"></app-slot>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-slot *ngIf="slots.bottom" [items]="slots.bottom"></app-slot>
</div>
