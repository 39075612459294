import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { CommunicationService } from '../../../backbone/communication.service';
import { StateService } from '../../../backbone/state.service';
import { SlotItem } from '../../slot/slot-item';

interface Slots {
  main: SlotItem[];
}

@Component({
  selector: 'app-center-screen',
  templateUrl: './center-screen.component.html',
  styleUrls: ['./center-screen.component.scss']
})
export class CenterScreenComponent implements OnInit, OnDestroy {
  slots: Slots;
  logo = true;
  class;
  wrapperClass;
  logoWrapperClass;

  constructor(
    private route: ActivatedRoute,
    public state: StateService,
    private comm: CommunicationService
  ) { }

  ngOnInit(): void {
    this.route.data.pipe(take(1)).subscribe(data => {
      this.slots = data.slots;
      if (typeof data.logo !== 'undefined') {
        this.logo = data.logo;
      }
      if (typeof data.class !== 'undefined') {
        this.class = data.class;
      }
      if (typeof data.wrapperClass !== 'undefined') {
        this.wrapperClass = data.wrapperClass;
      }
      if (typeof data.logoWrapperClass !== 'undefined') {
        this.logoWrapperClass = data.logoWrapperClass;
      }
    });
  }
  ngOnDestroy(): void {
    this.comm.resetChannels();
  }
}
