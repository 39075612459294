<ng-container *ngIf="menuItems[topId]">
  <!-- HAS SUB MENU -->
  <ng-container *ngIf="data.hover">
    <!-- OPEN ON HOVER -->
    <button
      class="hover-trigger"
      *ngIf="data.item.title"
      mat-button
      [routerLink]="getRouterLink(data.item)"
      (mouseenter)="openMenu()"
    >
      {{ data.item.title }}
      <span class="trigger" [matMenuTriggerFor]="panel.menu"></span>
    </button>
  </ng-container>
  <ng-container *ngIf="!data.hover">
    <!-- OPEN ON CLICK -->
    <button *ngIf="data.item" mat-button [matMenuTriggerFor]="panel.menu">
      {{ data.item.title }}
    </button>
    <button
      *ngIf="data.icon"
      [ngClass]="{ hamburger: data.hamburger }"
      mat-icon-button
      [matMenuTriggerFor]="panel.menu"
    >
      <mat-icon>{{ data.icon }}</mat-icon>
    </button>
    <span
      *ngIf="!data.item && !data.icon"
      [matMenuTriggerFor]="panel.menu"
    ></span>
  </ng-container>

  <!-- SUB MENU PANEL -->
  <app-menu-panel
    #panel
    [data]="{ 
      link: data.link, 
      class: data.class, 
      xPosition: data.xPosition, 
      scrollToElement: data.scrollToElement, 
      triggers: triggers
    }"
    [menuItems]="menuItems"
    [parentItem]="{ id: topId }"
  ></app-menu-panel>
</ng-container>

<!-- NO SUB MENU -->
<ng-container *ngIf="!data.scrollToElement">
  <button
    *ngIf="data.item && !menuItems[topId]"
    mat-button
    [routerLink]="getRouterLink(data.item)"
  >
    {{ data.item.title }}
  </button>
</ng-container>
<ng-container *ngIf="data.scrollToElement">
  <button
    *ngIf="data.item && !menuItems[topId]"
    mat-button
    (click)="scrollService.scrollToElement(data.item.url)"
  >
    {{ data.item.title }}
  </button>
</ng-container>
