<div class="complete-circle">
  <div class="inner-circle">
    <span *ngIf="showPercent && !data.label">
      {{ percent }}%
    </span>
    <span *ngIf="!showPercent && data.label">
      {{ language.getLabel(data.label) }}
    </span>
    <div class="spinner">
      <mat-progress-spinner
        [value]="percent"
        [mode]="data.mode"
        [strokeWidth]="data.strokeWidth"
      ></mat-progress-spinner>
    </div>
  </div>
</div>
