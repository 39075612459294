import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {Observable} from 'rxjs';
import { LanguageService } from '../../../backbone/language.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @Input() public data: any = {};
  @Input() public parentForm: UntypedFormGroup;

  constructor(public language: LanguageService) { }

  ngOnInit() {
  }

  change(slider) {
    if (typeof this.data.change === 'function') {
      if (!this.data.changeParams) {
        this.data.changeParams = {};
      }

      this.data.changeParams.value = slider.value;
      this.data.changeParams.event = 'change';

      const result = this.data.change(this.data.changeParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }
}
