<mat-form-field appearance="fill" *ngIf="parentForm" [formGroup]="parentForm">
  <mat-label>{{ language.getLabel(data.label) }}</mat-label>
  <input
    matInput
    [formControlName]="data.name"
    [placeholder]="language.getLabel(data.placeholder)"
    [readonly]="data.inputDisabled"
    [format]="data.format"
    [ngxTimepicker]="picker"
  />
  <button
    mat-icon-button
    [ngClass]="{ open: opened }"
    type="button"
    matSuffix
    (click)="picker.open()"
  >
    <mat-icon>schedule</mat-icon>
  </button>
  <ngx-material-timepicker
    #picker
    [enableKeyboardInput]="data.enableKeyboardInput"
    (opened)="open()"
    (closed)="close()"
  ></ngx-material-timepicker>
</mat-form-field>
