import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionBarComponent } from './action-bar.component';
import { RoutingService } from '../../backbone/routing.service';
import { SlotModule } from '../slot/slot.module';
import { PipesModule } from '../../backbone/pipes/pipes.module';



@NgModule({
  declarations: [ActionBarComponent],
  imports: [
    CommonModule,
    SlotModule,
    PipesModule
  ]
})
export class ActionBarModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('ActionBarComponent', ActionBarComponent);
  }
}
