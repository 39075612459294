<div *ngIf="parentForm" [formGroup]="parentForm" [class]="data.class">
  <ng-container *ngIf="!data.options; else optionsTemplate">
    <mat-checkbox
      [color]="data.color"
      [labelPosition]="data.labelPosition"
      [disabled]="data.disabled"
      [formControlName]="data.name"
      (change)="change()"
    >
      {{ language.getLabel(data.label) }}
    </mat-checkbox>
  </ng-container>
</div>
<ng-container *ngIf="!parentForm">
  <mat-checkbox
    [color]="data.color"
    [(ngModel)]="data.checked"
    [labelPosition]="data.labelPosition"
    [disabled]="data.disabled"
    (change)="change()"
  >
    {{ language.getLabel(data.label) }}
  </mat-checkbox>
</ng-container>

<ng-template #optionsTemplate>
    <div [class]="data.labelClass">{{ language.getLabel(data.label) }}</div>
    <input type="hidden" [formControlName]="data.name" />
    <mat-checkbox
      *ngFor="let option of data.options"
      [color]="option.color"
      [labelPosition]="option.labelPosition"
      [disabled]="option.disabled"
      [value]="option.value"
      [checked]="option.checked"
      [class]="option.class"
      (change)="change(option)"
    >
      {{ language.getLabel(option.label) }}
      <span class="facetCount" *ngIf="option.facetCount">[{{option.facetCount}}]</span>
    </mat-checkbox>
</ng-template>