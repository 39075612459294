import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatStepperModule } from '@angular/material/stepper';
import { RoutingService } from '../../backbone/routing.service';
import { SlotModule } from '../slot/slot.module';
import { PipesModule } from '../../backbone/pipes/pipes.module';


@NgModule({
  declarations: [
    StepperComponent
  ],
  imports: [
    CommonModule,
    SlotModule,
    PipesModule,
    CdkStepperModule,
    MatStepperModule
  ]
})
export class StepperModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('StepperComponent', StepperComponent);
  }
}
