import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { StateService } from '../state.service';
import { LoaderService } from '../loader.service';
import { ApiService } from '../api.service';
import { ActivatedRouteSnapshot, ActivationStart, Router } from '@angular/router';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private activeRequests: number;
    private route: ActivatedRouteSnapshot;

    constructor(
        private loader: LoaderService,
        private state: StateService,
        private api: ApiService,
        private router: Router
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof ActivationStart) {
                this.route = event.snapshot
            }
        });
        this.activeRequests = +this.state.get('activeRequest');
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let showLoader = true;
        if (typeof this.route?.data?.showLoader !== 'undefined') {
            showLoader = this.route.data.showLoader;
        }
        const environment = this.api.getEnv();
        if (showLoader && typeof environment.noLoaderRequests !== 'undefined') {
            for (const noLoader of environment.noLoaderRequests) {
                if (req.url.endsWith(noLoader.url)) {
                    if (typeof noLoader.body !== 'undefined') {
                        let bodyMatch = true;
                        for (const i in noLoader.body) {
                            if (typeof noLoader.body[i] !== 'object') {
                                if (req.body[i] !== noLoader.body[i]) {
                                    bodyMatch = false;
                                }
                            } else {
                                if (
                                    !Array.isArray(noLoader.body[i])
                                    && typeof req.body[i] !== 'undefined'
                                ) {
                                    for (const p in noLoader.body[i]) {
                                        if (
                                            typeof req.body[i][p] !== 'undefined'
                                            && req.body[i][p] !== noLoader.body[i][p]
                                        ) {
                                            bodyMatch = false;
                                        }
                                    }
                                }
                            }
                        }
                        if (bodyMatch) {
                            showLoader = false;
                        }
                    } else {
                        showLoader = false;
                    }
                }
            }
        }
        if (showLoader) {
            this.activeRequests++;
            this.state.set('activeRequests', this.activeRequests.toString());
            if (this.activeRequests === 1) {
                this.loader.show();
            }
            return next.handle(req).pipe(
                finalize(() => {
                    this.activeRequests--;
                    this.state.set('activeRequests', this.activeRequests.toString());
                    if (this.activeRequests === 0) {
                        this.loader.hide();
                    }
                })
            );
        } else {
            return next.handle(req);
        }
    }
}
