<div class="bar-container d-flex align-items-center" [class]="data.containerClass">
  <div class="milestone first big"
       [class]="data.milestonesClass"
       [ngClass]="{ completed: progress > 0 || data.isFirstCompleted }"
  >
    <mat-icon>{{ milestoneIcon }}</mat-icon>
    <div class="background"></div>
  </div>
  <div
    *ngFor="let milestone of milestones; let last = last"
    class="milestone"
    [class]="data.milestonesClass"
    [ngStyle]="getMilestonePosition(milestone)"
    [ngClass]="{
      completed: (progress / length) * 100 >= milestone.position,
      last: last,
      big: (progress / length) * 100 === milestone.position || milestone.big
    }"
  >
    <mat-icon>{{ milestoneIcon }}</mat-icon>
    <div class="background"></div>
  </div>
  <div class="progress-label" [ngStyle]="getPosition(progress)">
    <span *ngIf="!data.progressComponents">
      {{ progress }}/{{ length }}
    </span>
    <ng-container *ngIf="data.progressComponents">
      <app-slot
        [items]="data.progressComponents"
        class="w-100"
      ></app-slot>
    </ng-container>
  </div>
  <div class="bar" [class]="barClass">
    <div class="fill" [ngStyle]="getPosition(progress, 'DIM')"></div>
  </div>
</div>
