<mat-accordion
  [multi]="data.multi"
  [togglePosition]="data.togglePosition"
  [hideToggle]="data.hideToggleIcon">
  <mat-expansion-panel
    [class]="data.class"
    [expanded]="data.expanded"
    *ngFor="let obj of data.dataObject | getArrayPath: data.path; let i=index;"
  >
    <mat-expansion-panel-header [class]="data.headerClasses">
      <ng-container>
        <app-slot
          [items]="data.header"
          [componentValue]="obj"
          [parentComponent]="selfRef"
          class="w-100"
        ></app-slot>
      </ng-container>
      <mat-icon *ngIf="data.hideToggleIcon && data.openIcon && !isExpanded(i)">{{data.openIcon}}</mat-icon>
      <mat-icon *ngIf="data.hideToggleIcon && data.closeIcon && isExpanded(i)">{{data.closeIcon}}</mat-icon>
    </mat-expansion-panel-header>

    <ng-container>
      <app-slot
        [items]="data.content"
        [componentValue]="obj"
        [parentComponent]="selfRef"
        class="w-100"
      ></app-slot>
    </ng-container>

  </mat-expansion-panel>



</mat-accordion>
