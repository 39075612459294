<mat-form-field appearance="fill" [formGroup]="parentForm" *ngIf="parentForm">
  <mat-label>{{ language.getLabel(data.label) }}</mat-label>
  <input
    matInput
    [id]="data.id ? data.id : null"
    [placeholder]="language.getLabel(data.label)"
    [formControlName]="data.name"
    name="{{ data.name }}"
    [type]="data.inputType"
    [matAutocomplete]="auto"
  />
  <mat-icon *ngIf="data.suffixIcon" matSuffix (click)="select($event)">{{ data.suffixIcon }}</mat-icon>

  <mat-autocomplete
    #auto="matAutocomplete"
    [autoActiveFirstOption]="
      data.autoActiveFirstOption ? data.autoActiveFirstOption : false
    "
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="change($event)"
  >
    <div *ngIf="data.optionsTitle" [class]="data.optionsTitleClasses">{{ data.optionsTitle }}</div>
    <mat-option
      *ngFor="let option of filteredOptions"
      [value]="option.value"
      [class]="data.optionClasses">
        <span *ngIf="!data.baseRedirectRoute" [class]="data.optionTextClasses">{{ option.text }}</span>
        <a
          *ngIf="data.baseRedirectRoute"
          [routerLink]="[data.baseRedirectRoute, option.value]"
          [class]="data.itemRowClasses">
            <app-image
              *ngIf="data.showOptionImage"
              [class]="data.imageClasses"
              [data]="
                {
                  class: 'w-100',
                  dataObject: option.image,
                  srcPath: '',
                  displaySize: 400
                }
              ">
            </app-image>
            <span [class]="data.optionTextClasses">
              {{ option.text }}
            </span>
        </a>
    </mat-option>

    <ng-container *ngIf="data.additionalOptionsComponents">
      <app-slot
        [items]="data.additionalOptionsComponents"
        [componentValue]="responseData | getArrayPath: data.additionalOptionsPath"
        [detectChanges]="true"
        class="w-100"
      ></app-slot>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field appearance="fill" *ngIf="!parentForm">
  <mat-label>{{ language.getLabel(data.label) }}</mat-label>
  <input
    matInput
    [id]="data.id ? data.id : null"
    [placeholder]="language.getLabel(data.label)"
    [formControl]="formControl"
    name="no-name"
    [type]="data.inputType"
    [matAutocomplete]="auto"
    (keyup.enter)="select($event)"
  />
  <mat-icon *ngIf="data.suffixIcon" matSuffix (click)="select($event)">{{ data.suffixIcon }}</mat-icon>

  <mat-autocomplete
    #auto="matAutocomplete"
    [autoActiveFirstOption]="
      data.autoActiveFirstOption ? data.autoActiveFirstOption : false
    "
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="change($event)"
  >
  <div *ngIf="data.optionsTitle" [class]="data.optionsTitleClasses">{{ data.optionsTitle }}</div>
  <mat-option
    *ngFor="let option of filteredOptions"
    [value]="option.value"
    [class]="data.optionClasses">
      <span *ngIf="!data.baseRedirectRoute" [class]="data.optionTextClasses">{{ option.text }}</span>
      <a
        *ngIf="data.baseRedirectRoute"
        [routerLink]="[data.baseRedirectRoute, option.value]"
        [class]="data.itemRowClasses">
          <app-image
            *ngIf="data.showOptionImage"
            [class]="data.imageClasses"
            [data]="
              {
                class: 'w-100',
                dataObject: option.image,
                srcPath: '',
                displaySize: 400
              }
            ">
          </app-image>
          <span [class]="data.optionTextClasses">
            {{ option.text }}
          </span>
      </a>
  </mat-option>

  <ng-container *ngIf="data.additionalOptionsComponents">
    <app-slot
      [items]="data.additionalOptionsComponents"
      [componentValue]="responseData | getArrayPath: data.additionalOptionsPath"
      [detectChanges]="true"
      class="w-100"
    ></app-slot>
  </ng-container>
  </mat-autocomplete>
</mat-form-field>
