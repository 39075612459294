// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { env } from "ng-viv-ux";

export const environment: env = {
  production: true,
  sdk: import('ng-vex-sdk'),
  dark_logo: 'decona_logo_orange.svg',
  light_logo: 'decona_logo_white.svg',
  title: 'VexAI',
  version: '1.0.0',
  apiAccessPoint: {
    protocol: 'https',
    hostname: 'vex.demo.viv-design.com',
    path: 'api'
  },
  // tslint:disable-next-line: max-line-length
  apiToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyMiIsImp0aSI6IjRhMTVlZTE4NDcwZmQ5NTc3YzY3OGYwNzBiNmIxZjhjNjIyMDUzZTg1MjY4ZTg4MjRkZGY2NjgzZDZjY2Q5ZTgxZDg3YjA4YWQ2ZGNjYWZiIiwiaWF0IjoxNjU2NTA0Mzc4Ljc1ODY0MywibmJmIjoxNjU2NTA0Mzc4Ljc1ODY0OSwiZXhwIjoxNjg4MDQwMzc4Ljc0Nzc4LCJzdWIiOiIiLCJzY29wZXMiOltdfQ.VwDjy6_PrGH8FV0ydmdUzgotYGGstpx0kF9vAhqNEXUESp_YqX56HEAqcoKJXeeofaVDTIwdGnN6cZO0iwc5mU1KnbZ9ZsoXHDQ6F9FBJictOYKCMTrqFvom_-uLkGKWpm2-JF1ZKNrgweMKTJHoEQgzu3FZqdTZ0mju0o228bSN4g9_q4GBtPUc07pnP3Eb_tN-pFhw1ZBbnVOrrIBnaZxhZI0v_S3gWKMz9o0W6Xfh6ENiz0_Enf2M6KgnhHNeUK4PdlvRlLR4WYkJVsYjUjTjX-xCAONKXdfvYv0MERGDTgMAcemybxoOjgoSAsO2o0Dc4shzVw-rhz1LdYadBPgq_p_JZLT3lzS-bb5-pJ2CuXdbmw7hFtWFdZ9-LQ3MZGoY24BNgqx0yfn7UCppo4YoidPrFw-bMpwBqYtBmsI01_FZK9zWkAdy4v1YAlq1p3EPAGu14AClUl4e_EaVdQ17byRLz_8HGxV0UqydVnBTakF1Y4fYh5UXuV0UjHu2-c7KNkc2jUiD4uWjAj89ikj-5ta378vxvo3kHONG8XmLMc1vb-90risv7S9_pRtLcC6m4YqgLrclnFJAQ76uUeeS3QnwN-p8TkBhS5PdbZcsDmI_f7oG2MLrPILo3w8YNAgGGGdcP2JmiQSBFXaM9cSqg5GG0So9uyRO3DlLbMU',
  mapApiToken: 'AIzaSyCfecV8GfLbgx6Ciw3uDhcPV3S-UtarrvQ',
  recaptchaApiToken: '6LcEjiEgAAAAADk_cANagYtJqPG7Cx3ZkrwilUfO'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
