import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Event } from './event.class';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private subject = new Subject();

  constructor() { }
  fire(event: Event) {
    this.subject.next(event);
  }

  on(eventName: string, action: any): Subscription {
    return this.subject.pipe(
      filter((e: Event) => e.name === eventName),
      map((e: Event) => e.data)
    ).subscribe(action);
  }
}
