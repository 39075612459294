import { Component, OnInit, Input } from '@angular/core';
import { LanguageService } from '../../../../backbone/language.service';
import { GetArrayPathPipe } from '../../../../backbone/pipes/get-array-path.pipe';
import { TransformService } from '../../../../backbone/transform.service';

@Component({
  selector: 'app-chart-bar-horizontal-stacked',
  templateUrl: './chart-bar-horizontal-stacked.component.html',
  styleUrls: ['./chart-bar-horizontal-stacked.component.scss']
})
export class ChartBarHorizontalStackedComponent implements OnInit {
  @Input() public data: any = {};

  constructor(
    private language: LanguageService,
    private getArrayPath: GetArrayPathPipe,
    private transformService: TransformService
  ) { }

  ngOnInit(): void {
    if (typeof this.data.bars !== 'undefined') {
      const bars = [];
      for (const bName in this.data.bars) {
        const series = [];
        // tslint:disable-next-line: forin
        for (const sName in this.data.bars[bName]) {
          let labelValue = this.getArrayPath.transform(
            this.data.value,
            this.data.bars[bName][sName]
          );
          if (!labelValue) {
            continue;
          }
          if (typeof this.data.transform !== 'undefined') {
            labelValue = this.transformService.transform(
              this.getArrayPath.transform(this.data.value, this.data.bars[bName][sName]),
              this.data.transform
            );
          }
          series.push({
            name: this.language.getLabel(sName) + ' (' + labelValue + ')',
            value: this.getArrayPath.transform(this.data.value, this.data.bars[bName][sName])
          });
        }
        if (series.length > 0) {
          bars.push({
            name: this.language.getLabel(bName),
            series
          });
        }
      }
      this.data.value = bars;
    }
  }

  onSelect(data): void {
  }
}
