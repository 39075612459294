import { Injectable } from '@angular/core';
import { StateService } from '../state.service';

@Injectable({
    providedIn: 'root'
})
export class HashService {
    constructor(
        private state: StateService
    ) { }

    saveHash(response) {
        this.state.set('cartHash', response.result.data.hash);
    }
}
