<div class="h-100 row justify-content-center no-gutters">
  <div
    class="
      d-flex
      flex-column
      align-content-center
    "
    [class]="class"
  >
    <div class="wrapper my-auto text-center" [class]="wrapperClass">
      <div [class]="logoWrapperClass">
        <a [routerLink]="['']">
          <img
            *ngIf="logo"
            class="mainLogo p-3"
            src="assets/img/{{ state.get('dark_logo') }}"
          />
        </a>
      </div>
      <app-slot [items]="slots.main"></app-slot>
    </div>
  </div>
</div>
