import { Component, Input, OnInit } from '@angular/core';
import { TransformService } from '../../../../backbone/transform.service';
import { GetArrayPathService } from '../../../../backbone/get-array-path.service';
import { LanguageService } from '../../../../backbone/language.service';

@Component({
  selector: 'app-chart-bar-lines',
  templateUrl: './chart-bar-lines.component.html',
  styleUrls: ['./chart-bar-lines.component.scss']
})
export class ChartBarLinesComponent implements OnInit {
  @Input() data: any;

  constructor(private getArrayPath: GetArrayPathService,
              public langService: LanguageService) {}

  ngOnInit(): void {
    this.initChart();
  }

  private initChart() {
    if (typeof this.data.barsPath !== 'undefined') {
      const barsArray = [];

      const barsObject: any = this.getArrayPath.get(this.data.value, this.data.barsPath);

      for (const key of Object.keys(barsObject)) {
        const label = key;

        barsArray.push({
          name: label,
          value: barsObject[key]
        });
      }

      this.data.barChart = barsArray;
    }

    if (typeof this.data.linesPath !== 'undefined') {
      const linesArray = [];

      for (const line of this.data.linesPath) {
        const series = [];
        const lineObject = this.getArrayPath.get(this.data.value, line.path);

        for (const key of Object.keys(lineObject)) {
          const label = key;

          series.push({
            name: label,
            value: lineObject[key]
          });
        }

        linesArray.push({
          name: this.langService.getLabel(line.name),
          series
        });
      }

      this.data.lineChartSeries = linesArray;
    }
  }

  public yAxisTickFormatting(value) {
    return '';
  }

  public percentTickFormatting(value) {
    return value.toLocaleString() + '%';
  }

  public yRightAxisTickFormatting(value) {
    return this.percentTickFormatting(value);
  }
}
