import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from './permissions.service';
import { StateService } from './state.service';
import { ApiService } from './api.service';

enum USERSTATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  LOCKED = 2
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private state: StateService,
    private permissionService: PermissionsService,
    private api: ApiService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let accessDeniedRedirect = '';
    if (typeof route.data.accessDeniedRedirect !== 'undefined') {
      accessDeniedRedirect = route.data.accessDeniedRedirect;
    }
    if (this.state.get('auth') === true) {
      this.api.getService('UserService').checkSession().subscribe((response) => {
        const clonedResponse = JSON.parse(JSON.stringify(response.result));
        if (clonedResponse.permissions) {
          delete clonedResponse.permissions;
        }
        if (clonedResponse.status) {
          delete clonedResponse.status;
        }
        if (clonedResponse.user) {
          delete clonedResponse.user;
        }
        this.state.set('session', clonedResponse)
        this.state.set('roles', response.result.user.roles);
        if (
          typeof response.result.user.roles.find(
            item => item.key === 'guest'
          ) !== 'undefined'
        ) {
          this.state.set('auth', 'false');
          this.router.navigate([accessDeniedRedirect]);
        }

        if (typeof response.result.user.status !== 'undefined') {
          const environment = this.api.getEnv();
          let userLockedRedirect: string | string[] = '';
          if (typeof environment.defaultLockedRedirect !== 'undefined') {
            userLockedRedirect = environment.defaultLockedRedirect;
          }
          if (typeof route.data.userLockedRedirect !== 'undefined') {
            userLockedRedirect = route.data.userLockedRedirect;
          }
          if (userLockedRedirect) {
            switch (response.result.user.status) {
              case this.api.getStatic('USERSTATUS').LOCKED:
                if (!Array.isArray(userLockedRedirect)) {
                  userLockedRedirect = [userLockedRedirect];
                }
                this.router.navigate(userLockedRedirect);
                break;
            }
          }
        }
      });
      if (this.permissionService.checkPermissions(route.routeConfig)) {
        return true;
      } else {
        this.router.navigate([accessDeniedRedirect]);
        return false;
      }
    }
    this.router.navigate([accessDeniedRedirect]);
    return false;
  }

}
