<span [class]="data.class">
  <ngx-charts-bar-horizontal-stacked
    [view]="data.view"
    [scheme]="data.colorScheme"
    [results]="data.value"
    [gradient]="data.gradient"
    [xAxis]="data.showXAxis"
    [yAxis]="data.showYAxis"
    [legend]="data.showLegend"
    [showXAxisLabel]="data.showXAxisLabel"
    [showYAxisLabel]="data.showYAxisLabel"
    [xAxisLabel]="data.xAxisLabel"
    [yAxisLabel]="data.yAxisLabel"
    [barPadding]="data.barPadding || 8"
    (select)="onSelect($event)">
  </ngx-charts-bar-horizontal-stacked>
</span>