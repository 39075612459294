import { Component, Input, OnInit } from '@angular/core';
import { GetArrayPathService } from '../../backbone/get-array-path.service';
import { LanguageService } from '../../backbone/language.service';
import { ISlotComponent } from '../slot/slot-component';

@Component({
  selector: 'app-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss']
})
export class ProgressCircleComponent implements OnInit, ISlotComponent {
  @Input() public data: any;
  public percent = 0;
  public progress = [-90, 0];
  public showPercent = true;

  constructor(public language: LanguageService,
              private arrayPathService: GetArrayPathService) { }

  ngOnInit(): void {
    if (typeof this.data.showPercent !== 'undefined') {
      this.showPercent = this.data.showPercent;
    }

    if (typeof this.data.currentPath !== 'undefined') {
      this.data.current = this.arrayPathService.get(this.data.dataObject, this.data.currentPath);
    }

    this.percent = this.data.current / this.data.max * 100;
  }
}
