import { Component, Input } from '@angular/core';
import { ISlotComponent } from '../slot/slot-component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements ISlotComponent {
  @Input() public data: any;

  constructor() { }
}
