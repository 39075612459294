<h1 mat-dialog-title *ngIf="data.title">
  {{ language.getLabel(data.title) }}
</h1>
<mat-dialog-content>
  <app-slot
    *ngIf="!data.parentForm"
    [items]="data.items"
    style="display: block; width: 100%; height: 100%"
  ></app-slot>
  <app-slot
    *ngIf="data.parentForm"
    [items]="data.items"
    [parentForm]="data.parentForm"
    style="display: block; width: 100%; height: 100%"
  ></app-slot>
</mat-dialog-content>
<mat-dialog-actions *ngIf="data.close" align="end">
  <button *ngIf="data.close" mat-button matDialogClose="cancel" color="primary">
    {{ language.getLabel("App_close") }}
  </button>
</mat-dialog-actions>
