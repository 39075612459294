import { Component, Input, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { LanguageService } from '../../backbone/language.service';
import { take, takeUntil } from 'rxjs/operators';
import SwiperCore,
{ Autoplay, Pagination, Navigation, Thumbs, SwiperOptions, Zoom } from 'swiper';
import { ApiService } from '../../backbone/api.service';
import { Observable, Subject } from 'rxjs';
import { GetArrayPathPipe } from '../../backbone/pipes/get-array-path.pipe';
import { ActivatedRoute } from '@angular/router';
import { ISlotComponent } from '../slot/slot-component';
import { BreakpointObserver } from '@angular/cdk/layout';

SwiperCore.use([Autoplay, Pagination, Navigation, Thumbs, Zoom]);
@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [GetArrayPathPipe]
})

export class CarouselComponent implements OnInit, OnDestroy, ISlotComponent {
    @Input() public data: any;
    thumbsSwiper: any;
    public urlKey = 'original';
    public urlKeyWebp = 'webp';
    public image = [];
    public mainBannerConfig: SwiperOptions = {};
    public thumbnails: SwiperOptions = {};
    public singleSlideWidth = 24;
    /* Subject that emits when the component has been destroyed. */
    protected destroyed = new Subject<void>();
    public showLoadingIcon = false;

    constructor(
        private api: ApiService,
        public language: LanguageService,
        private getArrayPath: GetArrayPathPipe,
        private zone: NgZone,
        private route: ActivatedRoute,
        private breakpointObserver: BreakpointObserver
    ) { }

    ngOnInit(): void {
        let lastMatch = null;
        if (typeof this.data.breakpoints !== 'undefined') {
            const observe = []
            for (const width in this.data.breakpoints) {
                observe.push(width);
            }
            if (observe.length > 0) {
                this.breakpointObserver.observe(observe.map((i) => {
                    return '(min-width: ' + i + 'px)';
                }))
                    .pipe(takeUntil(this.destroyed)).subscribe((size) => {
                        if (size.matches) {
                            // tslint:disable-next-line: forin
                            for (const width in this.data.breakpoints) {
                                if (size.breakpoints['(min-width: ' + width + 'px)']) {
                                    this.singleSlideWidth = 24 / this.data.breakpoints[width]
                                        .slidesPerView;
                                    lastMatch = width;
                                }
                            }
                        } else {
                            this.singleSlideWidth = 24 / this.data.slidesPerView;
                        }

                        if (typeof this.data.dataSource !== 'undefined'
                            && typeof this.data.dataSource.params !== 'undefined'
                            && (
                                typeof this.data.dataSource.params.id === 'object' 
                                ||
                                typeof this.data.dataSource.params.code === 'object'
                            )
                            && lastMatch !== null
                        ) {
                            this.load(lastMatch);
                        } else {
                            this.load();
                        }
                    });
            } else {
                this.load();
            }
        }


        if (typeof this.data.slideImageSize !== 'undefined') {
            this.urlKey = this.data.slideImageSize + '_' + this.urlKey;
            this.urlKeyWebp = this.data.slideImageSize + '_' + this.urlKeyWebp;
        }
        if (this.data.initialSlide && this.data.thumbs) {
            console.warn(
                'You cannot use thumbnails and attribute "initialSlide" simultaneously!'
            );
        }
        this.thumbnails = this.data.thumbnails;

        // Stringify all specific properties
        for (const [key, value] of Object.entries(this.data)) {
            this.mainBannerConfig[key] = value;
        }

        if (typeof this.data.breakpoints === 'undefined') {
            this.load();
        }
    }

    load(width?) {
        if (this.data.dataObject) {
            this.image = this.data.dataObject;
            if (typeof this.data.path !== "undefined") {
                this.image = this.getArrayPath.transform(
                    this.data.dataObject,
                    this.data.path
                );
            }
            if (this.mainBannerConfig.loop) {
                if (this.mainBannerConfig.slidesPerView !== 'auto'
                    && this.mainBannerConfig.slidesPerView >= this.image.length) {
                    this.mainBannerConfig.loop = false;
                } else {
                    this.mainBannerConfig.loopedSlides = this.image.length;
                }
            }
        } else if (this.data.dataSource) {
            let params: any  = {};
            // load options if datasource is provided
            if (typeof this.data.dataSource.params !== 'undefined') {
                params = { ...this.data.dataSource.params };
                if (typeof width !== 'undefined'
                    && typeof this.data.dataSource.params.id === 'object'
                ) {
                    params.id = this.data.dataSource.params.id[width];
                }

                if (typeof width !== 'undefined'
                    && typeof this.data.dataSource.params.code === 'object'
                ) {
                    params.code = this.data.dataSource.params.code[width];
                }
            }


            this.route.params
                .pipe(takeUntil(this.destroyed))
                .subscribe(urlParams => {
                    const stringParams = JSON.stringify(params);
                    let replaced = stringParams;
                    for (const key of Object.keys(urlParams)) {
                        const search = ':' + key;
                        replaced = replaced.replace(new RegExp(search, 'g'), urlParams[key]);
                    }
                    if (replaced !== '') {
                        params = { ...JSON.parse(replaced) };
                    }
                });
            const dataService = this.api.getService(this.data.dataSource.service);
            dataService[this.data.dataSource.method](params)
                .pipe(take(1))
                .subscribe((response: any) => {
                    this.data.progressBar = false;
                    this.image = this.getArrayPath.transform(
                        response.result.data,
                        this.data.dataSource.path
                    );
                    if (this.mainBannerConfig.loop && typeof this.image !== 'undefined') {
                        if (this.mainBannerConfig.slidesPerView !== 'auto'
                            && this.mainBannerConfig.slidesPerView >= this.image.length) {
                            this.mainBannerConfig.loop = false;
                        } else {
                            this.mainBannerConfig.loopedSlides = this.image.length;
                        }
                    }
                });
        }
    }

    isObject(imageUrl: any) {
        return typeof (imageUrl) === 'object';
    }

    click(index?: number) {
        this.zone.run(() => {
            if (typeof this.data.click === 'function') {
                if (!this.data.clickParams) {
                    this.data.clickParams = {};
                    this.data.clickParams.event = 'click';
                    if (Array.isArray(this.image) &&
                        typeof this.image[index || 0].slug !== 'undefined') {
                      this.data.clickParams.slug = this.image[index || 0].slug;
                    }
                }
                const result = this.data.click(this.data.clickParams);
                if (result instanceof Observable) {
                    result.subscribe();
                }
            }
        });
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
