import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { ApiService } from '../../../../backbone/api.service';
import { ScrollService } from '../../../../backbone/scroll.service';
import { LanguageService } from '../../../../backbone/language.service';
import { LinkPipe } from '../../../../backbone/pipes/link.pipe';



@Component({
  selector: 'app-menu-node',
  templateUrl: './menu-node.component.html',
  styleUrls: ['../nested-menu.component.scss']
})
export class MenuNodeComponent extends BaseComponent implements OnInit {

  @Input() public data: any;

  constructor(
      public language: LanguageService,
      public scroll: ScrollService,
      protected api: ApiService,
      public link: LinkPipe,
  ) {
    super(language, scroll, api, link);
  }

  ngOnInit(): void {
  }

}
