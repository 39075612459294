import { Component, Input, OnInit } from '@angular/core';
import { GetArrayPathService } from '../../../../backbone/get-array-path.service';
import { LanguageService } from '../../../../backbone/language.service';

@Component({
  selector: 'app-chart-polar',
  templateUrl: './chart-polar.component.html',
  styleUrls: ['./chart-polar.component.scss']
})
export class ChartPolarComponent implements OnInit {
  @Input() data: any;

  constructor(
    private getArrayPath: GetArrayPathService,
    public langService: LanguageService
    ) { }

  ngOnInit(): void {
    this.initChart();
  }

  public yAxisTickFormatting(value) {
    return this.percentTickFormatting(value);
  }

  public percentTickFormatting(value) {
    return value.toLocaleString() + '%';
  }

  private initChart() {
    if (typeof this.data.resultsPath !== 'undefined') {
      const resultsArray = [];

      for (const line of this.data.resultsPath) {
        const series = [];
        const lineObject = this.getArrayPath.get(this.data.dataObject, line.path);

        for (const key of Object.keys(lineObject)) {
          const label = key;

          series.push({
            name: label,
            value: lineObject[key]
          });
        }

        resultsArray.push({
          name: this.langService.getLabel(line.name),
          series
        });
      }

      this.data.results = resultsArray;
    }
  }
}
