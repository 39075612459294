import { NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeListComponent } from './tree-list.component';
import { RoutingService } from '../../../backbone/routing.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { PrimitiveModule } from '../../primitive/primitive.module';
import { PipesModule } from '../../../backbone/pipes/pipes.module';
import { SlotModule } from '../../slot/slot.module';
import {DragDropModule} from '@angular/cdk/drag-drop';


@NgModule({
  declarations: [TreeListComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    PrimitiveModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    PipesModule,
    SlotModule,
    DragDropModule
  ]
})
export class TreeListModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('TreeListComponent', TreeListComponent);
  }
}
