import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ISlotComponent } from '../../slot/slot-component';
import { UntypedFormGroup, FormControl, AbstractControl } from '@angular/forms';
import { LanguageService } from '../../../backbone/language.service';
import { EventBusService } from '../../../backbone/event-bus.service';
import { Subscription, Observable, Subject } from 'rxjs';
import { Event } from '../../../backbone/event.class';
import { distinctUntilChanged, skip, startWith, takeUntil } from 'rxjs/operators';
import { MatInput } from '@angular/material/input';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, OnDestroy, ISlotComponent {
  @ViewChild(MatInput, { static: false }) private inputField: ElementRef<MatInput>;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  private subsc: Subscription;

  @Input() public data: any = {};
  @Input() public parentForm: UntypedFormGroup;
  public suffixAction;
  public suffixIcon;
  public internalValue: any;
  private destroy$: Subject<void> = new Subject();

  constructor(
    public language: LanguageService,
    private eventBus: EventBusService
  ) { }

  ngOnInit() {
    this.internalValue = this.data.value;

    if (typeof this.data.suffixAction !== 'undefined') {
      this.suffixAction = this[this.data.suffixAction];
    }
    if (typeof this.data.suffixIcon !== 'undefined') {
      this.suffixIcon = this.data.suffixIcon;
    }
    this.subsc = this.eventBus.on('actionBarControlReset', (data) => {
      if (
        typeof data.actionParams === 'undefined'
        || typeof data.actionParams.controls === 'undefined'
        || (
          typeof data.actionParams !== 'undefined'
          && typeof data.actionParams.controls !== 'undefined'
          && data.actionParams.controls.indexOf(this.data.actionId) >= 0
        )
      ) {
        this.internalValue = this.data.value;
        if (typeof this.data.change === 'function') {
          const result = this.data.change({
            id: this.data.actionId,
            value: this.internalValue,
            event: 'change'
          });
          if (result instanceof Observable) {
            result.subscribe();
          }
        }
      }
    });
    if (typeof this.data.maxLength === 'undefined') {
      this.data.maxLength = null;
    }

    if (this.parentForm) {
      const formField: AbstractControl = this.parentForm.get(this.data.name);

      formField.statusChanges
        .pipe(
          takeUntil(this.destroy$),
          startWith('INVALID'),
          distinctUntilChanged(),
          skip(1)
        )
        .subscribe(status => {
          if (status === 'VALID') {
            this.parentForm.get(this.data.name).setErrors(null);
          }
          this.status((status as string).toLowerCase());
        });
    }
  }

  private limitValue(value: number) {
    if (typeof this.data.min !== 'undefined' && value < this.data.min) {
      value = this.data.min;
    }
    if (typeof this.data.max !== 'undefined' && value > this.data.max) {
      value = this.data.max;
    }
    return value;
  }

  change(event: any) {
    event.stopPropagation();
    let newValue;
    if (this.data.inputType === 'number') {
      newValue = this.limitValue(+event.target.value);
    } else {
      newValue = event.target.value;
    }
    this.internalValue = newValue;
    if (typeof this.data.change === 'function') {
      if (!this.data.changeParams) {
        this.data.changeParams = {};
      }
      this.data.changeParams.value = this.internalValue;
      this.data.changeParams.event = 'change';

      if (this.parentForm) {
        this.data.changeParams.control = this.parentForm.get(this.data.name);
      }

      const result = this.data.change(this.data.changeParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }

  status(type: string) {
    const status = `${type}Status`;

    if (typeof this.data[status] === 'function') {
      if (!this.data[`${status}Params`]) {
        this.data[`${status}Params`] = {};
      }
      this.data[`${status}Params`].event = `${status}`;
      // tslint:disable-next-line:no-string-literal
      this.data[`${status}Params`].target = this.inputField['_elementRef'].nativeElement;

      const result = this.data[status](this.data[`${status}Params`]);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }

  enter() {
    if (typeof this.data.onEnter !== 'undefined') {
      this.eventBus.fire(new Event('actionBarEnterKey', this.data.onEnter));
    }
  }

  togglePassword() {
    const inputTypes = {
      password: 'text',
      text: 'password'
    };
    if (typeof inputTypes[this.data.inputType] !== 'undefined') {
      this.data.inputType = inputTypes[this.data.inputType];
    }

    if (
      typeof this.data.suffixIcon !== 'undefined'
      && typeof this.data.suffixIconToggle !== 'undefined'
    ) {
      switch (this.suffixIcon) {
        case this.data.suffixIcon:
          this.suffixIcon = this.data.suffixIconToggle;
          break;
        case this.data.suffixIconToggle:
          this.suffixIcon = this.data.suffixIcon;
          break;
      }
    }
  }

  numberIncr() {
    let newValue: number;
    if (this.parentForm) {
      const formControl = this.parentForm.get(this.data.name);
      formControl.setValue(this.limitValue(formControl.value + 1));
      newValue = formControl.value;
    } else {
      this.internalValue = this.limitValue(this.internalValue + 1);
      newValue = this.internalValue;
    }
    if (typeof this.data.change === 'function') {
      const result = this.data.change({
        value: newValue,
        event: 'change'
      });
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }
  numberDecr() {
    let newValue: number;
    if (this.parentForm) {
      const formControl = this.parentForm.get(this.data.name);
      formControl.setValue(this.limitValue(formControl.value - 1));
      newValue = formControl.value;
    } else {
      this.internalValue = this.limitValue(this.internalValue - 1);
      newValue = this.internalValue;
    }
    if (typeof this.data.change === 'function') {
      const result = this.data.change({
        value: newValue,
        event: 'change'
      });
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }

  ngOnDestroy() {
    if (typeof this.subsc !== 'undefined') {
      this.subsc.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }
}
