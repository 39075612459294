import { Component, OnDestroy, OnInit } from '@angular/core';
import { SlotItem } from '../../slot/slot-item';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { CommunicationService } from '../../../backbone/communication.service';

interface Slots {
  main: SlotItem[];
}

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit, OnDestroy {
  slots: Slots;

  constructor(
    private route: ActivatedRoute,
    private comm: CommunicationService
  ) { }

  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe(data => {
      this.slots = data.slots;
    });
  }

  ngOnDestroy() {
    this.comm.resetChannels();
  }
}
