<ngx-dropzone
  (change)="onSelect($event)"
  [multiple]="data.multiple"
  [expandable]="data.expandable"
  [accept]="data.accept || '*'"
  [class]="data.class"
  [ngClass]="{'single': !data.multiple}"
>
  <ngx-dropzone-label>{{ language.getLabel(data.label) }}</ngx-dropzone-label>
  <ng-container *ngIf="!data.imagePreview">
    <ngx-dropzone-preview
      *ngFor="let f of files"
      [removable]="data.removable"
      (removed)="onRemove(f)"
    >
      <ngx-dropzone-label (click)="click($event, f)">{{
        f.name
      }}</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ng-container>
  <ng-container *ngIf="data.imagePreview">
    <app-dropzone-image-preview
      ngProjectAs="app-dropzone-preview"
      *ngFor="let f of files"
      [file]="f"
      [removable]="data.removable"
      (removed)="onRemove(f)"
    >
    </app-dropzone-image-preview>
  </ng-container>
</ngx-dropzone>
