import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() public data: any = {};
  public type: string = 'inline';
  public name: string;

  constructor(
    public registry: MatIconRegistry,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    if (this.data.icon.endsWith('.svg')) {
      this.name = this.data.icon.substring(
        this.data.icon.lastIndexOf('/') + 1,
        this.data.icon.lastIndexOf('.')
      );
      this.registry.addSvgIcon(
        this.name,
        this.sanitizer.bypassSecurityTrustResourceUrl(this.data.icon)
      );
      this.type = 'svg';
    }
  }
}
