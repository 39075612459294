<ng-container [formGroup]="parentForm">
  <div class="label text-left" [ngClass]="{'active': active}">
    <mat-label>{{ data.label }}</mat-label>
  </div>
  <ckeditor
    #editor
    class="editor"
    [ngClass]="{'active': active}"
    [editor]="Editor"
    [data]="data.value"
    [config]="config"
    (ready)="onReady($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [formControlName]="data.name"
  ></ckeditor>
</ng-container>
