import { Component, OnInit, Input } from '@angular/core';
import { ISlotComponent } from '../../slot/slot-component';
import { GetArrayPathPipe } from '../../../backbone/pipes/get-array-path.pipe';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-colorbox',
    templateUrl: './colorbox.component.html',
    styleUrls: ['./colorbox.component.scss']
})
export class ColorboxComponent implements OnInit, ISlotComponent {
    @Input() public data: any = {};

    color: string;
    cursor: string;
    constructor() { }

    ngOnInit() {
        this.cursor = 'initial';
        if (this.data.value > 1) {
            this.data.color = this.data.colors[2];
            this.cursor = 'pointer';
        } else if (this.data.value > 0) {
            this.data.color = this.data.colors[1];
            this.cursor = 'pointer';
        } else if (this.data.value < 0) {
            this.data.color = this.data.colors[0];
            this.cursor = 'pointer';
        } else {
            this.data.color = '#fff';
        }
    }

    click(e) {
        if (typeof this.data.click === 'function') {
            if (!this.data.clickParams) {
                this.data.clickParams = {};
            }
            this.data.clickParams.event = 'click';
            const result = this.data.click(this.data.clickParams);
            if (result instanceof Observable) {
                result.subscribe();
            }
        }
    }
}
