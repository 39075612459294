import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ISlotComponent } from '../../slot/slot-component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LanguageService } from '../../../backbone/language.service';
import { Subscription, Observable, Subject, ReplaySubject } from 'rxjs';
import { EventBusService } from '../../../backbone/event-bus.service';
import { take, takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../backbone/api.service';
import { GetArrayPathPipe } from '../../../backbone/pipes/get-array-path.pipe';
import { ActivatedRoute } from '@angular/router';
import { CommunicationService, Message } from '../../../backbone/communication.service';
import { ObjectMergerService } from '../../../backbone/object-merger.service';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [GetArrayPathPipe]
})
export class SelectComponent implements OnInit, OnDestroy, ISlotComponent {
  private subsc: Subscription;
  public value;

  public selected: any;

  @Input() public data: any;
  @Input() public parentForm: UntypedFormGroup;

  public searchOptionsCtrl: UntypedFormControl = new UntypedFormControl();

  /** Subject that emits when the component has been destroyed. */
  protected destroyed = new Subject<void>();
  public options = [];
  public filteredOptions: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private api: ApiService,
    public language: LanguageService,
    private eventBus: EventBusService,
    private getArrayPath: GetArrayPathPipe,
    public route: ActivatedRoute,
    private comm: CommunicationService,
    private objectMerger: ObjectMergerService
  ) { }

  ngOnInit() {
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }
    if (this.data.dataSource) {
      this.load();
    } else {
      if (!this.data.options && this.data.optionsPath) {
        let options = this.getArrayPath.transform(undefined, this.data.optionsPath);
        if (typeof options === 'object' && !Array.isArray(options)) {
          this.data.options = [];
          for (const key in options) {
            this.data.options.push({
              text: options[key],
              value: key
            });
          }
        } else if (typeof options === 'string') {
          options = options.split(',')
        }
        if (Array.isArray(options)) {
          this.data.options = options.map(option => {
            return {
              text: this.preOptionText(option),
              value: option
            };
          });
        }
      }
      if (typeof this.data.options !== 'undefined') {
        this.setOptions(this.data.options);
      }
    }
    if (typeof this.data.selectedPath !== 'undefined') {
      this.selected = this.getArrayPath.transform(undefined, this.data.selectedPath);
    }
    if (this.data.searchable) {
      if (this.parentForm) {
        this.searchOptionsCtrl.valueChanges
          .pipe(takeUntil(this.destroyed))
          .subscribe(() => {
            if (!this.options) {
              return;
            }

            let search = this.searchOptionsCtrl.value;
            if (!search) {
              this.filteredOptions.next(this.options.slice());
            } else {
              search = search.toLowerCase();
            }

            this.filteredOptions.next(
              this.options.filter(option => {
                if (typeof option.text === 'string') {
                  return option.text.toLowerCase().indexOf(search) > -1;
                } else {
                  return undefined;
                }
              })
            );
          });
      }
    }
    this.subsc = this.eventBus.on('actionBarControlReset', (data) => {
      if (
        typeof data.actionParams === 'undefined'
        || typeof data.actionParams.controls === 'undefined'
        || (
          typeof data.actionParams !== 'undefined'
          && typeof data.actionParams.controls !== 'undefined'
          && data.actionParams.controls.indexOf(this.data.actionId) >= 0
        )
      ) {
        this.selected = this.data.selected;
        if (typeof this.data.change === 'function') {
          const result = this.data.change({
            id: this.data.actionId,
            value: this.selected
          });
          if (result instanceof Observable) {
            result.subscribe();
          }
        }
      }
    });
  }
  load(params = {}) {
    if (typeof this.data.value !== 'undefined') {
      if (typeof this.data.value === 'string' && this.data.value.startsWith(':')) {
      } else {
        this.value = this.data.value;
      }
    }
    // load options if datasource is provided
    if (typeof this.data.dataSource.params !== 'undefined') {
      if (Object.keys(params).length > 0) {
        params = this.objectMerger.deep(this.data.dataSource.params, params);
      } else {
        params = { ...this.data.dataSource.params };
      }
    }
    this.route.params.pipe(take(1)).subscribe(urlParams => {
      // If params or value has dynamic params from route url - search and replace them
      const stringParams = JSON.stringify(params);
      let replaced = stringParams;
      for (const key of Object.keys(urlParams)) {
        const search = ':' + key;
        replaced = replaced.replace(new RegExp(search, 'g'), urlParams[key]);
        if (typeof this.value === 'string') {
          this.value = this.value.replace(new RegExp(search, 'g'), urlParams[key]);
        }
      }
      if (replaced !== '') {
        params = { ...JSON.parse(replaced) };
      }
    });
    const dataService = this.api.getService(this.data.dataSource.service);
    dataService[this.data.dataSource.method](params)
      .pipe(take(1))
      .subscribe((response: any) => {
        this.data.options = [];
        response.result.data.forEach((item) => {
          const value = this.getArrayPath.transform(
            item,
            this.data.dataSource.valuePath
          );
          let label: string;
          if (this.data.dataSource.labelPath) {
            label = this.getArrayPath.transform(
              item,
              this.data.dataSource.labelPath
            );
          }
          if (this.data.dataSource.multiLabelPath) {
            const labelParts = [];
            for (const path of this.data.dataSource.multiLabelPath) {
              labelParts.push(this.getArrayPath.transform(item, path));
            }
            label = labelParts.join(' ');
          }
          this.data.options.push({
            value,
            text: label
          });
          if (this.value) {
            if (this.data.multiple && typeof this.value === 'string') {
              this.value = this.value.split(',');
            }
            this.selected = this.value;
          }
        });
        this.options = this.data.options;
        this.filteredOptions.next(this.data.options.slice());
      });
  }
  setOptions(options: Array<{ [key: string]: any }>, filter?: { [key: string]: any }) {
    if (typeof options !== 'undefined' && typeof this.data.optionsSortBy !== 'undefined') {
      options.sort((a, b) => {
        return a[this.data.optionsSortBy] - b[this.data.optionsSortBy];
      });
    }
    if (
      (
        typeof options[0].value === 'undefined'
        && typeof this.data.optionValuePath !== 'undefined'
      )
      ||
      (
        typeof options[0].text === 'undefined'
        && typeof this.data.optionTextPath !== 'undefined'
      )
    ) {
      options = options.map((option) => {
        const newOption = { ...option };
        if (typeof this.data.optionValuePath !== 'undefined') {
          newOption['value'] = this.getArrayPath.transform(option, this.data.optionValuePath);
        }
        if (typeof this.data.optionTextPath !== 'undefined') {
          newOption['text'] = this.getArrayPath.transform(option, this.data.optionTextPath);
        }
        return newOption;
      }, this);
    }
    this.options = options;
    if (typeof filter !== 'undefined') {
      this.filterOptions(filter);
    } else {
      this.filteredOptions.next(options.slice());
    }
  }
  filterOptions(filter: { [key: string]: any }) {
    this.filteredOptions.next(this.options.filter((item) => {
      for (const key in filter) {
        if (item[key] !== filter[key]) {
          return false;
        }
      }
      return true;
    }));
  }
  change(event: any, options: any) {
    if (typeof this.data.emptyOption !== 'undefined' && event.value === '') {
      return;
    }
    if (typeof this.data.change === 'function') {
      if (!this.data.changeParams) {
        this.data.changeParams = {};
      }
      this.data.changeParams.event = 'change';
      this.data.changeParams.value = event.value;
      this.data.changeParams.options = options;

      const result = this.data.change(this.data.changeParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }

  private preOptionText(text) {
    if (typeof this.data.optionTextPrefix !== 'undefined') {
      text = this.data.optionTextPrefix + text;
    }
    return text;
  }

  ngOnDestroy() {
    if (this.subsc) {
      this.subsc.unsubscribe();
    }
    this.destroyed.next();
    this.destroyed.complete();
  }
}
