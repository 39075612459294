import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../../backbone/communication.service';
import { EventBusService } from '../../backbone/event-bus.service';
import { LanguageService } from '../../backbone/language.service';

@Component({
  selector: 'app-slot-dialog',
  templateUrl: './slot-dialog.component.html',
  styleUrls: ['./slot-dialog.component.scss']
})
export class SlotDialogComponent implements OnInit, OnDestroy {
  private channels: Set<string> = new Set();
  private listener: Subscription;
  public selfRef: SlotDialogComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public language: LanguageService,
    private eventBus: EventBusService,
    private comm: CommunicationService
  ) {}

  ngOnInit() {
    this.selfRef = this;
    if (typeof this.data.close === 'undefined') {
      this.data.close = true;
    }

    this.listener = this.eventBus.on('newChannelRegistered', (channelUid) =>
      this.registerChannel(channelUid)
    );
  }

  registerChannel(channel: string) {
    this.channels.add(channel);
  }

  ngOnDestroy(): void {
    this.comm.resetChannels(this.channels);
    this.listener.unsubscribe();
  }
}
