<ng-template #contents let-data="data">
  <ng-container *ngIf="data.label">
    {{ language.getLabel(data.label) }}
  </ng-container>
  <ng-container *ngIf="data.value">
    {{ data.value }}
  </ng-container>
  <ng-container *ngIf="data.sections">
    <span *ngFor="let section of data.sections" [class]="section.class">
      <ng-container *ngIf="section.label">
        {{ language.getLabel(section.label) }}
      </ng-container>
      <ng-container *ngIf="section.value">
        {{ section.value }}
      </ng-container>
    </span>
  </ng-container>
  <!-- DEPRECATED -->
  <ng-container *ngIf="data.startingSpan && data.endingSpan">
    <span [class]="data.startingSpan.class">{{ data.startingSpan.value }}&nbsp;</span>
    <span [class]="data.endingSpan.class">{{ data.endingSpan.value }}</span>
  </ng-container>
  <ng-container *ngIf="data.subtitle">
    <span [class]="data.subtitleClass">{{ language.getLabel(data.subtitle) }}</span>
  </ng-container>
</ng-template>

<h1 *ngIf="data.level === 1" [class]="data.class">
  <ng-container *ngTemplateOutlet="contents; context: { data: data }"></ng-container>
</h1>
<h2 *ngIf="data.level === 2" [class]="data.class">
  <ng-container *ngTemplateOutlet="contents; context: { data: data }"></ng-container>
</h2>
<h3 *ngIf="data.level === 3" [class]="data.class">
  <ng-container *ngTemplateOutlet="contents; context: { data: data }"></ng-container>
</h3>
<h4 *ngIf="data.level === 4" [class]="data.class">
  <ng-container *ngTemplateOutlet="contents; context: { data: data }"></ng-container>
</h4>
<h5 *ngIf="data.level === 5" [class]="data.class">
  <ng-container *ngTemplateOutlet="contents; context: { data: data }"></ng-container>
</h5>
<h6 *ngIf="data.level === 6" [class]="data.class">
  <ng-container *ngTemplateOutlet="contents; context: { data: data }"></ng-container>
</h6>
