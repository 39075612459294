import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { ISlotComponent } from '../../slot/slot-component';
import { ApiService } from '../../../backbone/api.service';
import { PermissionsService } from '../../../backbone/permissions.service';
import { take } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { EventBusService } from '../../../backbone/event-bus.service';
import { Router } from '@angular/router';
import { ExtRoute } from '../../../backbone/interfaces/ext-route.interface';
import { LinkPipe } from '../../../backbone/pipes/link.pipe';
import { ScrollService } from '../../../backbone/scroll.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [LinkPipe]
})
export class MenuComponent implements OnInit, ISlotComponent {
  @Input() public data: any;
  @Input() public menuItems: object;
  @Input() public topId = 0;
  @ViewChildren(MatMenuTrigger) triggers: QueryList<MatMenuTrigger>;
  public subsc: Subscription;


  constructor(
    private api: ApiService,
    private eventBus: EventBusService,
    private router: Router,
    private permissionSevice: PermissionsService,
    private link: LinkPipe,
    public scrollService: ScrollService
  ) { }

  ngOnInit() {
    if (typeof this.menuItems === 'undefined') {
      this.menuItems = {};
      this.menuItems[this.topId] = [];
      const dataService = this.api.getService(this.data.dataSource.service);
      dataService[this.data.dataSource.method](this.data.dataSource.params)
        .pipe(take(1))
        .subscribe((response) => {
          for (const item of response.result.data.items) {
            let canShow = true;
            const routerData = this.router.config.filter(
              object => (
                typeof item.url === 'string'
                && object.path === item.url.substr(1)
              )
            ) as ExtRoute[];
            if (typeof routerData[0] !== 'undefined'
              && typeof routerData[0].requiredPermissions !== 'undefined'
              && this.permissionSevice.checkPermissions(routerData[0]) === false
            ) {
              canShow = false;
            }

            if (response.result.data.items.filter((i) => {
              return item.parent_id != null
                && i.id === item.parent_id;
            }).length > 0) {
              if (canShow) {
                if (!this.menuItems[item.parent_id]) {
                  this.menuItems[item.parent_id] = [];
                }
                this.menuItems[item.parent_id].push(item);
              }
            } else {
              if (canShow) {
                this.menuItems[0].push(item);
              }
            }
          }
        });
    }

    this.subsc = this.eventBus.on('toggleSidenav', (data) => {
      this.triggers.forEach(trigger => {
        trigger.openMenu();
      });
    });
  }

  getRouterLink(item) {
    if (item.url) {
      return item.url;
    } else if (typeof this.data.link !== 'undefined') {
      return this.link.transform(item, this.data.link);
    }
  }

  openMenu() {
    this.triggers.forEach(trigger => {
      trigger.openMenu();
    });
  }
  closeMenu() {
    this.triggers.forEach(trigger => {
      trigger.closeMenu();
    });
  }
}
