import { OverlayRef } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  private overlayRegistry: Map<string, OverlayRef> = new Map();

  constructor() { }

  public registerOverlay(id: string, ref: OverlayRef): void {
    this.overlayRegistry.set(id, ref);
  }

  public removeOverlay(id: string): void {
    this.overlayRegistry.delete(id);
  }

  public isOverlayRegistered(id: string): boolean {
    return this.overlayRegistry.has(id);
  }

  public getOverlayRef(id: string): OverlayRef {
    return this.overlayRegistry.get(id);
  }
}
