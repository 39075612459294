import { Pipe, PipeTransform } from '@angular/core';
import { GetArrayPathService } from '../get-array-path.service';

@Pipe({
  name: 'getArrayPath',
  pure: true
})
export class GetArrayPathPipe implements PipeTransform {
  constructor(public getArrayPath: GetArrayPathService) { }

  transform(input: Array<any> | {[key:string]: any}, path: string | Array<any> = ''): any {
    return this.getArrayPath.get(input, path);
  }

}
