<ng-container *ngIf="src">
  <ng-container *ngIf="onlyOriginal">
    <img
      [src]="src"
      [attr.class]="data.class"
      [attr.alt]="language.getLabel(data.alt)"
      [attr.title]="language.getLabel(data.title)"
    />
  </ng-container>
  <picture *ngIf="!onlyOriginal">
    <source srcset="{{src[size + 'webp']}}" type="image/webp" />
    <source
      *ngIf="
        src[size + 'original'].endsWith('.jpg') ||
        src[size + 'original'].endsWith('.jpeg')
      "
      srcset="{{src[size + 'original']}}"
      type="image/jpeg"
    />
    <source
      *ngIf="src[size + 'original'].endsWith('.png')"
      srcset="{{src[size + 'original']}}"
      type="image/png"
    />
    <img
      [src]="src[size + 'original']"
      [attr.class]="data.class"
      [attr.alt]="language.getLabel(data.alt)"
      [attr.title]="language.getLabel(data.title)"
    />
  </picture>
</ng-container>