import { Injectable, Injector } from "@angular/core";
import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  UntypedFormArray,
  UntypedFormGroup,
} from "@angular/forms";
import { FormArrayValidationFilter } from "./interfaces/form-array-validation-filter.interface";

@Injectable()
export class CustomValidators {
  static match(matchTo: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.parent &&
        control.parent.value &&
        control.value === control.parent.value[matchTo]
      ) {
        return null;
      } else {
        return { match: control.value };
      }
    };
  }

  static uniqueInArray(element: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (typeof control.parent !== "undefined" && control.parent !== null) {
        const group = control.parent;
        if (group.parent) {
          const formArrayObject = group.parent as UntypedFormArray;
          for (const arrayGroup of formArrayObject.controls) {
            if (
              arrayGroup !== group &&
              arrayGroup["controls"][element].value === value
            ) {
              return {
                uniqueInArray: control.value,
              };
            }
          }
        }
      }
      return null;
    };
  }

  static uniqueInEntireForm(element: string): ValidatorFn {
    function hasValue(object, value) {
      return Object.values(object).some((val) => {
        if (val === value) {
          return true;
        }
        if (val && typeof val === "object" && !Array.isArray(val)) {
          return hasValue(val, value);
        }
        if (val && Array.isArray(val)) {
          return (val as any[]).some((obj) => {
            return hasValue(obj, value);
          });
        }
      });
    }
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      let formGroup: AbstractControl = control.parent;

      if (typeof formGroup !== "undefined" && formGroup !== null) {
        while (formGroup.parent) {
          formGroup = formGroup.parent;
        }
      }

      if (formGroup) {
        const form: any = (formGroup as UntypedFormGroup).value;

        const formKeys = Object.keys(form);
        if (formKeys.length === 1 && typeof form[formKeys[0]] !== 'object') {
          return null;
        }

        if (hasValue(form, value)) {
          return {
            uniqueInEntireForm: control.value,
          };
        } else {
          return null;
        }
      }

      return null;
    };
  }

  static greaterThan(value: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent && control.parent.value && control.value > value) {
        return null;
      } else {
        return { greaterThan: value };
      }
    };
  }

  static formArraySumLtEq(
    controlName: string,
    limit: number,
    filter: FormArrayValidationFilter | null = null
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (typeof control.parent !== "undefined") {
        const formArray = control.parent.parent;
        if (formArray instanceof UntypedFormArray) {
          let sum = 0;
          const rows = formArray.controls;
          for (const row of rows) {
            const filterValue = row.get(filter.controlName).value;
            if (
              filter.controlValues.indexOf(filterValue) >= 0 &&
              row["groupUid"] === control.parent["groupUid"]
            ) {
              sum += parseInt(row.get(controlName).value, 10);
            }
          }
          if (sum > limit) {
            return {
              formArraySumLtEq: limit,
            };
          } else {
            for (const row of rows) {
              const filterValue = row.get(filter.controlName).value;
              if (
                filter.controlValues.indexOf(filterValue) >= 0 &&
                row["groupUid"] === control.parent["groupUid"]
              ) {
                row.get(controlName).setErrors(null);
              }
            }
          }
        }
      }
      return null;
    };
  }
  static formArraySumGtEq(
    controlName: string,
    limit: number,
    filter: FormArrayValidationFilter | null = null
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (typeof control.parent !== "undefined") {
        const formArray = control.parent.parent;
        if (formArray instanceof UntypedFormArray) {
          let sum = 0;
          const rows = formArray.controls;
          for (const row of rows) {
            const filterValue = row.get(filter.controlName).value;
            if (
              filter.controlValues.indexOf(filterValue) >= 0 &&
              row["groupUid"] === control.parent["groupUid"]
            ) {
              sum += parseInt(row.get(controlName).value, 10);
            }
          }
          if (sum < limit) {
            return {
              formArraySumGtEq: limit,
            };
          } else {
            for (const row of rows) {
              const filterValue = row.get(filter.controlName).value;
              if (
                filter.controlValues.indexOf(filterValue) >= 0 &&
                row["groupUid"] === control.parent["groupUid"]
              ) {
                row.get(controlName).setErrors(null);
              }
            }
          }
        }
      }
      return null;
    };
  }
  static formArraySumEq(
    controlName: string,
    limit: number,
    filter: FormArrayValidationFilter | null = null,
    deviation: number | null
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (typeof control.parent !== "undefined") {
        const formArray = control.parent.parent;
        if (formArray instanceof UntypedFormArray) {
          let sum = 0;
          const rows = formArray.controls;
          for (const row of rows) {
            const filterValue = row.get(filter.controlName).value;
            if (
              filter.controlValues.indexOf(filterValue) >= 0 &&
              row["groupUid"] === control.parent["groupUid"]
            ) {
              sum += parseInt(row.get(controlName).value, 10);
            }
          }
          let valid = false;
          if (deviation !== null) {
            if (sum >= limit - deviation && sum <= limit + deviation) {
              valid = true;
            }
          } else {
            if (sum === limit) {
              valid = true;
            }
          }
          for (const row of rows) {
            const filterValue = row.get(filter.controlName).value;
            if (
              filter.controlValues.indexOf(filterValue) >= 0 &&
              row["groupUid"] === control.parent["groupUid"]
            ) {
              row.get(controlName).setErrors(null);
            }
          }
          if (!valid) {
            return {
              formArraySumEq: {
                limit,
                sum,
              },
            };
          }
        }
      }
      return null;
    };
  }

  static formGroupNotEmpty(skipKeys: string[]): ValidatorFn {
    function hasAnyValue(object) {
      return Object.entries(object).some((item) => {
        if (skipKeys.indexOf(item[0]) < 0) {
            if (item[1] && typeof item[1] === "object" && !Array.isArray(item[1])) {
              return hasAnyValue(item[1]);
            }
            if (item[1] && Array.isArray(item[1])) {
              return (item[1] as any[]).some((obj) => {
                return hasAnyValue(obj);
              });
            }
            if (item[1]) {
                return true;
            }
        }
      });
    }

    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      if (!hasAnyValue(formGroup.value)) {
        return {
          formGroupNotEmpty: formGroup.value
        };
      }
      return null;
    };
  }

  static formGroupMaxValuesCount(params): ValidatorFn {
    let count = 0;
    function countValues(object) {
      if (count > 0) {
        count = 0;
      }
      Object.entries(object).some((item) => {
        if (params.skipKeys.indexOf(item[0]) < 0) {
          if (item[1] && typeof item[1] === "object" && !Array.isArray(item[1])) {
            return countValues(item[1]);
          }
          if (item[1] && Array.isArray(item[1])) {
            return (item[1] as any[]).some((obj) => {
              return countValues(obj);
            });
          }
          if (item[1]) {
            count++;
          }
        }
      });
      return count;
    }

    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      if (countValues(formGroup.value) > params.count) {
        return {
          formGroupMaxValuesCount: formGroup.value
        };
      }
      return null;
    };
  }
}
