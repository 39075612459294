import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressCircleComponent } from './progress-circle.component';
import { RoutingService } from '../../backbone/routing.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  declarations: [
    ProgressCircleComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  exports: [
    ProgressCircleComponent
  ]
})
export class ProgressCircleModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('ProgressCircleComponent', ProgressCircleComponent);
  }
}
