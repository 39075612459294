import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FacebookLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { ApiService } from '../../backbone/api.service';
import { LoginService } from '../../backbone/executables';
import { LanguageService } from '../../backbone/language.service';
import { ISlotComponent } from '../slot/slot-component';

@Component({
  selector: 'app-facebook-login',
  templateUrl: './facebook-login.component.html',
  styleUrls: ['./facebook-login.component.scss']
})
export class FacebookLoginComponent implements OnInit, ISlotComponent {
  @Input() public data: any = {};

  constructor(
    public language: LanguageService,
    private authService: SocialAuthService,
    private loginService: LoginService,
    protected api: ApiService,
  ) {}

  ngOnInit(): void {
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(result => {
      const dataService = this.api.getService(this.data.dataSource.service);
      dataService[this.data.dataSource.method]({authToken: result.authToken})
        .subscribe((response) => {
          this.loginService.login(response, {
            redirect: this.data.redirectTo
          });
        });
    });
  }
}
