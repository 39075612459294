import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetQueryParamsAsObjectPipe } from './query-params.pipe';
import { ObjectToQueryParamsPipe } from './object-to-query.pipe';



@NgModule({
  declarations: [
    GetQueryParamsAsObjectPipe,
    ObjectToQueryParamsPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GetQueryParamsAsObjectPipe,
    ObjectToQueryParamsPipe
  ],
  providers: [
    GetQueryParamsAsObjectPipe,
    ObjectToQueryParamsPipe
  ]
})
export class QueryPipesModule { }
