import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { GetArrayPathService } from '../../../backbone/get-array-path.service';

@Component({
  selector: 'app-image-background',
  templateUrl: './image-background.component.html',
  styleUrls: ['./image-background.component.scss']
})
export class ImageBackgroundComponent implements OnInit {
  @Input() public data: any = {};
  public background: string = '';

  private sizeObserver;

  constructor(
    private getArrayPath: GetArrayPathService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.sizeObserver = this.breakpointObserver.observe(
      [
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ]
    );
  }

  ngOnInit(): void {
    let src
    if (typeof this.data.srcPath !== 'undefined') {
      // url comes from data object
      src = this.getArrayPath.get(this.data.dataObject, this.data.srcPath);
    } else {
      // direct url configured
      src = this.data.src;
    }

    const srclastDotIdx = src.lastIndexOf('.');
    const srcPath = src.slice(0, srclastDotIdx);
    const srcFileExt = src.slice(srclastDotIdx);

    // prepare fallback image
    let srcFallback;
    if (typeof this.data.srcFallbackPath !== 'undefined') {
      // url comes from data object
      srcFallback = this.getArrayPath.get(this.data.dataObject, this.data.srcFallbackPath);
    } else if (this.data.srcFallback) {
      // direct url configured
      srcFallback = this.data.srcFallback;
    }

    let srcFallbackPath;
    let srcFallbackFileExt;
    if (typeof srcFallback !== 'undefined') {
      const srcFallbacklastDotIdx = srcFallback.lastIndexOf('.');
      srcFallbackPath = srcFallback.slice(0, srcFallbacklastDotIdx);
      srcFallbackFileExt = srcFallback.slice(srcFallbacklastDotIdx);
    }

    this.sizeObserver
      .pipe(take(1)).subscribe(size => {
        // (max-width: 599.98px)
        if (size.breakpoints[Breakpoints.XSmall]) {
          src = srcPath + '_xsmall' + srcFileExt;
          if (typeof srcFallback !== 'undefined') {
            srcFallback = srcFallbackPath + '_xsmall' + srcFallbackFileExt;
          }
        }
        // (min-width: 600px) and (max-width: 959.98px)
        if (size.breakpoints[Breakpoints.Small]) {
          src = srcPath + '_small' + srcFileExt;
          if (typeof srcFallback !== 'undefined') {
            srcFallback = srcFallbackPath + '_small' + srcFallbackFileExt;
          }
        }
        // (min-width: 960px) and (max-width: 1279.98px)
        if (size.breakpoints[Breakpoints.Medium]) {
          src = srcPath + '_medium' + srcFileExt;
          if (typeof srcFallback !== 'undefined') {
            srcFallback = srcFallbackPath + '_medium' + srcFallbackFileExt;
          }
        }
        // (min-width: 1280px) and (max-width: 1919.98px)
        if (size.breakpoints[Breakpoints.Large]) {
          src = srcPath + '_large' + srcFileExt;
          if (typeof srcFallback !== 'undefined') {
            srcFallback = srcFallbackPath + '_large' + srcFallbackFileExt;
          }
        }
        // (min-width: 1920px)
        if (size.breakpoints[Breakpoints.XLarge]) {
          src = srcPath + '_xlarge' + srcFileExt;
          if (typeof srcFallback !== 'undefined') {
            srcFallback = srcFallbackPath + '_xlarge' + srcFallbackFileExt;
          }
        }

        if (typeof this.data.overlay !== 'undefined') {
          this.background += this.data.overlay + ', ';
        }
        this.background += 'url(' + src + ')';
        if (typeof srcFallback !== 'undefined') {
          this.background += ',url(' + srcFallback + ')'
        }
      });
  }
}
