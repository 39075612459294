<ng-container *ngIf="parentForm" [formGroup]="parentForm">
  <mat-slider
    [disabled]="data.disabled"
    [invert]="data.invert"
    [vertical]="data.vertical"
    [max]="data.max ? data.max : null"
    [min]="data.min ? data.min : null"
    [value]="data.value"
    [formControlName]="data.name"
    (change)="change($event)"
  >
  </mat-slider>
</ng-container>
<ng-container *ngIf="!parentForm">
  <mat-slider
    [disabled]="data.disabled"
    [invert]="data.invert"
    [vertical]="data.vertical"
    [max]="data.max ? data.max : null"
    [min]="data.min ? data.min : null"
    [value]="data.value"
    (change)="change($event)"
  >
  </mat-slider>
</ng-container>
