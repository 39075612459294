<div class="d-flex flex-wrap">
  <ng-container *ngIf="parentForm">
    <mat-form-field
      appearance="fill"
      class="flex-fill"
      [formGroup]="parentForm"
      (click)="pickerFrom.open()"
    >
      <mat-label>{{ language.getLabel(data.from.placeholder) }}</mat-label>

      <input
        matInput
        [matDatepicker]="pickerFrom"
        [placeholder]="language.getLabel(data.from.placeholder)"
        (dateChange)="change($event, 'from')"
        [(value)]="from"
        [disabled]="data.from.inputDisabled"
        [formControlName]="data.name + '_from'"
        (click)="click($event)"
      />

      <mat-datepicker-toggle
        matSuffix
        [for]="pickerFrom"
      ></mat-datepicker-toggle>

      <mat-datepicker
        #pickerFrom
        [color]="data.from.color"
        [opened]="data.from.opened"
        [disabled]="data.from.disabled"
        [startView]="data.from.startView"
      >
      </mat-datepicker>
    </mat-form-field>

    <mat-form-field
      appearance="fill"
      class="flex-fill"
      [formGroup]="parentForm"
      (click)="pickerTo.open()"
    >
      <mat-label>{{ language.getLabel(data.to.placeholder) }}</mat-label>

      <input
        matInput
        [matDatepicker]="pickerTo"
        [placeholder]="language.getLabel(data.to.placeholder)"
        (dateChange)="change($event, 'to')"
        [(value)]="to"
        [disabled]="data.to.inputDisabled"
        [formControlName]="data.name + '_to'"
        (click)="click($event)"
      />

      <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>

      <mat-datepicker
        #pickerTo
        [color]="data.to.color"
        [opened]="data.to.opened"
        [disabled]="data.to.disabled"
        [startView]="data.to.startView"
      >
      </mat-datepicker>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="!parentForm">
    <mat-form-field
      appearance="fill"
      class="flex-fill"
      (click)="pickerFrom.open()"
    >
      <mat-label>{{ language.getLabel(data.from.placeholder) }}</mat-label>

      <input
        matInput
        [matDatepicker]="pickerFrom"
        [placeholder]="language.getLabel(data.from.placeholder)"
        (dateChange)="change($event, 'from')"
        [(value)]="from"
        [disabled]="data.from.inputDisabled"
        (click)="click($event)"
      />

      <mat-datepicker-toggle
        matSuffix
        [for]="pickerFrom"
      ></mat-datepicker-toggle>

      <mat-datepicker
        #pickerFrom
        [color]="data.from.color"
        [opened]="data.from.opened"
        [disabled]="data.from.disabled"
        [startView]="data.from.startView"
      >
      </mat-datepicker>
    </mat-form-field>

    <mat-form-field
      appearance="fill"
      class="flex-fill"
      (click)="pickerTo.open()"
    >
      <mat-label>{{ language.getLabel(data.to.placeholder) }}</mat-label>

      <input
        matInput
        [matDatepicker]="pickerTo"
        [placeholder]="language.getLabel(data.to.placeholder)"
        (dateChange)="change($event, 'to')"
        [(value)]="to"
        [disabled]="data.to.inputDisabled"
        (click)="click($event)"
      />

      <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>

      <mat-datepicker
        #pickerTo
        [color]="data.to.color"
        [opened]="data.to.opened"
        [disabled]="data.to.disabled"
        [startView]="data.to.startView"
      >
      </mat-datepicker>
    </mat-form-field>
  </ng-container>

  <button
    (click)="changePeriod('prev')"
    *ngIf="from && to && data.showPrevButton"
    mat-mini-fab
    color="primary"
  >
    <mat-icon>navigate_before</mat-icon>
  </button>
  <button
    (click)="changePeriod('next')"
    *ngIf="from && to && data.showNextButton"
    mat-mini-fab
    color="primary"
  >
    <mat-icon>navigate_next</mat-icon>
  </button>
</div>
