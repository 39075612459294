import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GetArrayPathService } from '../../backbone/get-array-path.service';
import { LanguageService } from '../../backbone/language.service';
import { ISlotComponent } from '../slot/slot-component';
import { FormComponent } from '../form/form.component';

@Component({
  selector: 'app-tabbed',
  templateUrl: './tabbed.component.html',
  styleUrls: ['./tabbed.component.scss']
})
export class TabbedComponent implements OnInit, OnDestroy, ISlotComponent {
  @Input() public data: any;

  public selfRef: any;
  public buttons;

  private formsData: UntypedFormGroup[] = [];
  private forms: FormComponent[] = [];
  private formsState = [];
  private subsc: Subscription[] = [];

  constructor(
    public language: LanguageService,
    public getArrayPath: GetArrayPathService,
    private cdRef: ChangeDetectorRef,
    public location: Location
  ) { }

  ngOnInit(): void {
    this.selfRef = this;
  }
  registerForm(form: UntypedFormGroup, formComponent: FormComponent) {
    if (typeof this.data.singleSubmit === 'undefined') {
      this.data.singleSubmit = true;
    }
    this.formsData.push(form);
    this.formsState.push(form.value);
    const tab = this.formsData.length - 1;
    this.subsc.push(form.valueChanges.subscribe(change => {
      if (typeof this.data.tabs[tab].reload !== 'undefined') {
        const oldValue = this.getArrayPath.get(
          this.formsState[tab],
          this.data.tabs[tab].reload.onChangePath
        );
        const newValue = this.getArrayPath.get(
          change,
          this.data.tabs[tab].reload.onChangePath
        );
        if (oldValue !== newValue) {
          const paramsToAdd = {};
          for (const key in this.data.tabs[tab].reload.addParams) {
            if (this.data.tabs[tab].reload.addParams[key]) {
              paramsToAdd[key] = this.getArrayPath.get(
                change,
                this.data.tabs[tab].reload.addParams[key]
              );
            }
          }
          const formKey = this.data.tabs[tab].reload.tab - 1;
          this.forms[formKey].load(paramsToAdd);
        }
        this.formsState[tab] = change;
      }
    }));
    this.forms.push(formComponent);
    if (typeof this.buttons === 'undefined') {
      // if forms are registered add submit and cancel button
      this.buttons = [{
        color: 'primary',
        type: 'mat-flat-button',
        label: this.language.getLabel('App_cancel'),
        class: '',
        click: function() {
          this.location.back();
        }.bind(this)
      },
      {
        color: 'primary',
        type: 'mat-flat-button',
        label: this.language.getLabel('App_submit'),
        class: 'ml-1',
        click: this.submit.bind(this)
      }];
      this.cdRef.detectChanges();
    }
  }

  private submit() {
    if (typeof this.data.submit === 'undefined') {
      // submit all registered forms seperately to their own submits
      // TODO figure out a way to make a batch jsonrpc request
      const formStatus = new Array(this.forms.length).fill(false);
      let i = 0;
      for (const form of this.forms) {
        form.data.submitSuccess = (params) => {
          formStatus[i] = params;
          if (formStatus.every(status => status)) {
            if (typeof this.data.submitSuccess === 'function') {
              if (!this.data.submitSuccessParams) {
                this.data.submitSuccessParams = {forms: formStatus};
                this.data.submitSuccessParams.event = 'submitSuccess';
                if (this.data.dataObject) {
                  this.data.submitSuccessParams.dataObject = this.data.dataObject;
                }
              }
  
              const result = this.data.submitSuccess(this.data.submitSuccessParams);
            }
          }
          i++;
        }
        form.onSubmit();
      }
    } else {
      // TODO: submit all forms as one to the submit of the tabbed component
    }
  }

  ngOnDestroy(): void {
    this.subsc.forEach(entry => {
      entry.unsubscribe();
    });
  }
}
