import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlotDialogComponent } from './slot-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { SlotModule } from '../slot/slot.module';



@NgModule({
  declarations: [SlotDialogComponent],
  imports: [
    CommonModule,
    SlotModule,
    MatDialogModule,
    MatButtonModule
  ]
})
export class SlotDialogModule { }
