<mat-stepper
  [class]="data.class"
  #stepper
  [orientation]="data.orientation"
  [linear]="data.linear"
>
  <ng-container *ngFor="let step of steps; let i = index">
    <mat-step
      [completed]="completed[i]"
      [editable]="step.editable"
      [state]="step.state"
      [class]="step.class"
    >
      <ng-template matStepLabel *ngIf="step.label || step.labelComponents">
        {{ language.getLabel(step.label) }}
        <app-slot
          *ngIf="step.labelComponents"
          [items]="step.labelComponents"
          [componentValue]="data.dataObject | getArrayPath: data.path"
          [parentComponent]="selfRef"
          style="display: block; width: 100%; height: 100%"
        ></app-slot>
      </ng-template>
      <ng-template *ngIf="isLazy(step)" matStepContent>
        <app-slot
          [items]="step.components"
          [componentValue]="data.dataObject | getArrayPath: data.path"
          [parentComponent]="selfRef"
          style="display: block; width: 100%; height: 100%"
        ></app-slot>
      </ng-template>
      <ng-container *ngIf="!isLazy(step)">
        <app-slot
          [items]="step.components"
          [componentValue]="data.dataObject | getArrayPath: data.path"
          [parentComponent]="selfRef"
          style="display: block; width: 100%; height: 100%"
        ></app-slot>
      </ng-container>
    </mat-step>
  </ng-container>
</mat-stepper>
