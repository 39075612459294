import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { ApiService } from '../../backbone/api.service';
import { CommunicationService, Message } from '../../backbone/communication.service';
import { GetArrayPathService } from '../../backbone/get-array-path.service';
import { ISlotComponent } from '../slot/slot-component';

interface Milestone {
  index: number;
  length: number;
  position?: number;
  tooltip?: any;
  big?: boolean;
}

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnDestroy, ISlotComponent {
  public milestones: Milestone[] = [];
  public milestoneIcon = 'trip_origin'
  public direction: 'horizontal' | 'vertical' = 'horizontal';
  public progress = 0;
  public barClass = '';
  public length = 0;
  private barStyle: 'solid' | 'dashed' = 'solid';
  @Input() public data: any;
  private destroyed = new Subject<void>();

  constructor(
    private comm: CommunicationService,
    private api: ApiService,
    private getArrayPath: GetArrayPathService
  ) { }

  ngOnInit(): void {
    this.handleInput();
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }

    if (typeof this.data.dataSource !== 'undefined') {
      this.load();
    }
  }
  public handleInput() {
    if (typeof this.data.direction !== 'undefined') {
      this.direction = this.data.direction;
    }

    if (typeof this.data.milestones !== 'undefined') {
      this.milestones = this.data.milestones;
    } else if (typeof this.data.milestonesPath !== 'undefined') {
      this.milestones = this.getArrayPath.get(
        this.data.dataObject,
        this.data.milestonesPath
      );
    }

    if (
      typeof this.data.milestoneMap !== 'undefined' &&
      typeof this.milestones !== 'undefined'
    ) {
      this.milestones = this.milestones.map((milestone) => {
        const mapped: Milestone = {
          index: this.getArrayPath.get(milestone, this.data.milestoneMap.index),
          length: this.getArrayPath.get(milestone, this.data.milestoneMap.length),
        }
        return mapped;
      });
    }
    if (typeof this.data.length !== 'undefined') {
      this.length = this.data.length;
    } else if (typeof this.milestones !== 'undefined') {
      let length = 0;
      this.milestones.forEach(item => length += item.length);
      this.length = length;
    }

    if (typeof this.data.progress !== 'undefined') {
      this.progress = this.data.progress / this.length * 100;
    } else if (typeof this.data.progressPath !== 'undefined') {
      const progress = this.getArrayPath.get(
        this.data.dataObject,
        this.data.progressPath
      );
      if (Array.isArray(progress)) {
        setTimeout(() => {
          let newProgress = 0;
          progress.forEach((i) => newProgress += i)
          this.progress = newProgress;
        }, 0);
      } else if (typeof progress !== 'undefined') {
        setTimeout(() => this.progress = progress, 0);
      }
    }
    if (typeof this.data.barStyle !== 'undefined') {
      this.barStyle = this.data.barStyle;
    }

    if (typeof this.data.milestoneIcon !== 'undefined') {
      this.milestoneIcon = this.data.milestoneIcon;
    }
    if (typeof this.data.barClass === 'undefined') {
      this.barClass = this.barStyle;
    } else {
      this.barClass += ' ' + this.data.barClass;
    }

    if (this.progress >= this.length) {
      this.progressBarStatus('Full');
    } else {
      this.progressBarStatus('Partial');
    }
  }
  private load() {
    this.api.callServiceMethod(this.data.dataSource)
      .pipe(take(1))
      .subscribe((response) => {
        this.data.dataObject = response.result.data;
      });
  }

  incrementProgress() {
    this.setProgress(this.progress + 1);
  }
  decrementProgress() {
    this.setProgress(this.progress - 1);
  }
  setProgress(progress) {
    if (progress < 0) {
      progress = 0;
    } else if (progress > this.length) {
      progress = this.length;
    }
    this.progress = progress;
  }
  getMilestonePosition(milestone) {
    const prev = this.milestones.filter(item => item.index < milestone.index);
    let length = milestone.length;
    prev.forEach(item => length += item.length);
    const position = length / this.length * 100;
    milestone.position = position;
    return this[this.direction + 'XY'](position);
  }
  getPosition(position, type = 'XY') {
    if (position >= this.length) {
      position = this.length;
      this.progressBarStatus('Full');
    } else {
      this.progressBarStatus('Partial');
    }

    position = position / this.length * 100;
    const result = this[this.direction + type](position);
    return result;
  }
  private horizontalXY(position) {
    return {
      left: (position > 50) ? null : position + '%',
      right: (position > 50) ? (100 - position) + '%' : null
    };
  }
  private verticalXY(position) {
    return {
      top: (position > 50) ? null : position + '%',
      bottom: (position > 50) ? (100 - position) + '%' : null
    };
  }
  private horizontalDIM(position) {
    return {
      width: position + '%'
    };
  }
  private verticalDIM(position) {
    return {
      height: position + '%'
    };
  }

  private progressBarStatus(status: 'Full' | 'Partial') {
    if (typeof this.data[`progressBar${status}`] === 'function') {
      if (!this.data[`progressBar${status}Params`]) {
        this.data[`progressBar${status}Params`] = {};
      }
      this.data[`progressBar${status}Params`].event = `progressBar${status}`;

      const result = this.data[`progressBar${status}`](this.data[`progressBar${status}Params`]);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
