<!-- If we want loading bar before when loading -->
<div *ngIf="data.progressBar" class="loadingContainer">
  <!-- <mat-icon class="loadingIcon">{{data.loadingIcon}}</mat-icon> -->
  <div class="loadingComponent">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>
<ng-container *ngIf="image && image.length > 1">
  <swiper
    mainSwiper
    [config]="mainBannerConfig"
    [thumbs]="{ swiper: thumbsSwiper }"
    [parallax]="data.parallax"
    >
    <ng-container *ngIf="!data.parallax">
      <ng-template swiperSlide *ngFor="let item of image">
        <!-- If sliding html items -->
        <ng-container *ngIf="!item.file">
          <div class="swiper-zoom-container">
            <!-- <ng-container *ngIf="data.parallax && data.content && data.parallaxOnImage">
              <div class="swiper-zoom-container parallax-content {{data.parallaxContentClass}}">
                  <app-slot
                    [items]="data.content.components"
                    [componentValue]="item | getArrayPath: data.content.path"
                    style="display: block; width: 100%; height: 100%"
                  ></app-slot>
              </div>
            </ng-container> -->
            <ng-container *ngIf="!isObject(item.url)">
              <img (click)="click()" [src]="item.url" />
            </ng-container>
            <ng-container *ngIf="isObject(item.url)">
              <picture>
                <source srcset="{{item.url[urlKeyWebp]}}" type="image/webp" />
                <source
                  *ngIf="
                    item.url[urlKey].endsWith('.jpg') || item.url[urlKey].endsWith('.jpeg')
                  "
                  srcset="{{item.url[urlKey]}}"
                  type="image/jpeg"
                />
                <source
                  *ngIf="item.url[urlKey].endsWith('.png')"
                  srcset="{{item.url[urlKey]}}"
                  type="image/png"
                />
                <img (click)="click()" [src]="item.url[urlKey]" />
              </picture>
            </ng-container>
          </div>
        </ng-container>
        <!-- If sliding images -->
        <ng-container *ngIf="item.file">
          <div class="swiper-zoom-container">
            <a href="{{ item.url }}">
              <ng-container *ngIf="!isObject(item.file.url)">
                <img (click)="click()" [src]="item.file.url" />
              </ng-container>
              <ng-container *ngIf="isObject(item.file.url)">
                <picture>
                  <source srcset="{{item.file.url[urlKeyWebp]}}" type="image/webp" />
                  <source
                    *ngIf="
                      item.file.url[urlKey].endsWith('.jpg') ||
                      item.file.url[urlKey].endsWith('.jpeg')
                    "
                    srcset="{{item.file.url[urlKey]}}"
                    type="image/jpeg"
                  />
                  <source
                    *ngIf="item.file.url[urlKey].endsWith('.png')"
                    srcset="{{item.file.url[urlKey]}}"
                    type="image/png"
                  />
                  <img (click)="click()" [src]="item.file.url[urlKey]" />
                </picture>
              </ng-container>
            </a>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="data.parallax && data.content">
      <ng-template swiperSlide *ngFor="let item of image; let i = index">
        <div class="swiper-zoom-container" (click)="click(i)">
          <app-slot
            [items]="data.content.components"
            [componentValue]="item | getArrayPath : data.content.path"
            style="display: block; width: 100%; height: 100%"
          ></app-slot>
        </div>
      </ng-template>
    </ng-container>
  </swiper>
  <ng-container *ngIf="data.thumbs && !data.parallax">
    <swiper thumbsSwiper [config]="thumbnails" (swiper)="thumbsSwiper = $event">
      <ng-template swiperSlide *ngFor="let item of image">
        <ng-container *ngIf="!item.file">
          <div class="swiper-zoom-container">
            <ng-container *ngIf="!isObject(item.url)">
              <img [src]="item.url" />
            </ng-container>
            <ng-container *ngIf="isObject(item.url)">
              <picture>
                <source srcset="{{item.url[urlKeyWebp]}}" type="image/webp" />
                <source
                  *ngIf="
                    item.url[urlKey].endsWith('.jpg') || item.url[urlKey].endsWith('.jpeg')
                  "
                  srcset="{{item.url[urlKey]}}"
                  type="image/jpeg"
                />
                <source
                  *ngIf="item.url[urlKey].endsWith('.png')"
                  srcset="{{item.url[urlKey]}}"
                  type="image/png"
                />
                <img [src]="item.url[urlKey]" />
              </picture>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="item.file">
          <div class="swiper-zoom-container">
            <ng-container *ngIf="!isObject(item.file.url)">
              <img [src]="item.file.url" />
            </ng-container>
            <ng-container *ngIf="isObject(item.file.url)">
              <picture>
                <source srcset="{{item.file.url[urlKeyWebp]}}" type="image/webp" />
                <source
                  *ngIf="
                    item.file.url[urlKey].endsWith('.jpg') ||
                    item.file.url[urlKey].endsWith('.jpeg')
                  "
                  srcset="{{item.file.url[urlKey]}}"
                  type="image/jpeg"
                />
                <source
                  *ngIf="item.file.url[urlKey].endsWith('.png')"
                  srcset="{{item.file.url[urlKey]}}"
                  type="image/png"
                />
                <img [src]="item.file.url[urlKey]" />
              </picture>
            </ng-container>
          </div>
        </ng-container>
      </ng-template>
    </swiper>
  </ng-container>
</ng-container>

<ng-container *ngIf="image && image.length == 1">
  <div *ngFor="let item of image" class="single-slide" [class]="'col-' + singleSlideWidth">
    <ng-container *ngIf="data.content">
      <div class="swiper-zoom-container parallax-content">
        <app-slot
          [items]="data.content.components"
          [componentValue]="item | getArrayPath : data.content.path"
          style="display: block; width: 100%; height: 100%"
        ></app-slot>
      </div>
    </ng-container>
    <ng-container *ngIf="item.file && item.file.type == 'image'">
      <a [href]="item.url">
        <ng-container *ngIf="!isObject(item.file.url)">
          <img (click)="click()" [src]="item.file.url" class="w-100" />
        </ng-container>
        <ng-container *ngIf="isObject(item.file.url)">
          <picture class="w-100">
            <source srcset="{{item.file.url[urlKeyWebp]}}" type="image/webp" />
            <source
              *ngIf="
                item.file.url[urlKey].endsWith('.jpg') ||
                item.file.url[urlKey].endsWith('.jpeg')
              "
              srcset="{{item.file.url[urlKey]}}"
              type="image/jpeg"
            />
            <source
              *ngIf="item.file.url[urlKey].endsWith('.png')"
              srcset="{{item.file.url[urlKey]}}"
              type="image/png"
            />
            <img (click)="click()" [src]="item.file.url[urlKey]" />
          </picture>
        </ng-container>
      </a>
    </ng-container>
    <ng-container *ngIf="!item.file">
      <ng-container *ngIf="!isObject(item.url)">
        <img (click)="click()" [src]="item.url" class="w-100" />
      </ng-container>
      <ng-container *ngIf="isObject(item.url)">
        <picture class="w-100">
          <source srcset="{{item.url[urlKeyWebp]}}" type="image/webp" />
          <source
            *ngIf="item.url[urlKey].endsWith('.jpg') || item.url[urlKey].endsWith('.jpeg')"
            srcset="{{item.url[urlKey]}}"
            type="image/jpeg"
          />
          <source
            *ngIf="item.url[urlKey].endsWith('.png')"
            srcset="{{item.url[urlKey]}}"
            type="image/png"
          />
          <img (click)="click()" [src]="item.url[urlKey]" />
        </picture>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
