<div id="pac-container" *ngIf="data.autocomplete">
  <input
    id="autocomplete"
    type="text"
    [placeholder]="language.getLabel(data.autocompletePlaceholder)"
  />
</div>
<google-map
  [ngClass]="{ sized: data.options.width || data.options.height }"
  [ngStyle]="{
    width: data.options.width || 'auto',
    height: data.options.height || 'auto'
  }"
  id="map"
  [options]="data.options"
  #googleMap
  *ngIf="apiLoaded"
>
  <!-- <map-marker
    #markerEl="mapMarker"
    width="100%"
    *ngFor="let marker of markers"
    [position]="marker.position"
    [label]="marker.label"
    [title]="marker.title"
    [icon]="marker.icon"
    (mapClick)="openInfoWindow(markerEl, marker)"
  >
  </map-marker>
  <ng-container *ngIf="data.shape == 'polygon'">
    <map-polygon [paths]="data.coordinates"></map-polygon>
  </ng-container>
  <ng-container *ngIf="data.shape == 'rectangle'">
    <map-rectangle [bounds]="data.coordinates"></map-rectangle>
  </ng-container>
  <ng-container *ngIf="data.shape == 'circle'">
    <map-circle
      [center]="data.coordinates.circleCenter"
      [radius]="data.coordinates.circleRadius"
    >
    </map-circle>
  </ng-container> -->
  <map-marker-clusterer *ngIf="data.clustering" [imagePath]="data.markerClustererImagePath">
    <map-marker 
      *ngFor="let markerPosition of markerPositions"
      [position]="markerPosition">
    </map-marker>
  </map-marker-clusterer>
  <map-info-window>
    <ng-container *ngIf="infoContent">
      {{ infoContent.title }}

      <!--Render html elements in info window-->
      <ng-container *ngIf="infoContent.html">
        <app-html [innerHTML]="infoContent.infoWindowDescription"></app-html>
      </ng-container>
      <ng-container
        *ngIf="!infoContent.html && infoContent.infoWindowDescription"
      >
        {{ infoContent.infoWindowDescription }}
      </ng-container>

      <!--Visualize image in info window if there is any-->
      <ng-container *ngIf="infoContent.infoWindowImage">
        <img
          [title]="infoContent.infoWindowImage.title"
          [src]="infoContent.infoWindowImage.url"
        />
      </ng-container>
    </ng-container>
  </map-info-window>
</google-map>
