import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './progress-bar.component';
import { RoutingService } from '../../backbone/routing.service';
import { MatIconModule } from '@angular/material/icon';
import { SlotModule } from '../slot/slot.module';


@NgModule({
  declarations: [
    ProgressBarComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    SlotModule
  ]
})
export class ProgressBarModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('ProgressBarComponent', ProgressBarComponent);
  }
}
