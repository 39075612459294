<div *ngIf="parentForm" [formGroup]="parentForm" class="d-flex {{ data.class }}">
  <mat-label *ngIf="data.group.label" class="margin-bottom-10-block">
    {{ language.getLabel(data.group.label) }}
  </mat-label>
  <mat-radio-group
    [class]="data.group.class"
    [color]="data.group.color"
    [labelPosition]="data.group.labelPosition"
    [formControlName]="data.group.name"
  >
    <mat-radio-button
      *ngFor="let option of data.options"
      [value]="getValue(option)"
      [disabled]="option.disabled"
      [color]="option.color"
      (change)="change($event)"
    >
      <ng-container *ngIf="option.name">
        {{ language.getLabel(option.name) }}
        <div *ngIf="option.subtitle" [innerHTML]="language.getLabel(option.subtitle)"></div>
      </ng-container>
      <app-slot
        *ngIf="data.components"
        [items]="data.components"
        [componentValue]="option"
        [parentForm]="parentForm"
        style="display: block; width: 100%; height: 100%"
      ></app-slot>
    </mat-radio-button>
  </mat-radio-group>
</div>
<div *ngIf="!parentForm" class="d-flex {{ data.class }}">
  <mat-label *ngIf="data.group.label" class="margin-bottom-10-block">
    {{ language.getLabel(data.group.label) }}
  </mat-label>
  <mat-radio-group
    [class]="data.group.class"
    [color]="data.group.color"
    [labelPosition]="data.group.labelPosition"
    [required]="data.group.required"
    [(ngModel)]="selected"
  >
    <mat-radio-button
      *ngFor="let option of data.options"
      [checked]="option.checked"
      [value]="getValue(option)"
      [disabled]="option.disabled"
      [color]="option.color"
      (change)="change($event)"
    >
      <ng-container *ngIf="!data.components">
        {{ language.getLabel(option.name) }}
      </ng-container>
      <app-slot
        *ngIf="data.components"
        [items]="data.components"
        [componentValue]="option"
        style="display: block; width: 100%; height: 100%"
      ></app-slot>
    </mat-radio-button>
  </mat-radio-group>
</div>
