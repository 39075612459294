import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor(public router: Router) { }

  /**
   * Adds parameters to the url, removes top level params if they already exist
   * @param params the array of parameters to be added, may contain base url
   * @param action add | toggle
   */
  toUrl(params, action = 'add') {
    let baseSegments = [];
    let newUrl;
    let querySplit;
    const clonedParams = Object.assign({}, params);
    if (typeof clonedParams.baseUrl !== 'undefined') {
      newUrl = [clonedParams.baseUrl];
      delete clonedParams.baseUrl;
    } else {
      querySplit = this.router.routerState.snapshot.url.split('?');
      const urlSplit = querySplit[0].split(';');
      newUrl = [urlSplit.shift()];
      baseSegments.push(...urlSplit.filter(segment => {
        if (action === 'add') {
          const keyValueSplit = segment.split('=');
          const path = keyValueSplit[0].split('.');
          let self = clonedParams;
          let i = 1;
          for (const key of path) {
            if (typeof self[key] !== 'undefined') {
              if (i < path.length) {
                self = self[key];
                i++;
              } else {
                return false;
              }
            }
          }
        } else {
          for (const group in clonedParams) {
            if (segment.startsWith(group)) {
              if (action === 'toggleGroup') {
                delete clonedParams[group];
              }
              return false;
            }
          }
        }
        return true;
      }));
    }
    const filter = this.objectToUrlString(clonedParams).split(';');
    baseSegments = baseSegments.concat(filter).sort().reverse();
    newUrl = newUrl.concat(baseSegments);

    let url = newUrl.join(';');
    if (typeof querySplit !== 'undefined' && typeof querySplit[1] !== 'undefined') {
      url += '?' + querySplit[1];
    }
    this.router.navigateByUrl(url);
  }

  private objectToUrlString(obj, group = null) {
    const segments = [];
    for (const key in obj) {
      if (obj[key] !== '') {
        let pair = '';
        if (group !== null) {
          pair += group + '.' + key;
        }
        if (typeof obj[key] === 'object') {
          if (Array.isArray(obj[key])) {
            if (obj[key].length === 0) {
              continue;
            }
            pair += '=' + obj[key].join(',');
          } else if (
            typeof obj[key].min === 'number'
            && typeof obj[key].max === 'number'
          ) {
            pair += '=' + Object.values(obj[key]).join('..');
          } else {
            let rightSide;
            if (group === null) {
              rightSide = this.objectToUrlString(obj[key], key);
            } else {
              rightSide = this.objectToUrlString(obj[key], group);
            }
            if (rightSide) {
              pair += rightSide;
            } else {
              continue;
            }
          }
        } else {
          if (pair === '') {
            pair += key;
          }
          pair += '=' + obj[key];
        }
        segments.push(pair);
      }
    }
    return segments.join(';');
  }
  // urlStringToObject(str) {
  //   const obj = {};
  //   const semiColonSplit = str.split(';');
  //   let group;
  //   for (const segment of semiColonSplit) {
  //     const eqSplit = segment.split('=');
  //     if (eqSplit.length > 1) {
  //       const commaSplit = eqSplit[1].split(',');
  //       if (commaSplit.length > 1) {
  //         obj[group][eqSplit[0]] = commaSplit;
  //       } else {
  //         obj[group][eqSplit[0]] = commaSplit[0];
  //       }
  //     } else {
  //       group = eqSplit[0];
  //       obj[group] = {};
  //     }
  //   }

  //   return obj;
  // }
}
