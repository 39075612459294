import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ApiService } from '../../../backbone/api.service';
import { LanguageService } from '../../../backbone/language.service';
import { ScrollService } from '../../../backbone/scroll.service';
import { LinkPipe } from '../../../backbone/pipes/link.pipe';

@Component({
  selector: 'app-nested-menu',
  templateUrl: './nested-menu.component.html',
  styleUrls: ['./nested-menu.component.scss']
})
export class NestedMenuComponent extends BaseComponent implements OnInit {

  @Input() public data: any;

  constructor(
    protected api: ApiService,
    public language: LanguageService,
    public scroll: ScrollService,
    public link: LinkPipe
  ) {
    super(language, scroll, api, link);
  }

  ngOnInit(): void {

    this.load()
  }
}