import { Component, OnInit, Input } from '@angular/core';
import { ISlotComponent } from '../../../slot/slot-component';
import { Observable } from 'rxjs';
import { LanguageService } from '../../../../backbone/language.service';
import { UntypedFormGroup } from '@angular/forms';
import { GetArrayPathService } from '../../../../backbone/get-array-path.service';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent implements OnInit, ISlotComponent {
  @Input() public data: any = {};
  @Input() public parentForm: UntypedFormGroup;

  constructor(
    public language: LanguageService,
    private getArrPath: GetArrayPathService
    ) { }

  ngOnInit() {
    if(typeof this.data.path !== 'undefined'){
      this.data.checked = !!this.getArrPath.get(this.data.dataObject, this.data.path)
    } else {
      this.data.checked = Boolean(this.data.checked);
    }
  }

  change(slideToggle) {
    if (typeof this.data.change === 'function') {
      if (!this.data.changeParams) {
        this.data.changeParams = {};
      }
      this.data.changeParams.value = slideToggle.checked;
      this.data.changeParams.event = 'change';
      const result = this.data.change(this.data.changeParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }
}
