<!-- grid -->
<div *ngIf="data.display === 'grid'" class="row" [class]="data.gridRowClass">
  <div
    *ngFor="let item of items"
    [class]="data.gridColClass"
    [routerLink]="item | link: data.link"
  >
    <mat-card>
      <app-slot
        *ngIf="data.image"
        [items]="[data.image.component]"
        [componentValue]="item | getArrayPath: data.image.path"
      ></app-slot>
      <mat-card-content *ngIf="data.content">
        <app-slot
          [items]="[data.content.component]"
          [componentValue]="item | getArrayPath: data.content.path"
          style="display: block; width: 100%; height: 100%"
        ></app-slot>
      </mat-card-content>
      <mat-card-actions *ngIf="data.actions">
        <app-slot
          [items]="data.actions.components"
          [componentValue]="item | getArrayPath: data.actions.path"
          style="display: block; width: 100%; height: 100%"
        ></app-slot>
      </mat-card-actions>
    </mat-card>
  </div>
  <div 
    *ngIf="data.components"
    [class]="data.gridColClass"
  >
    <app-slot
      *ngIf="data.components"
      [items]="data.components"
    ></app-slot>
  </div>
</div>

<!-- list -->
<ng-container *ngIf="data.display === 'list'">
  <div class="row" *ngFor="let item of items" [class]="data.listRowClass">
    <div [class]="data.listColClass" [routerLink]="item | link: data.link">
      <mat-card>
        <mat-card-title-group class="row no-gutters">
          <app-slot
            *ngIf="data.image"
            [items]="[data.image.component]"
            [componentValue]="item | getArrayPath: data.image.path"
            class="col"
          ></app-slot>
          <mat-card-content *ngIf="data.content" class="col pl-2 list-content">
            <app-slot
              [items]="[data.content.component]"
              [componentValue]="item | getArrayPath: data.content.path"
              style="display: block; width: 100%; height: 100%"
            ></app-slot>
          </mat-card-content>
        </mat-card-title-group>
        <mat-card-actions *ngIf="data.actions">
          <app-slot
            [items]="data.actions.components"
            [componentValue]="item | getArrayPath: data.actions.path"
            style="display: block; width: 100%; height: 100%"
          ></app-slot>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <div 
    *ngIf="data.components"
    [class]="data.listColClass"
  >
    <app-slot
      *ngIf="data.components"
      [items]="data.components"
    ></app-slot>
  </div>
</ng-container>

<mat-paginator
  *ngIf="page.size"
  showFirstLastButtons
  [length]="page.length"
  [pageSize]="page.size"
  (page)="handlePaginator($event)"
>
</mat-paginator>
