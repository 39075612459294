<div [class]="data.class" [ngClass]="{ 'mat-elevation-z8': !data.inline }">
  <mat-tab-group dynamicHeight [headerPosition]="data.headerPosition">
    <ng-container *ngFor="let tab of data.tabs">
      <mat-tab *ngIf="tab.lazyLoad">
        <ng-template matTabLabel>
          <span
            *ngIf="tab.errors"
            matBadge="!"
            matBadgeColor="warn"
            matBadgeSize="small"
            matBadgeOverlap="false"
            matBadgePosition="above before"
          >
            {{ language.getLabel(tab.title) }}
          </span>
          <span *ngIf="true">{{ language.getLabel(tab.title) }}</span>
        </ng-template>
        <ng-template matTabContent>
          <app-slot
            [items]="tab.components"
            [componentValue]="tab.dataObject | getArrayPath: tab.path"
            [parentComponent]="selfRef"
          ></app-slot>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="!tab.lazyLoad">
        <ng-template matTabLabel>
          <span
            *ngIf="tab.errors"
            matBadge="!"
            matBadgeColor="warn"
            matBadgeSize="small"
            matBadgeOverlap="false"
            matBadgePosition="above before"
          >
            {{ language.getLabel(tab.title) }}
          </span>
          <span *ngIf="true">{{ language.getLabel(tab.title) }}</span>
        </ng-template>
        <app-slot
          [items]="tab.components"
          [componentValue]="tab.dataObject | getArrayPath: tab.path"
          [parentComponent]="selfRef"
        ></app-slot>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
  <div class="row pb-1" *ngIf="data.singleSubmit">
    <div class="col text-center">
      <app-button [data]="button" *ngFor="let button of buttons"></app-button>
    </div>
  </div>
</div>
