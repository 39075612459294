import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { InjectionToken } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../../backbone/communication.service';
import { EventBusService } from '../../backbone/event-bus.service';

export const OVERLAY_TOKEN = new InjectionToken<any>('overlay-data');

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit, OnDestroy {
  @Output() closeOverlay = new EventEmitter<void>();

  private channels: Set<string> = new Set();
  private listener: Subscription;
  public selfRef: OverlayComponent;

  constructor(@Inject(OVERLAY_TOKEN) public data: any,
              private eventBus: EventBusService,
              private comm: CommunicationService) { }

  ngOnInit(): void {
    this.selfRef = this;

    this.listener = this.eventBus.on('newChannelRegistered', (channelUid) =>
      this.registerChannel(channelUid)
    );
  }

  close() {
    if (this.data.disableMouseLeave) {
      return;
    }
    this.closeOverlay.emit();
  }

  registerChannel(channel: string) {
    this.channels.add(channel);
  }

  ngOnDestroy(): void {
    this.comm.resetChannels(this.channels);
    this.listener.unsubscribe();
  }
}
