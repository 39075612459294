<div (mouseleave)="close()">
  <app-slot
    [items]="data.components"
    [componentValue]="data.dataObject | getArrayPath: data.path"
    style="display: block; width: 100%; height: 100%"
  ></app-slot>
</div>



