import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../../../backbone/api.service';
import { CommunicationService, Message } from '../../../backbone/communication.service';
import { EvalService } from '../../../backbone/eval.service';
import { GetArrayPathService } from '../../../backbone/get-array-path.service';
import { QueryService } from '../../../backbone/query.service';
import { ISlotComponent } from '../../slot/slot-component';
import { GetArrayPathPipe } from '../../../backbone/pipes/get-array-path.pipe';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
  providers: [GetArrayPathPipe]
})
export class CardListComponent implements OnInit, OnDestroy, ISlotComponent {
  @Input() public data: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public items = [];
  public page = {
    size: 0,
    length: 0
  };

  private destroyed = new Subject<void>();
  private urlParams;

  constructor(
    private api: ApiService,
    public query: QueryService,
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private comm: CommunicationService,
    private evaluator: EvalService,
    private getArrayPath: GetArrayPathService,
    private getArrayPathPipe: GetArrayPathPipe,
  ) { }

  ngOnInit(): void {
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }
    this.breakpointObserver.observe([
      '(min-width: 992px)'
    ])
      .pipe(takeUntil(this.destroyed)).subscribe((size) => {
        if (size.matches) {
          if (typeof this.data.listCondition !== 'undefined') {
            const result = this.getArrayPath.get(null, this.data.listCondition.path);
            if (result instanceof Observable) {
              const that = this;
              result.pipe(
                takeUntil(this.destroyed),
                map(data => {
                  return this.evaluator.exec(data, this.data.listCondition.condition);
                }),
                filter((data: boolean) => data),
                tap(() => {
                  that.data.display = 'list';
                })
              ).subscribe(() => { });
            } else if (this.evaluator.exec(null, this.data.listCondition)) {
              this.data.display = 'list';
            }
          }
        } else if (typeof this.data.display === 'undefined') {
          this.data.display = 'grid';
        }
      });
    if (typeof this.data.dataSource !== 'undefined') {
      if (this.data.reloadsWithUrl) {
        // Refresh data if query params or url param has been changed
        combineLatest([
          this.route.queryParams.pipe(startWith(null)),
          this.route.params.pipe(startWith(null))
        ]).pipe(takeUntil(this.destroyed), debounceTime(0))
          .subscribe((params) => {
            const urlParams = JSON.parse(JSON.stringify(params));
            this.query.updateQueryUrl(this.route.snapshot);
            if (
              typeof this.paginator !== 'undefined'
              && (typeof urlParams[0].page === 'undefined' || !urlParams[0].page)
            ) {
              urlParams[0].page = '1';
              this.paginator.firstPage();
            }
            if (
              this.urlParams
              && JSON.stringify(this.urlParams) !== JSON.stringify(urlParams)
            ) {
              this.load();
              this.changeDisplay();
            }
            this.urlParams = urlParams;
          });
      }
      this.load();
    }
    // execute init event handler if such exists
    if (typeof this.data.init === 'function') {
      if (!this.data.initParams) {
        this.data.initParams = {};
      }
      this.data.initParams.event = 'init';
      if (this.data.dataObject) {
        this.data.initParams.dataObject = this.data.dataObject;
      }
      const result = this.data.init(this.data.initParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }
  private changeDisplay() {
    this.breakpointObserver.observe([
      '(min-width: 992px)'
    ])
      .pipe(take(1)).subscribe((size) => {
        if (size.matches) {
          if (typeof this.data.listCondition !== 'undefined') {
            const result = this.getArrayPath.get(null, this.data.listCondition.path);
            if (result instanceof Observable) {
              const that = this;
              result.pipe(
                takeUntil(this.destroyed),
                map(data => {
                  return this.evaluator.exec(data, this.data.listCondition.condition);
                }),
                filter((data: boolean) => data),
                tap(() => {
                  that.data.display = 'list';
                })
              ).subscribe(() => { });
            } else if (this.evaluator.exec(null, this.data.listCondition)) {
              this.data.display = 'list';
            }
          }
        } else if (typeof this.data.display === 'undefined') {
          this.data.display = 'grid';
        }
      });
  }
  private load() {
    this.api.callServiceMethod(this.data.dataSource)
      .pipe(take(1))
      .subscribe((response) => {
        if (typeof this.data.dataSource.path !== 'undefined') {
          this.items = this.getArrayPathPipe.transform(
            response.result.data,
            this.data.dataSource.path
        );
        } else {
          this.items = response.result.data;
        }
        if (response.result.meta) {
          this.page.length = response.result.meta.total;
          this.page.size = response.result.meta.per_page;
        }

        if (typeof this.data.loaded === 'function') {
          this.data.dataObject = response.result.data;
          this.data.loadedParams = {};
          this.data.loadedParams.event = 'loaded';
          if (this.data.dataObject) {
            this.data.loadedParams.dataObject = this.data.dataObject;
          }

          const result = this.data.loaded(this.data.loadedParams);
          if (result instanceof Observable) {
            result.pipe(take(1)).subscribe();
          }
        }
      });
  }

  handlePaginator(e: PageEvent) {
    const page = e.pageIndex + 1;
    this.router.navigate([], { queryParams: { page }, queryParamsHandling: 'merge' })
      .then(() => {
        this.query.updateQueryUrl(this.route.snapshot);
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
