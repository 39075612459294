import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ISlotComponent } from '../../slot/slot-component';
import { LanguageService } from '../../../backbone/language.service';
import { Subscription, Observable } from 'rxjs';
import { EventBusService } from '../../../backbone/event-bus.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit, OnDestroy, ISlotComponent {
  private subsc: Subscription;
  @Input() public data: any = {};
  @Input() public parentForm: UntypedFormGroup;

  constructor(
    public language: LanguageService,
    private eventBus: EventBusService
  ) { }

  ngOnInit() {
    this.subsc = this.eventBus.on('actionBarControlReset', (data) => {
      if (
        typeof data.actionParams === 'undefined'
        || typeof data.actionParams.controls === 'undefined'
        || (
          typeof data.actionParams !== 'undefined'
          && typeof data.actionParams.controls !== 'undefined'
          && data.actionParams.controls.indexOf(this.data.actionId) >= 0
        )
      ) {
        this.data.checked = this.data.originalValue;
        if (typeof this.data.change === 'function') {
          const result = this.data.change({
            id: this.data.actionId,
            checked: this.data.checked
          });
          if (result instanceof Observable) {
            result.subscribe();
          }
        }
      }
    });

    // Set checked options when data.options exists
    if (typeof this.data.options !== 'undefined') {
      const val = this.parentForm.get(this.data.name).value;
      const splitted = val.split(',');

      for (const i in this.data.options) {
          // tslint:disable-next-line: forin
        for (const splitValue of splitted) {
          if (this.data.options[i].value === splitValue) {
            this.data.options[i].checked = true;
          }
        }
      }
    }
  }

  change(option?) {
    if (typeof this.data.change === 'function') {
      if (!this.data.changeParams) {
        this.data.changeParams = {};
      }

      if (typeof this.data.options !== 'undefined') {
        this.data.changeParams.value = this.data.options;
      } else {
        if (typeof this.data.name !== 'undefined') {
          this.data.changeParams.value = this.data.name;
        }
        this.data.changeParams.checked = this.data.checked;
      }

      const result = this.data.change(this.data.changeParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }

    // Set value on change when data.options exists
    if (typeof this.data.options !== 'undefined') {
      if (option.checked) {
        option.checked = false;
      } else {
        option.checked = true;
      }

      let val = '';
      for (const i in this.data.options) {
        if (this.data.options[i].checked) {
          val += this.data.options[i].value + ',';
        }
      }
      if (val.endsWith(',')){
        val = val.substring(0, val.length - 1);
      }
      this.parentForm.get(this.data.name).setValue(val);
    }
  }

  ngOnDestroy(): void {
    this.subsc.unsubscribe();
  }
}
