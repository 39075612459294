import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardListComponent } from './card-list.component';
import { RoutingService } from '../../../backbone/routing.service';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { SlotModule } from '../../slot/slot.module';
import { PrimitiveModule } from '../../primitive/primitive.module';
import { PipesModule } from '../../../backbone/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { MatPaginatorModule } from '@angular/material/paginator';



@NgModule({
  declarations: [
    CardListComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    SlotModule,
    MatCardModule,
    MatDividerModule,
    MatPaginatorModule,
    PipesModule,
    PrimitiveModule
  ]
})
export class CardListModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('CardListComponent', CardListComponent);
  }
}
