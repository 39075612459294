import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../backbone/api.service';
import { CommunicationService, Message } from '../../../backbone/communication.service';
import { ExtRoute } from '../../../backbone/interfaces/ext-route.interface';
import { PermissionsService } from '../../../backbone/permissions.service';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss']
})
export class HorizontalMenuComponent implements OnInit, OnDestroy {
  @Input() public data: any;
  public menuItems: object;
  public hamburger;

  private destroyed = new Subject<void>();

  constructor(
    private api: ApiService,
    private router: Router,
    private permissionSevice: PermissionsService,
    private comm: CommunicationService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe([
      '(min-width: 992px)'
    ])
      .pipe(takeUntil(this.destroyed)).subscribe(size => {
        if (size.matches) {
          this.hamburger = false;
        } else {
          this.hamburger = true;
        }
      });
  }

  ngOnInit() {
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }
    this.load();
  }

  public load() {
    this.menuItems = { 0: [] };
    if (typeof this.data.xPosition === 'undefined') {
      this.data.xPosition = 'after';
    }
    this.api.callServiceMethod(this.data.dataSource)
      .pipe(take(1))
      .subscribe((response) => {
        for (const item of response.result.data.items) {
          let canShow = true;
          const routerData = this.router.config.filter(
            object => (
              typeof item.url === 'string'
              && object.path === item.url.substr(1)
            )
          ) as ExtRoute[];
          if (typeof routerData[0] !== 'undefined'
            && typeof routerData[0].requiredPermissions !== 'undefined'
            && this.permissionSevice.checkPermissions(routerData[0]) === false
          ) {
            canShow = false;
          }

          if (response.result.data.items.filter((i) => {
            return item.parent_id != null
              && i.id === item.parent_id;
          }).length > 0) {
            if (canShow) {
              if (!this.menuItems[item.parent_id]) {
                this.menuItems[item.parent_id] = [];
              }
              this.menuItems[item.parent_id].push(item);
            }
          } else {
            if (canShow) {
              this.menuItems[0].push(item);
            }
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
