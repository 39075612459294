import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoutComponent } from './logout.component';
import { RoutingService } from '../../backbone/routing.service';



@NgModule({
  declarations: [
    LogoutComponent
  ],
  imports: [
    CommonModule
  ]
})
export class LogoutModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('LogoutComponent', LogoutComponent);
  }
}
