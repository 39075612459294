import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetArrayPathPipe } from './get-array-path.pipe';
import { JoinPipe } from './join.pipe';
import { TooltipContentPipe } from './tooltip-content.pipe';
import { LinkPipe } from './link.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  declarations: [
    GetArrayPathPipe,
    JoinPipe,
    TooltipContentPipe,
    LinkPipe,
    SafeHtmlPipe
  ],
  exports: [
    GetArrayPathPipe,
    JoinPipe,
    TooltipContentPipe,
    LinkPipe,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
    GetArrayPathPipe,
    JoinPipe,
    TooltipContentPipe,
    LinkPipe,
    SafeHtmlPipe
  ]
})
export class PipesModule { }
