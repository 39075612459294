import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleLoginComponent } from './google-login.component';
import { RoutingService } from '../../backbone/routing.service';
import { SlotModule } from '../slot/slot.module';
import { MatButtonModule } from '@angular/material/button';
import { PrimitiveModule } from '../primitive/primitive.module';
@NgModule({
    declarations: [GoogleLoginComponent],
    imports: [
        CommonModule,
        SlotModule,
        MatButtonModule,
        PrimitiveModule
    ],
    exports: [GoogleLoginComponent]
})
export class GoogleLoginModule {
    constructor(routing: RoutingService) {
        routing.mapComponent('GoogleLoginComponent', GoogleLoginComponent);
    }
}
