<!-- ANCHORS -->
<ng-container *ngIf="!data.print && data.link">
  <!-- mat-button -->
  <button
    *ngIf="data.type == 'mat-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="click($event)"
    (mouseover)="onEvent($event, 'mouseover')"
    [routerLink]="data.dataObject | link : data.link"
    type="button"
    mat-button
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'after'"
    ></app-icon>
  </button>
  <!--mat-raised-button-->
  <button
    *ngIf="data.type == 'mat-raised-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="click($event)"
    (mouseover)="onEvent($event, 'mouseover')"
    [routerLink]="data.dataObject | link : data.link"
    type="button"
    mat-raised-button
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'after'"
    ></app-icon>
  </button>
  <!--mat-stroked-button-->
  <button
    *ngIf="data.type == 'mat-stroked-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="click($event)"
    (mouseover)="onEvent($event, 'mouseover')"
    [routerLink]="data.dataObject | link : data.link"
    type="button"
    mat-stroked-button
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'after'"
    ></app-icon>
  </button>
  <!--mat-flat-button-->
  <button
    *ngIf="data.type == 'mat-flat-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="click($event)"
    (mouseover)="onEvent($event, 'mouseover')"
    [routerLink]="data.dataObject | link : data.link"
    type="button"
    mat-flat-button
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'after'"
    ></app-icon>
  </button>
  <!--mat-icon-button-->
  <button
    *ngIf="data.type == 'mat-icon-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="click($event)"
    (mouseover)="onEvent($event, 'mouseover')"
    [routerLink]="data.dataObject | link : data.link"
    type="button"
    mat-icon-button
  >
    <app-icon
      *ngIf="data.icon"
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
    ></app-icon>
  </button>
  <!--mat-mini-fab-->
  <button
    *ngIf="data.type == 'mat-mini-fab'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="click($event)"
    (mouseover)="onEvent($event, 'mouseover')"
    [routerLink]="data.dataObject | link : data.link"
    type="button"
    mat-mini-fab
  >
    <app-icon
      *ngIf="data.icon"
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
    ></app-icon>
  </button>
</ng-container>
<!-- BUTTONS -->
<ng-container *ngIf="!data.print && !data.link && !data.scrollToElement">
  <!-- mat-button -->
  <button
    *ngIf="data.type == 'mat-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="click($event)"
    (mouseover)="onEvent($event, 'mouseover')"
    [type]="data.buttonType"
    mat-button
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'after'"
    ></app-icon>

    <app-slot
      *ngIf="data.components"
      [items]="data.components"
      [componentValue]="data.dataObject | getArrayPath : data.path"
      [parentForm]="parentForm"
      style="display: block; width: 100%; height: 100%"
    ></app-slot>
  </button>
  <!--mat-raised-button-->
  <button
    *ngIf="data.type == 'mat-raised-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="click($event)"
    (mouseover)="onEvent($event, 'mouseover')"
    [type]="data.buttonType"
    mat-raised-button
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'after'"
    ></app-icon>
    <app-slot
      *ngIf="data.components"
      [items]="data.components"
      [componentValue]="data.dataObject | getArrayPath : data.path"
      [parentForm]="parentForm"
      style="display: block; width: 100%; height: 100%"
    ></app-slot>
  </button>
  <!--mat-stroked-button-->
  <button
    *ngIf="data.type == 'mat-stroked-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="click($event)"
    (mouseover)="onEvent($event, 'mouseover')"
    [type]="data.buttonType"
    mat-stroked-button
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'after'"
    ></app-icon>
    <app-slot
      *ngIf="data.components"
      [items]="data.components"
      [componentValue]="data.dataObject | getArrayPath : data.path"
      [parentForm]="parentForm"
      style="display: block; width: 100%; height: 100%"
    ></app-slot>
  </button>
  <!--mat-flat-button-->
  <button
    *ngIf="data.type == 'mat-flat-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="click($event)"
    (mouseover)="onEvent($event, 'mouseover')"
    [type]="data.buttonType"
    mat-flat-button
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'after'"
    ></app-icon>
    <app-slot
      *ngIf="data.components"
      [items]="data.components"
      [componentValue]="data.dataObject | getArrayPath : data.path"
      [parentForm]="parentForm"
      style="display: block; width: 100%; height: 100%"
    ></app-slot>
  </button>
  <!--mat-icon-button-->
  <button
    *ngIf="data.type == 'mat-icon-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="click($event)"
    (mouseover)="onEvent($event, 'mouseover')"
    [type]="data.buttonType"
    mat-icon-button
  >
    <app-icon
      *ngIf="!data.components && data.icon"
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      >{{ data.icon }}</app-icon
    >
    <app-slot
      *ngIf="data.components"
      [items]="data.components"
      [componentValue]="data.dataObject | getArrayPath : data.path"
      [parentForm]="parentForm"
      style="display: block; width: 100%; height: 100%"
    ></app-slot>
  </button>
  <!--mat-mini-fab-->
  <button
    *ngIf="data.type == 'mat-mini-fab'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="click($event)"
    (mouseover)="onEvent($event, 'mouseover')"
    [type]="data.buttonType"
    mat-mini-fab
  >
    <app-icon
      *ngIf="!data.components && data.icon"
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
    ></app-icon>
    <app-slot
      *ngIf="data.components"
      [items]="data.components"
      [componentValue]="data.dataObject | getArrayPath : data.path"
      [parentForm]="parentForm"
      style="display: block; width: 100%; height: 100%"
    ></app-slot>
  </button>
</ng-container>
<!-- PRINT BUTTONS -->
<ng-container *ngIf="data.print && !data.link">
  <!-- mat-button -->
  <button
    *ngIf="data.type == 'mat-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    mat-button
    [printSectionId]="data.print"
    [useExistingCss]="true"
    ngxPrint
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'after'"
    ></app-icon>
    <app-slot
      *ngIf="data.components"
      [items]="data.components"
      [componentValue]="data.dataObject | getArrayPath : data.path"
      [parentForm]="parentForm"
      style="display: block; width: 100%; height: 100%"
    ></app-slot>
  </button>
  <!--mat-raised-button-->
  <button
    *ngIf="data.type == 'mat-raised-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    mat-raised-button
    [printSectionId]="data.print"
    [useExistingCss]="true"
    ngxPrint
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'after'"
    ></app-icon>
    <app-slot
      *ngIf="data.components"
      [items]="data.components"
      [componentValue]="data.dataObject | getArrayPath : data.path"
      [parentForm]="parentForm"
      style="display: block; width: 100%; height: 100%"
    ></app-slot>
  </button>
  <!--mat-stroked-button-->
  <button
    *ngIf="data.type == 'mat-stroked-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    mat-stroked-button
    [printSectionId]="data.print"
    [useExistingCss]="true"
    ngxPrint
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'after'"
    ></app-icon>
    <app-slot
      *ngIf="data.components"
      [items]="data.components"
      [componentValue]="data.dataObject | getArrayPath : data.path"
      [parentForm]="parentForm"
      style="display: block; width: 100%; height: 100%"
    ></app-slot>
  </button>
  <!--mat-flat-button-->
  <button
    *ngIf="data.type == 'mat-flat-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    mat-flat-button
    [printSectionId]="data.print"
    [useExistingCss]="true"
    ngxPrint
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="!data.components && data.icon && data.iconPosition === 'after'"
    ></app-icon>
    <app-slot
      *ngIf="data.components"
      [items]="data.components"
      [componentValue]="data.dataObject | getArrayPath : data.path"
      [parentForm]="parentForm"
      style="display: block; width: 100%; height: 100%"
    ></app-slot>
  </button>
  <!--mat-icon-button-->
  <button
    *ngIf="data.type == 'mat-icon-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    mat-icon-button
    [printSectionId]="data.print"
    [useExistingCss]="true"
    ngxPrint
  >
    <app-icon
      *ngIf="!data.components && data.icon"
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
    ></app-icon>
    <app-slot
      *ngIf="data.components"
      [items]="data.components"
      [componentValue]="data.dataObject | getArrayPath : data.path"
      [parentForm]="parentForm"
      style="display: block; width: 100%; height: 100%"
    ></app-slot>
  </button>
  <!--mat-mini-fab-->
  <button
    [ngClass]="data.class"
    [color]="data.color"
    *ngIf="data.type == 'mat-mini-fab'"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    [printSectionId]="data.print"
    [useExistingCss]="true"
    ngxPrint
    mat-mini-fab
  >
    <app-icon
      *ngIf="!data.components && data.icon"
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      >{{ data.icon }}</app-icon
    >
    <app-slot
      *ngIf="data.components"
      [items]="data.components"
      [componentValue]="data.dataObject | getArrayPath : data.path"
      [parentForm]="parentForm"
      style="display: block; width: 100%; height: 100%"
    ></app-slot>
  </button>
</ng-container>
<ng-container *ngIf="data.scrollToElement">
  <!-- mat-button -->
  <button
    *ngIf="data.type == 'mat-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="scrollService.scrollToElement(data.scrollToElement)"
    (mouseover)="onEvent($event, 'mouseover')"
    type="button"
    mat-button
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'after'"
    ></app-icon>
  </button>
  <!--mat-raised-button-->
  <button
    *ngIf="data.type == 'mat-raised-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="scrollService.scrollToElement(data.scrollToElement)"
    (mouseover)="onEvent($event, 'mouseover')"
    type="button"
    mat-raised-button
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'after'"
    ></app-icon>
  </button>
  <!--mat-stroked-button-->
  <button
    *ngIf="data.type == 'mat-stroked-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="scrollService.scrollToElement(data.scrollToElement)"
    (mouseover)="onEvent($event, 'mouseover')"
    type="button"
    mat-stroked-button
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'after'"
    ></app-icon>
  </button>
  <!--mat-flat-button-->
  <button
    *ngIf="data.type == 'mat-flat-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="scrollService.scrollToElement(data.scrollToElement)"
    (mouseover)="onEvent($event, 'mouseover')"
    type="button"
    mat-flat-button
  >
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'before'"
    ></app-icon>
    {{ language.getLabel(data.label) }}
    <app-icon
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
      *ngIf="data.icon && data.iconPosition === 'after'"
    ></app-icon>
  </button>
  <!--mat-icon-button-->
  <button
    *ngIf="data.type == 'mat-icon-button'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="scrollService.scrollToElement(data.scrollToElement)"
    (mouseover)="onEvent($event, 'mouseover')"
    type="button"
    mat-icon-button
  >
    <app-icon
      *ngIf="data.icon"
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
    ></app-icon>
  </button>
  <!--mat-mini-fab-->
  <button
    *ngIf="data.type == 'mat-mini-fab'"
    [ngClass]="data.class"
    [color]="data.color"
    [disableRipple]="!data.ripple"
    [disabled]="data.disabled"
    [matBadge]="data.badge ? data.badge.value : null"
    [matBadgePosition]="data.badge ? data.badge.position : ''"
    [matBadgeColor]="data.badge ? data.badge.color : null"
    [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
    [matBadgeSize]="data.badge ? data.badge.size : null"
    [attr.alt]="language.getLabel(data.label)"
    [attr.title]="language.getLabel(data.label)"
    (click)="scrollService.scrollToElement(data.scrollToElement)"
    (mouseover)="onEvent($event, 'mouseover')"
    type="button"
    mat-mini-fab
  >
    <app-icon
      *ngIf="data.icon"
      [data]="{
        icon: data.icon,
        color: data.iconColor
      }"
    ></app-icon>
  </button>
</ng-container>