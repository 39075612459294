<ng-container *ngIf="!hamburger">
  <!-- REGULAR MENU -->
  <ng-container *ngFor="let item of menuItems[0]">
    <app-menu
      [data]="{
        item: item, 
        link: data.link, 
        hover: true, 
        class: data.class, 
        scrollToElement: data.scrollToElement,
        xPosition: data.xPosition
      }"
      [menuItems]="menuItems"
      [topId]="item.id"
    ></app-menu>
  </ng-container>
</ng-container>
<ng-container *ngIf="hamburger">
  <!-- HAMBURGER MENU (WHEN <= Medium Breakpoint) -->
  <app-menu
    [data]="{
      icon: 'menu',
      link: data.link,
      hamburger: true,
      class: data.class,
      xPosition: 'before',
      scrollToElement: data.scrollToElement
    }"
    [menuItems]="menuItems"
  ></app-menu>
</ng-container>
