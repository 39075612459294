import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../../backbone/api.service';
import { LanguageService } from '../../../backbone/language.service';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {
  @Input() public data: any = {};
  public external = false;

  constructor(public language: LanguageService, private api: ApiService) { }

  ngOnInit(): void {
    if (typeof this.data.base !== 'undefined') {
      this[this.data.base]();
    }
    if (this.data.url.indexOf('://') > 0) {
      this.external = true;
    }
  }

  private apiAccessPointHost() {
    // TODO should be able to select the access point to use
    // Default access point used for now
    const config = this.api.getService('ConfigService').getAccessPoint();
    let apiAccessPointUrl = config.url.protocol + '://';
    apiAccessPointUrl += config.url.hostname;
    this.data.url = apiAccessPointUrl + this.data.url;
  }
}
