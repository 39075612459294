<app-slot
  *ngIf="data.control"
  [items]="[data.control]"
  [componentValue]="data.dataObject | getArrayPath: data.path"
  [parentForm]='parentForm'
></app-slot>
<span
  *ngIf="!data.control"
  (click)="data.events.click && doAction({ event: 'click' })"
  (mouseover)="data.events.mouseover && doAction({ event: 'mouseover' })"
>
  {{ data.dataObject | getArrayPath: data.path }}
</span>
