import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from "../../backbone/language.service";
import { take, takeUntil } from 'rxjs/operators';
import { CommunicationService, Message } from '../../backbone/communication.service';
import { MetaService } from '../../backbone/meta.service';
import { ISlotComponent } from '../slot/slot-component';
import { Subject } from 'rxjs';
import { EventBusService } from '../../backbone/event-bus.service';

@Component({
  selector: 'app-base-layout',
  template: '',
  styleUrls: []
})
export class BaseLayoutComponent implements ISlotComponent {
  @Input() data: any;

  private destroyed = new Subject<void>();
  
  constructor(
    public route: ActivatedRoute,
    public comm: CommunicationService,
    public metaService: MetaService,
    public language: LanguageService,
    public eventBus: EventBusService,
  ) { 
    this.route.data.pipe(take(1)).subscribe(data => {
      this.data = data;
      if (typeof data.channel !== 'undefined') {
        this.comm.getChannel(data.channel)
          .pipe(takeUntil(this.destroyed))
          .subscribe((message: Message) => this.comm.processMessage(message, this));
      } else {
        //Trigger update meta when no dynamic data needed
        this.updateMeta();
      }
    });

    // Update meta data on page initial load after labels are loaded
    this.eventBus.on('labelsLoaded', () => this.updateMeta());
  }

  updateMeta(): void {
    if(this.data.metaTitle){
      const title = this.language.getLabel(this.data.metaTitle); 
      this.metaService.updateTitle(title);
    }
    if(this.data.metaDescription){
      const description = this.language.getLabel(this.data.metaDescription);
      this.metaService.updateDescription(description);
    }
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.comm.resetChannels();
  }
}
