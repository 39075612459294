import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ObjectMergerService {

  constructor() { }

  private isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  deep(target, source) {
    const output = JSON.parse(JSON.stringify(target));
    if (this.isObject(target) && this.isObject(source)) {
      Object.keys(source).forEach(key => {
        if (this.isObject(source[key])) {
          if (!(key in target)) {
            if (source[key] === null) {
              delete output[key];
            } else {
              Object.assign(output, { [key]: source[key] });
            }
          } else {
            output[key] = this.deep(target[key], source[key]);
          }
        } else if (Array.isArray(source[key])) {
          if (typeof output[key] === 'undefined') {
            output[key] = [];
          }
          output[key].push(...source[key]);
        } else {
          if (source[key] === null) {
            delete output[key];
          } else {
            Object.assign(output, { [key]: source[key] });
          }
        }
      });
    }
    return output;
  }
}
