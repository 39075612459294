import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { RoutingService } from '../../backbone/routing.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PrimitiveModule } from '../primitive/primitive.module';
import { PipesModule } from '../../backbone/pipes/pipes.module';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { SlotModule } from '../slot/slot.module';
import { MapModule } from '../map/map.module';
import { AddressAutocompleteModule } from '../address-autocomplete/address-autocomplete.module';

@NgModule({
  declarations: [FormComponent],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MapModule,
    AddressAutocompleteModule,
    MatSlideToggleModule,
    MatGridListModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    SlotModule,
    PrimitiveModule,
    PipesModule,
    MatTabsModule,
    MatBadgeModule,
    MatDialogModule,
    DragDropModule
  ]
})
export class FormModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('FormComponent', FormComponent);
  }
}
