<div class="mat-elevation-z8" *ngIf="willLoad" [id]="data.id" [class]="data.class">
  <div class="responsiveTable">
    <table class="table-list" mat-table [dataSource]="filteredRows" matSort>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item; let index = index">
          <app-checkbox
            [data]="checkbox[index]"
            (click)="selectRow(index)"
          ></app-checkbox>
        </td>
      </ng-container>

      <ng-container
        [matColumnDef]="column.label || column.id"
        *ngFor="let column of listedColumns"
        [sticky]="column.sticky || null"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [class]="column.class"
        >
          <span *ngIf="!column.sort">{{
            language.getLabel(column.label)
          }}</span>
          <a
            *ngIf="column.sort"
            (click)="sort(column.sort)"
            href="javascript:void(0)"
          >
            <mat-icon
              class="sortIcon"
              *ngIf="
                query.getCurrentSortDir(column.sort.path, column.sort) == 'desc'
              "
              >arrow_drop_down</mat-icon
            >
            <mat-icon
              class="sortIcon"
              *ngIf="
                query.getCurrentSortDir(column.sort.path, column.sort) == 'asc'
              "
              >arrow_drop_up</mat-icon
            >
            {{ language.getLabel(column.label) }}
          </a>
          <a
            *ngIf="
              column.sort &&
              query.getCurrentSortDir(column.sort.path, column.sort) != null
            "
            (click)="sort(column.sort, true)"
            href="javascript:void(0)"
          >
            <mat-icon class="sortIcon">clear</mat-icon>
          </a>
        </th>
        <td mat-cell *matCellDef="let item" [class]="column.class">
          <!-- If column contains component(s) -->
          <ng-container *ngIf="
            column.components
            && (
              (column.condition && evaluator.exec(item, column.condition))
              || !column.condition
            )">
            <app-slot
              [items]="column.components"
              [componentValue]="item | getArrayPath: column.path"
              [parentForm]="parentForm"
              style="display: block; width: 100%; height: 100%"
            ></app-slot>
          </ng-container>
          <!--If the column contains just a value-->
          <ng-container
            *ngIf="
              !column.components &&
              !column.link &&
              !column.externalLink &&
              !column.download
            "
          >
            <!-- content from multiple response paths -->
            <ng-container *ngIf="column.multipath">
              <ng-container *ngFor="let path of column.multipath; let isLast = last">
                <span *ngIf="!column.transform">
                  {{ item | getArrayPath: path }}
                </span>
                <span *ngIf="column.transform">
                  {{
                    transformData.transform(
                      item | getArrayPath: path,
                      column.transform
                    )
                  }}
                </span>
                <ng-container
                *ngIf="column.multipathGlue && !isLast && (item | getArrayPath : path)"
                >{{ column.multipathGlue }}</ng-container
              >
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!column.multipath">
              <ng-container *ngIf="column.html">
                <div
                  class="html-preview"
                  [innerHTML]="item | getArrayPath: column.path | safeHtml"
                ></div>
              </ng-container>
              <ng-container *ngIf="!column.html">
                <span *ngIf="!column.transform">{{
                  item | getArrayPath: column.path
                }}</span>
                <span *ngIf="column.transform">{{
                  transformData.transform(
                    item | getArrayPath: column.path,
                    column.transform
                  )
                }}</span>
              </ng-container>
            </ng-container>
          </ng-container>
          <!--If the column has action and the action is link-->
          <a
            *ngIf="column.link && !column.components"
            [routerLink]="item | link: column.link"
          >
            <span *ngIf="!column.transform">{{
              item | getArrayPath: column.path
            }}</span>
            <span *ngIf="column.transform">{{
              transformData.transform(
                item | getArrayPath: column.path,
                column.transform
              )
            }}</span>
          </a>
          <a
            *ngIf="column.externalLink && !column.components"
            [href]="item | link: column.externalLink"
          >
            <span *ngIf="!column.transform">{{
              item | getArrayPath: column.path
            }}</span>
            <span *ngIf="column.transform">{{
              transformData.transform(
                item | getArrayPath: column.path,
                column.transform
              )
            }}</span>
          </a>
          <ng-container *ngIf="column.download">
            <app-download [data]="item"></app-download>
          </ng-container>
        </td>
      </ng-container>

      <ng-container *ngIf="actionColAppended" matColumnDef="action">
        <th mat-header-cell class="last-table-header" *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let item; let index = index"
          class="action-link"
        >
          <ng-container *ngFor="let action of data.actions">
            <ng-container *ngIf="!action.denied">
              <a
                *ngIf="action.link && action.id != 'delete'"
                [routerLink]="[action.link, item.id]"
              >
                <button
                  mat-icon-button
                  [attr.alt]="language.getLabel(action.name)"
                  [attr.title]="language.getLabel(action.name)"
                >
                  <mat-icon [ngClass]="action.id + '-icon'">{{
                    action.id
                  }}</mat-icon>
                </button>
              </a>
              <span *ngIf="action.id == 'delete'">
                <button
                  [attr.alt]="language.getLabel('App_delete')"
                  [attr.title]="language.getLabel('App_delete')"
                  mat-icon-button
                  (click)="delete(action, index)"
                >
                  <mat-icon class="delete-icon">{{
                    language.getLabel("delete")
                  }}</mat-icon>
                </button>
              </span>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="cols"></tr>
      <tr
        class="users-table-rows"
        mat-row
        *matRowDef="let row; columns: cols"
        (click)="rowClick($event)"
        [class]="rowNgClass(row)"
      ></tr>
    </table>
  </div>
  <mat-paginator
    *ngIf="pageSize"
    showFirstLastButtons
    [length]="length"
    [pageSize]="pageSize"
    (page)="handlePaginator($event)"
  >
  </mat-paginator>
</div>
