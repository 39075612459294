import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { ServiceCall } from './interfaces/service-call.interface';
import { normalizeCommonJSImport } from './normalizeCommonJSImport';
import { env } from './interfaces/env.type';
import { EvalService } from "./eval.service";
import { EMPTY } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private sdk: any;
  private env: env;
  private route: ActivatedRouteSnapshot;

  constructor(
    private injector: Injector,
    private evaluator: EvalService,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.route = event.snapshot
      }
    })
  }

  public async loadSdk(env: env) {
    this.env = env;
    const sdkLoader = normalizeCommonJSImport(env.sdk);
    this.sdk = await sdkLoader;
    const configService = this.injector.get<any>(this.sdk.ConfigService);
    if (typeof env.accessPoints !== 'undefined') {
      for (const apName in env.accessPoints) {
        configService.setAccessPoint(env.accessPoints[apName], apName);
      }
    } else if(typeof env.apiAccessPoint !== 'undefined') {
      // deprecated
      const accessPoint: {[key:string]: any} = {
        url: env.apiAccessPoint,
      };
      if (typeof env.apiToken !== 'undefined') {
        accessPoint.token = env.apiToken;
      }
      configService.setAccessPoint(accessPoint);
    } else {
      throw 'No api access point configuration present in environment';
    }
  }

  getEnv() {
    return this.env;
  }

  getStatic(key) {
    return this.sdk[key];
  }
  getService(service) {
    return this.injector.get<any>(this.sdk[service]);
  }

  setObjectPath(path, value, result) {
    path.split(".").reduce((obj, i, iter, pathSplit) => {
      if (typeof obj[i] === "undefined") {
        if (iter === pathSplit.length - 1) {
          if (typeof value === "string" && value.indexOf(",") >= 0) {
            value = value.split(",");
          }
          return (obj[i] = value);
        }
        return (obj[i] = {});
      } else {
        return obj[i];
      }
    }, result);
  }

  prepServiceCallParams(params = {}) {
    // If params has dynamic params from route url - search and replace them
    const parsedParams = {};
    for (const param in this.route.params) {
      if (param.indexOf('.') >= 0) {
        this.setObjectPath(param, this.route.params[param], parsedParams);
      } else {
        parsedParams[param] = this.route.params[param];
      }
    }

    const stringParams = JSON.stringify(params);
    let replaced = stringParams;
    for (const key of Object.keys(parsedParams)) {
      let search = ':' + key;
      let value;
      if (typeof parsedParams[key] === 'object') {
        search = `\"${search}\"`;
        value = JSON.stringify(parsedParams[key]);
      } else {
        value = parsedParams[key];
      }
      replaced = replaced.replace(new RegExp(search, 'g'), value);
    }
    if (replaced !== '') {
      params = { ...JSON.parse(replaced) };
    }

    for (const param in params) {
      if (params[param]) {
        if (
          typeof params[param] === "string" &&
          params[param].startsWith(":")
        ) {
          delete params[param];
        }
        if (param.indexOf(".") >= 0) {
          this.setObjectPath(param, params[param], params);
          delete params[param];
        }
      }
    }

    return params;
  }

  callServiceMethod(callDef: ServiceCall) {
    if (
      typeof callDef.condition !== "undefined" &&
      !this.evaluator.exec(null, callDef.condition)
    ) {
      return EMPTY;
    }
    let page = 1;
    if (typeof this.route.queryParams.page !== 'undefined') {
      // Set paging to query if comes from url
      page = this.route.queryParams.page;
    }
    const params = this.prepServiceCallParams(callDef.params);
    return this.getService(callDef.service)[callDef.method](params, page);
  }
}
