<mat-menu #menu overlapTrigger="false" [xPosition]="data.xPosition" [class]="data.class">
  <ng-container *ngFor="let menuItem of menuItems[parentItem.id]">
    <ng-container *ngIf="menuItems[menuItem.id]">
      <!-- HAS SUB MENU -->
      <button
        mat-menu-item
        [routerLink]="getRouterLink(menuItem)"
        [matMenuTriggerFor]="innerPanel.menu"
      >
        {{ menuItem.title }}
      </button>
      <app-menu-panel
        #innerPanel
        [data]="{
          link: data.link,
          class: data.class,
          triggers: data.triggers,
          xPosition: data.xPosition,
          scrollToElement: data.scrollToElement
        }"
        [menuItems]="menuItems"
        [parentItem]="menuItem"
      ></app-menu-panel>
    </ng-container>
    <ng-container *ngIf="!menuItems[menuItem.id]">
      <!-- NO SUB MENU -->
      <ng-container *ngIf="!data.scrollToElement">
        <button mat-menu-item [routerLink]="getRouterLink(menuItem)">
          {{ menuItem.title }}
        </button>
      </ng-container>
      <ng-container *ngIf="data.scrollToElement">
        <button mat-menu-item (click)="scrollService.scrollToElement(menuItem.url)">
          {{ menuItem.title }}
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-menu>
