import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ISlotComponent } from '../../slot/slot-component';
import { LanguageService } from '../../../backbone/language.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { GetArrayPathService } from '../../../backbone/get-array-path.service';
import { CommunicationService, Message } from '../../../backbone/communication.service';
import { HotkeysService } from '../../../backbone/hotkeys.service';
import { LinkPipe } from '../../../backbone/pipes/link.pipe';
import { ScrollService } from '../../../backbone/scroll.service';
import { TransformService } from '../../../backbone/transform.service';
import { EvalService } from '../../../backbone/eval.service';
import { StateService } from '../../../backbone/state.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  providers: [LinkPipe]
})
export class ButtonComponent implements OnInit, OnDestroy, OnChanges, ISlotComponent {
  @Input() public data: any;
  @Input() disabled: any;
  @Input() public parentForm: UntypedFormGroup;
  public link;

  private destroyed: Subject<void> = new Subject();

  constructor(
    public language: LanguageService,
    // private activatedRoute: ActivatedRoute,
    private arrayPathService: GetArrayPathService,
    private router: Router,
    private comm: CommunicationService,
    private hotkeys: HotkeysService,
    private linkPipe: LinkPipe,
    public scrollService: ScrollService,
    public transformData: TransformService,
    public evaluator: EvalService,
    private state: StateService,
  ) { }

  ngOnInit() {
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }

    if (typeof this.data.buttonType === 'undefined') {
      this.data.buttonType = 'button';
    }
    // if (this.data.link) {
    //   this.link = this.data.link;
    //   this.activatedRoute.params.subscribe(urlParams => {
    //     let replaced = '';
    //     for (const key of Object.keys(urlParams)) {
    //       const search = ':' + key;
    //       if (Array.isArray(this.data.link)) {
    //         replaced = this.data.link[0].replace(new RegExp(search, 'g'), urlParams[key]);
    //         this.link[0] = replaced;
    //       } else {
    //         replaced = this.data.link.replace(new RegExp(search, 'g'), urlParams[key]);
    //         this.link = replaced;
    //       }
    //     }
    //   });
    // }

    // bind hotkey if configured
    if (this.data.hotkey) {
      this.hotkeys.addShortcut({ keys: this.data.hotkey })
        .pipe(takeUntil(this.destroyed))
        .subscribe((e: KeyboardEvent) => {
          if (!this.data.disabled) {
            if (this.data.link) {
              this.router.navigate([
                this.linkPipe.transform(this.data.dataObject, this.data.link)
              ]);
            } else {
              this.onEvent(e, 'click');
            }
          }
        });
    }

    this.loadBadge();
    this.loadConditionalIcons();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.disabled
      && changes.disabled.currentValue !== changes.disabled.previousValue
    ) {
      this.data.disabled = this.disabled;
    }
  }

  public loadConditionalIcons() {
    if (this.data.conditionalIcon) {
      let conditionData;
      if (this.data.conditionalIcon.dataScope && this.data.conditionalIcon.dataScope === 'state') {
        conditionData = this.state.get(this.data.conditionalIcon.stateKey);
      } else {
        if (this.data.conditionalIcon.dataPath) {
          conditionData = this.arrayPathService.get(this.data.dataObject, this.data.conditionalIcon.dataPath);
        } else {
          conditionData = this.data.dataObject;
        }
      }

      if (this.data.conditionalIcon.valuePath) {
        this.data.conditionalIcon.value = this.arrayPathService.get(this.data.dataObject, this.data.conditionalIcon.valuePath);
      }

      if (this.evaluator.exec(conditionData, this.data.conditionalIcon)) {
        this.data.icon = this.data.conditionalIcon.icon;
      }
    }
  }

  public loadBadge() {
    if (this.data.badge) {
      if (this.data.badge.valuePath) {
        this.data.badge.value = this.arrayPathService.get(this.data.dataObject, this.data.badge.valuePath);
      }
    }
  }

  // Deprecated - use onEvent function
  click($event) {
    this.onEvent($event, 'click');
  }

  onEvent($event, type) {
    if (typeof this.data[type] === 'function') {
      $event.preventDefault();

      if (this.data.stopPropagation) {
        $event.stopPropagation();
      }

      const paramsName = `${type}Params`;

      if (!this.data[paramsName]) {
        this.data[paramsName] = {};
        this.data[paramsName].event = type;
        this.data[paramsName].$event = $event;

        // Attach $event.target (the button that was clicked) to the action state
        if ($event.target.closest('svg')) {
          this.data[paramsName].target = $event.target.closest('button');
        } else {
          this.data[paramsName].target = $event.target;
        }

        if (this.data.dataObject) {
          this.data[paramsName].dataObject = this.data.dataObject;
        }
      }
      if (this.parentForm) {
        if (this.data.name) {
          this.data[paramsName].control = this.parentForm.get(this.data.name);
          if (this.data[paramsName].control.disabled) {
            return;
          }
        }
      }

      const result = this.data[type](this.data[paramsName]);
      if (result instanceof Observable) {
        result.pipe(take(1)).subscribe();
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
