<nav [class]="data.navClasses">
  <ul class="breadcrumb-menu-wrapper" [class]="data.breadCrumbUlClasses">
    <li *ngFor="let bc of breadCrumbs; let i=index"
        [class]="data.breadCrumbClasses">
      <a [routerLink]="bc.url ? [bc.url] : null">{{ bc.label }}</a>
      <app-icon
        *ngIf="i !== breadCrumbs.length - 1"
        [data]="{
          icon: data.separatorIcon
        }"
      ></app-icon>
    </li>
  </ul>
</nav>
