import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxDropzonePreviewComponent } from 'ngx-dropzone';

@Component({
  selector: 'app-dropzone-image-preview',
  templateUrl: './dropzone-image-preview.component.html',
  styleUrls: ['./dropzone-image-preview.component.scss'],
  providers: [
    {
      provide: NgxDropzonePreviewComponent,
      useExisting: DropzoneImagePreviewComponent
    }
  ]
})
export class DropzoneImagePreviewComponent extends NgxDropzonePreviewComponent implements OnInit {
  imageSrc: any;
  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  ngOnInit(): void {
    this.renderImage();
  }
  private renderImage() {
    this.readFile()
      .then((img: string) => setTimeout(() => { 
        return this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(img);
      }))
      .catch(err => console.error(err));
  }
}
