import { Component, OnDestroy, OnInit, Input, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ApiService } from '../../../backbone/api.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Event } from '../../../backbone/event.class';
import { EventBusService } from '../../../backbone/event-bus.service';
import { LanguageService } from '../../../backbone/language.service';
import { PermissionsService } from '../../../backbone/permissions.service';
import { QueryService } from '../../../backbone/query.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GetArrayPathPipe } from '../../../backbone/pipes/get-array-path.pipe';
import { TransformService } from '../../../backbone/transform.service';
import { ISlotComponent } from '../../slot/slot-component';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AlertDialogComponent } from '../../alert-dialog/alert-dialog.component';
import { GetArrayPathService } from '../../../backbone/get-array-path.service';
import { UntypedFormGroup } from '@angular/forms';
import {
  CdkDragDrop,
  CdkDragEnd,
  CdkDragMove,
  CdkDragStart,
  CDK_DRAG_CONFIG,
  DragDropConfig
} from '@angular/cdk/drag-drop';
import { FormComponent } from '../../form/form.component';
import { CommunicationService, Message } from '../../../backbone/communication.service';

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface TreeNode {
  [key: string]: any;
  children?: TreeNode[];
}

/** Flat node with expandable and level information */
interface FlatNode {
  internalId: number;
  internalParent: number;
  expandable: boolean;
  children?: Array<FlatNode>;
  title: string;
  level: number;
  id: number;
}

const dragConfig: DragDropConfig = {
  pointerDirectionChangeThreshold: 10
};
@Component({
  selector: 'app-tree-list',
  templateUrl: './tree-list.component.html',
  styleUrls: ['./tree-list.component.scss'],
  providers: [GetArrayPathPipe, { provide: CDK_DRAG_CONFIG, useValue: dragConfig }]
})
export class TreeListComponent implements OnInit, OnDestroy, ISlotComponent {
  @Input() public data: any;
  @Input() public parentForm: any;
  @ViewChild('dropList') private dropListRef: ElementRef;

  public selfRef: any;
  public willLoad = false;
  public buttons = {};
  public minListHeight = 0;

  private nextInternalId = 0;
  private subsc: Subscription[] = [];
  private formsRegister: UntypedFormGroup[] = [];
  private urlParams;
  private dragging = false;
  private draggingNode: FlatNode;
  private dragDirection;
  private dragLastHoveredNode: FlatNode;
  private dragPlaceholderOffsetStep = 40;
  public dragPlaceholderOffset = 0;
  public dragPlaceholderHeight = 60;

  private treeFlattener;
  public treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable
  );
  public dataSource;

  private transformer = (node: TreeNode, level: number) => {
    if (typeof node.internalId === 'undefined' || node.internalId === null) {
      if (node.id) {
        node.internalId = node.id;
        if (this.nextInternalId < node.id + 1) {
          this.nextInternalId = node.id + 1;
        }
      } else {
        node.internalId = this.nextInternalId++;
      }
    }
    if (typeof node.internalParent === 'undefined' || node.internalParent === null) {
      node.internalParent = node.parent_id;
    }
    if (typeof node.children === 'undefined') {
      node.children = [];
    }
    node.expandable = !!node.children && node.children.length > 0;
    node.level = level;
    return node;
  }

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private eventBus: EventBusService,
    private router: Router,
    private permissionSevice: PermissionsService,
    private comm: CommunicationService,
    public language: LanguageService,
    public query: QueryService,
    public transformData: TransformService,
    public route: ActivatedRoute,
    public getArrayPath: GetArrayPathService,
    public cdRef: ChangeDetectorRef
  ) {
    this.query = query;
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      node => node.level,
      node => node.expandable,
      node => node.children
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  ngOnInit() {
    this.selfRef = this;

    if (typeof this.data.channel !== 'undefined') {
      this.subsc.push(this.comm.getChannel(this.data.channel)
        .subscribe((message: Message) => {
          this.comm.processMessage(message, this);
        }));
    }

    this.query.updateQueryUrl(this.route.snapshot);
    if (typeof this.data.actions !== 'undefined') {
      this.data.actions.forEach((action, key) => {
        if (!this.permissionSevice.checkPermissions(action)) {
          this.data.actions[key].denied = true;
        } else {
          this.data.actions[key].denied = false;
        }
      });
    }

    if (this.data.actions) {
      for (const action of this.data.actions) {
        if (action.actionBar && this.permissionSevice.checkPermissions(action)) {
          // register action in action bar(s)
          if (typeof action.mutationIndex !== 'undefined'
            && typeof this.data.dataSource.params !== 'undefined'
            && typeof this.data.dataSource.params.mutations !== 'undefined'
          ) {
            action.actionBar.data.value
              = this.data.dataSource.params.mutations[action.mutationIndex];
          }
          action.event = action.id + '_TreeList_' + this.data.instanceId;
          this.eventBus.fire(new Event('addToActionBar', action));
          this.subsc.push(this.eventBus.on(action.event, (actionData) => {
            if (typeof this[actionData.id] !== 'undefined') {
              this[actionData.id](actionData);
            }
          }));
        }
      }
    }
    if (this.data.loadOnInit === false && Object.keys(this.query.queryUrl).length === 0) {
      return;
    }
    this.load();

    if (typeof this.data.submit !== 'undefined') {
      this.buttons = [{
        color: 'primary',
        type: 'mat-flat-button',
        label: this.language.getLabel('App_cancel'),
        class: ''
      },
      {
        color: 'primary',
        type: 'mat-flat-button',
        label: this.language.getLabel('App_submit'),
        class: 'ml-1',
        click: this.submit.bind(this)
      }];
    }

    // Refresh data if url param has been changed
    this.subsc.push(this.route.params.subscribe(params => {
      if (this.urlParams && this.urlParams !== params) {
        this.load();
      }
      this.urlParams = params;
    }));
  }

  load(queryParams = null, page = null) {
    this.willLoad = true;

    // prepare actions
    if (!page && typeof this.route.snapshot.queryParams.page !== 'undefined') {
      // Set paging to query if comes from url
      page = this.route.snapshot.queryParams.page;
    }
    let params: any = {};

    if (!queryParams) {
      queryParams = this.query.prepareParams();
    }
    if (typeof this.data.dataSource.params !== 'undefined') {
      params = { ...this.data.dataSource.params };

      // If params has dynamic params from route url - search and replace them
      this.route.params.pipe(take(1)).subscribe(urlParams => {
        this.urlParams = urlParams;
        const stringParams = JSON.stringify(params);
        let replaced = stringParams;
        for (const key of Object.keys(urlParams)) {
          const search = ':' + key;
          replaced = replaced.replace(new RegExp(search, 'g'), urlParams[key]);
        }
        if (replaced !== '') {
          params = { ...JSON.parse(replaced) };
        }
      });
      if (Object.keys(queryParams).length > 0) {
        if (
          typeof params.mutations !== 'undefined'
          && typeof queryParams.mutations !== 'undefined'
        ) {
          const cloned = [...params.mutations];
          params = { ...params, ...queryParams };
          params.mutations = this.query.mergeMutations(cloned, queryParams.mutations);
        } else {
          params = { ...params, ...queryParams };
        }
      }
    } else if (Object.keys(queryParams).length > 0) {
      params = queryParams;
    }

    const dataService = this.api.getService(this.data.dataSource.service);
    dataService[this.data.dataSource.method](params, page)
      .pipe(take(1))
      .subscribe((response) => {
        if (typeof this.data.dataSource.path !== 'undefined') {
          this.dataSource.data = this.getArrayPath.get(
            response.result.data,
            this.data.dataSource.path
          );
        } else {
          this.dataSource.data = response.result.data;
        }
        if (this.data.dataSource.ifEmptyAddItem && this.dataSource.data.length <= 0) {
          this.dataSource.data = [{}];
        }
        if (typeof this.data.selected !== 'undefined') {
          if (
            typeof this.data.selected.value === 'string'
            && this.data.selected.value.indexOf(':') === 0
          ) {
            this.route.params.pipe(take(1)).subscribe(urlParams => {
              const selectedValue = urlParams[this.data.selected.value.replace(':', '')];
              this.getTreeSelection(selectedValue);
            });
          } else {
            this.getTreeSelection(this.data.selected.value);
          }
        }
      });
  }

  private getTreeSelection(selectedValue, selection: Array<FlatNode> = [], i = 0) {
    const node = this.treeControl.dataNodes.filter(item => {
      return String(item[this.data.selected.property]) === String(selectedValue);
    });
    if (i > 0) {
      this.treeControl.expansionModel.select(node[0]);
    }
    if (node[0].internalParent !== null) {
      i++;
      this.getTreeSelection(node[0].internalParent, selection, i);
    }
  }

  isNodeSelected(node) {
    if (typeof this.data.selected !== 'undefined') {
      if (
        typeof this.data.selected.value === 'string'
        && this.data.selected.value.indexOf(':') === 0
      ) {
        const value = this.urlParams[this.data.selected.value.replace(':', '')];
        return String(node[this.data.selected.property]) === String(value);
      } else {
        return String(node[this.data.selected.property]) === String(this.data.selected.value);
      }
    }
    return false;
  }

  private makeEmptyNode(node, propsToKeep) {
    const emptyNode: { [key: string]: any } = {};
    for (const prop in node) {
      if (typeof node[prop] !== 'undefined') {
        switch (prop) {
          case 'expandable':
            emptyNode[prop] = false;
            break;
          default:
            if (propsToKeep.indexOf(prop) >= 0) {
              emptyNode[prop] = node[prop];
              break;
            }
            if (typeof node[prop] === 'object') {
              if (Array.isArray(node[prop])) {
                emptyNode[prop] = [];
                break;
              }
              if (node[prop] === null) {
                emptyNode[prop] = null;
                break;
              }
              emptyNode[prop] = {};
            }
            emptyNode[prop] = null;
            break;
        }
      }
    }
    emptyNode.internalId = this.nextInternalId++;
    return emptyNode;
  }
  private findNodeRecursive(tree, node, compare = ['id', 'id']) {
    let foundNode = tree.find(n => n[compare[0]] === node[compare[1]]);
    if (typeof foundNode === 'undefined') {
      const expandables = tree.filter(n => n.children.length > 0);
      for (const expandable of expandables) {
        foundNode = this.findNodeRecursive(
          expandable.children,
          node,
          compare
        );
        if (typeof foundNode !== 'undefined') {
          break;
        }
      }
    }
    return foundNode;
  }
  private rebuild(data) {
    this.minListHeight = this.dropListRef.nativeElement.offsetHeight;
    this.formsRegister = [];
    this.dataSource.data = [];
    this.dataSource.data = data;
    setTimeout(() => {
      this.minListHeight = 0;
    }, 0);
  }

  addNode(node, propsToKeep = [], addChild = false) {
    const newNode = this.makeEmptyNode(node, propsToKeep);
    const data = this.dataSource.data;
    if (addChild) {
      // add child node
      if (typeof node.internalId !== 'undefined' && node.internalId !== null) {
        newNode.internalParent = node.internalId;
      } else {
        newNode.internalParent = node.id;
      }
      newNode.parent_id = node.id;
      node.children.push(newNode);

      // make parent expandable and expand
      node.expandable = true;
      this.treeControl.expansionModel.select(node);
    } else {
      if (node.parent_id) {
        // find parent
        const parent = this.findNodeRecursive(data, node, ['id', 'parent_id']);
        // add sibling at next position in the parent
        parent.children.splice(parent.children.indexOf(node) + 1, 0, newNode);
      } else if (
        typeof node.internalParent !== 'undefined'
        && node.internalParent !== null
        && node.internalParent !== ''
      ) {
        const parent = this.findNodeRecursive(
          data,
          node,
          ['internalId', 'internalParent']
        );
        newNode.internalParent = parent.internalId;
        parent.children.splice(parent.children.indexOf(node) + 1, 0, newNode);
      } else {
        const position = data.indexOf(node);
        if (position >= 0) {
          // add root level sibling at next position
          data.splice(position + 1, 0, newNode);
        } else {
          // add root level sibling at the end
          data.push(newNode);
        }
      }
    }
    this.rebuild(data);
  }
  removeNode(node) {
    const data = this.dataSource.data;
    if (node.parent_id) {
      const parent = this.findNodeRecursive(data, node, ['id', 'parent_id']);
      parent.children.splice(parent.children.indexOf(node), 1);
      if (parent.children.length <= 0) {
        parent.expandable = false;
      }
    } else if (
      typeof node.internalParent !== 'undefined'
      && node.internalParent !== null
      && node.internalParent !== ''
    ) {
      const parent = this.findNodeRecursive(
        data,
        node,
        ['internalId', 'internalParent']
      );
      parent.children.splice(parent.children.indexOf(node), 1);
      if (parent.children.length <= 0) {
        parent.expandable = false;
      }
    } else {
      data.splice(data.indexOf(node), 1);
    }
    this.rebuild(data);
  }

  mutate(params: any) {
    if (typeof params.actionParams !== 'undefined'
      && Object.keys(params.actionParams).length > 0
    ) {
      const queryParams = this.query.prepareQueryParams(
        params.id,
        params.actionParams
      );
      if (queryParams[params.id] !== '') {
        this.router.navigate([], {
          queryParams
        }).then(() => {
          this.query.updateQueryUrl(this.route.snapshot);
          this.load();
        });
      }
    }
  }

  delete(params, id) {
    const alertDialog: MatDialogRef<any> = this.dialog.open(AlertDialogComponent,
      {
        data: {
          title: this.language.getLabel(params.confirmDialog.title),
          text: this.language.getLabel(params.confirmDialog.text),
          button: this.language.getLabel(params.label)
        }
      }
    );
    alertDialog.afterClosed().pipe(take(1)).subscribe((result: string) => {
      if (result === 'confirm') {
        this.api.getService(params.apiCall.service)[params.apiCall.method]({
          id
        })
          .pipe(take(1))
          .subscribe(() => {
            this.load();
          });
      }
    });
  }

  dragStarted(event: CdkDragStart<FlatNode>) {
    if (!this.data.draggable) { return; }
    this.dragging = true;
    this.draggingNode = event.source.data;
    this.dragPlaceholderHeight = event.source.element.nativeElement.offsetHeight;
    if (event.source.data.expandable) {
      this.treeControl.expansionModel.deselect(event.source.data);
    }
  }
  dragMoved(event: CdkDragMove<string[]>) {
    this.dragDirection = event.delta.y;
  }
  dragEnded(event: CdkDragEnd<string[]>) {
    if (!this.data.draggable) { return; }
    this.dragPlaceholderOffset = 0;
    this.dragging = false;
  }
  hover(node: FlatNode) {
    if (!this.data.draggable && node !== this.draggingNode) { return; }
    if (this.dragging) {
      if (node.level > 0) {
        this.dragPlaceholderOffset = (
          node.level * this.dragPlaceholderOffsetStep
        ) + this.dragPlaceholderOffsetStep;
      } else {
        this.dragPlaceholderOffset = 0;
      }
      switch (this.dragDirection) {
        case 1:
          if (node.expandable && this.treeControl.isExpanded(node)) {
            this.dragPlaceholderOffset = (
              (node.level + 1) * this.dragPlaceholderOffsetStep
            ) + this.dragPlaceholderOffsetStep;
          }
          break;
      }
      this.dragLastHoveredNode = node;
    }
  }
  hoverEnd() { }
  drop(event: CdkDragDrop<string[]>) {
    if (
      !this.data.draggable
      || !this.dragLastHoveredNode
    ) { return; }
    const data = this.dataSource.data;
    // remove node from old index
    if (event.item.data.internalParent) {
      // when node is a child
      const parent = this.findNodeRecursive(
        data,
        event.item.data,
        ['internalId', 'internalParent']
      );
      const removeAtIdx = parent.children
        .findIndex(n => n.internalId === event.item.data.internalId);
      parent.children.splice(removeAtIdx, 1);
    } else {
      // when root node
      const removeAtIdx = data.findIndex(n => n.internalId === event.item.data.internalId);
      data.splice(removeAtIdx, 1);
    }
    // insert node at new position
    switch (this.dragDirection) {
      case -1:
        // when dragging up
        if (this.dragLastHoveredNode.internalParent) {
          // insert after child node
          const parent = this.findNodeRecursive(
            data,
            this.dragLastHoveredNode,
            ['internalId', 'internalParent']
          );
          const insertAtIdx = parent.children
            .findIndex(n => n.internalId === this.dragLastHoveredNode.internalId);
          event.item.data.internalParent = parent.internalId;
          parent.children.splice(insertAtIdx, 0, event.item.data);
        } else {
          // insert after root node
          const insertAtIdx = data
            .findIndex(n => n.internalId === this.dragLastHoveredNode.internalId);
          event.item.data.internalParent = null;
          data.splice(insertAtIdx, 0, event.item.data);
        }
        break;
      case 1:
        // when dragging down
        if (
          this.dragLastHoveredNode.expandable
          && this.treeControl.isExpanded(this.dragLastHoveredNode)
        ) {
          // insert into expanded last hovered node
          event.item.data.internalParent = this.dragLastHoveredNode.internalId;
          this.dragLastHoveredNode.children.splice(0, 0, event.item.data);
        } else {
          if (this.dragLastHoveredNode.internalParent) {
            // insert after child node
            const parent = this.findNodeRecursive(
              data,
              this.dragLastHoveredNode,
              ['internalId', 'internalParent']
            );
            const insertAtIdx = parent.children
              .findIndex(n => n.internalId === this.dragLastHoveredNode.internalId) + 1;
            event.item.data.internalParent = parent.internalId;
            parent.children.splice(insertAtIdx, 0, event.item.data);
          } else {
            // insert after root node
            const insertAtIdx = data
              .findIndex(n => n.internalId === this.dragLastHoveredNode.internalId) + 1;
            event.item.data.internalParent = null;
            data.splice(insertAtIdx, 0, event.item.data);
          }
        }
        break;
    }

    this.dragLastHoveredNode = null;
    this.rebuild(data);
  }

  registerForm(form: UntypedFormGroup, component: FormComponent) {
    this.formsRegister.push(form);
  }
  submit() {
    // validate registered forms if any
    let invalid = false;
    for (const form of this.formsRegister) {
      if (!form.valid) {
        invalid = true;
        form.markAllAsTouched();
      }
    }
    if (invalid) {
      return;
    }

    if (this.data.submit) {
      // submit
      let params;
      if (typeof this.data.submit.params !== 'undefined') {
        // If params has dynamic params from route url - search and replace them
        this.route.params.pipe(take(1)).subscribe(urlParams => {
          this.urlParams = urlParams;
          const stringParams = JSON.stringify(this.data.submit.params);
          let replaced = stringParams;
          for (const key of Object.keys(urlParams)) {
            const search = ':' + key;
            replaced = replaced.replace(new RegExp(search, 'g'), urlParams[key]);
          }
          if (replaced !== '') {
            params = { ...JSON.parse(replaced) };
          }
        });
        params.items = this.dataSource.data;
      } else {
        params = { items: this.dataSource.data };
      }
      const dataService = this.api.getService(this.data.submit.service);
      dataService[this.data.submit.method](params)
        .pipe(take(1))
        .subscribe((response: any) => {
        });
    }
  }

  ngOnDestroy() {
    for (const subsc of this.subsc) {
      subsc.unsubscribe();
    }
  }
}
