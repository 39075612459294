import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { CommunicationService } from '../../../backbone/communication.service';
import { SlotItem } from '../../slot/slot-item';

interface Slots {
  main: SlotItem[];
  top?: SlotItem[];
  subtop?: SlotItem[];
  left?: SlotItem[];
  right?: SlotItem[];
  bottom?: SlotItem[];
}

@Component({
  selector: 'app-stretch-menu',
  templateUrl: './stretch-menu.component.html',
  styleUrls: ['./stretch-menu.component.scss']
})
export class StretchMenuComponent implements OnInit, OnDestroy {
  slots: Slots;

  constructor(
    private route: ActivatedRoute,
    private comm: CommunicationService
  ) { }

  ngOnInit(): void {
    this.route.data.pipe(take(1)).subscribe(data => {
      this.slots = data.slots;
    });
  }

  ngOnDestroy(): void {
    this.comm.resetChannels();
  }
}
