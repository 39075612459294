import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CloneAbstractControlService {

  public clone<T extends AbstractControl>(control: T): T {
    let newControl: T;
    if (control instanceof UntypedFormGroup) {
      const formGroup = new UntypedFormGroup({}, control.validator, control.asyncValidator);
      const controls = control.controls;
      Object.keys(controls).forEach(key => {
        formGroup.addControl(key, this.clone(controls[key]));
      });
      newControl = formGroup as any;
    } else if (control instanceof UntypedFormArray) {
      const formArray = new UntypedFormArray([], control.validator, control.asyncValidator);
      control.controls.forEach(formControl => formArray.push(this.clone(formControl)));
      newControl = formArray as any;
    } else if (control instanceof UntypedFormControl) {
      newControl = new UntypedFormControl(control.value, control.validator, control.asyncValidator) as any;
      if (typeof control['def'] !== 'undefined') {
        newControl['def'] = JSON.parse(JSON.stringify(control['def']));
      }
    } else {
      throw new Error('Error: unexpected control value');
    }
    if (control.disabled) { newControl.disable({ emitEvent: false }); }
    return newControl;
  }
}
