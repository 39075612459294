import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlotModule } from '../slot/slot.module';
import { RoutingService } from '../../backbone/routing.service';
import { OverlayComponent } from './overlay.component';
import { PipesModule } from '../../backbone/pipes/pipes.module';

@NgModule({
  declarations: [OverlayComponent],
  imports: [
    CommonModule,
    SlotModule,
    PipesModule
  ]
})
export class OverlayModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('OverlayComponent', OverlayComponent);
  }
}
