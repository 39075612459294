import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LoaderComponent } from '../components/loader/loader.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loader: MatDialogRef<any>;
  constructor(private dialog: MatDialog) { }
  show() {
    this.loader = this.dialog.open(LoaderComponent, {
      hasBackdrop: true,
      disableClose: true
    });
  }
  hide() {
    this.loader.close();
  }
}
