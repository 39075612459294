import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorizontalMenuComponent } from './horizontal-menu.component';
import { MenuModule } from '../../menus/menu/menu.module';
import { RoutingService } from '../../../backbone/routing.service';



@NgModule({
  declarations: [HorizontalMenuComponent],
  imports: [
    CommonModule,
    MenuModule
  ]
})
export class HorizontalMenuModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('HorizontalMenuComponent', HorizontalMenuComponent);
  }
}
