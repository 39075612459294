import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { LanguageService } from '../../backbone/language.service';
import { ISlotComponent } from '../slot/slot-component';

@Component({
  selector: 'app-social-sharing',
  templateUrl: './social-sharing.component.html',
  styleUrls: ['./social-sharing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SocialSharingComponent implements OnInit, ISlotComponent {
  @Input() public data: any = {};

  constructor(
    public language: LanguageService
  ) { }

  ngOnInit(): void {}

}
