import { CdkDragDrop, CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiService } from '../../backbone/api.service';
import { LanguageService } from '../../backbone/language.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { ISlotComponent } from '../slot/slot-component';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, ISlotComponent {
  @Input() public data: any;
  public items = [];
  public imageDisplaySize = '';
  public dropzoneConfig = {
    label: 'App_upload_image',
    removable: false,
    multiple: false,
    expandable: false,
    change: null
  };

  constructor(
    public language: LanguageService,
    private api: ApiService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    this.dropzoneConfig.change = this.uploadImage.bind(this);
  }

  private callDataService(dataServiceConfig, successCallback, params?) {
    if (typeof this.data.uploadClass === 'undefined') {
      this.data.uploadClass = 'col-lg-24 col-xl-4 pt-3';
    }
    if (typeof this.data.contentClass === 'undefined') {
      this.data.contentClass = 'col-lg-24 col-xl-20';
    }
    if (typeof this.data.contentItemClass === 'undefined') {
      this.data.contentItemClass = 'col-xs-24 col-sm-6 col-md-8 col-lg-4 pt-3';
    }
    this.route.paramMap
      .pipe(take(1))
      .subscribe(routeParams => {
        // parse data source params and replace any url params that are mapped
        let dataServiceParams = {};
        if (typeof dataServiceConfig.params !== 'undefined') {
          let paramStr = JSON.stringify(dataServiceConfig.params);
          const pattern = /\"(:[\w-]+?)\"/g;
          let placeholder;
          // tslint:disable-next-line: no-conditional-assignment
          while ((placeholder = pattern.exec(paramStr)) !== null) {
            const param = placeholder[1].replace(':', '');
            if (param) {
              const search = placeholder[1];
              paramStr = paramStr.replace(search, routeParams.get(param));
            }
          }
          if (typeof params !== 'undefined') {
            dataServiceParams = Object.assign(params, JSON.parse(paramStr));
          } else {
            dataServiceParams = JSON.parse(paramStr);
          }
        } else if (typeof params !== 'undefined') {
          dataServiceParams = params;
        }

        const dataService = this.api.getService(dataServiceConfig.service);

        dataService[dataServiceConfig.method](dataServiceParams)
          .subscribe(successCallback);
      });
  }
  ngOnInit(): void {
    if (typeof this.data.imageDisplaySize !== 'undefined') {
      this.imageDisplaySize = this.data.imageDisplaySize + '_';
    }
    if (typeof this.data.dropzone !== 'undefined') {
      this.dropzoneConfig = Object.assign(this.dropzoneConfig, this.data.dropzone);
    }
    this.callDataService(
      this.data.dataSource,
      (response: any) => {
        this.items = response.result.data;
      }
    );
  }
  uploadImage(params) {
    this.callDataService(this.data.upload, (response: any) => {
      if (typeof response.result.data !== 'undefined') {
        this.items.unshift(...response.result.data);
      } else if (response.result.status === 'success') {
        this.items.unshift(response.result.url);
      }
    }, {
      file: params.value
    });

    return of([]);
  }
  isObject(item) {
    return (typeof item === 'object');
  }
  deleteImage(item) {
    const alertDialog: MatDialogRef<any> = this.dialog.open(AlertDialogComponent,
      {
        data: {
          title: this.language.getLabel('App_delete_confirm_title'),
          text: this.language.getLabel('App_delete_confirm_text'),
          button: this.language.getLabel('App_delete')
        }
      }
    );
    alertDialog.afterClosed().pipe(take(1)).subscribe({
      next: (result: string) => {
        if (result === 'confirm') {
          const params: {
            url?: string;
            id?: number[];
          } = {};
          if (typeof item === 'string') {
            params.url = encodeURI(item);
          }
          if (typeof item === 'object') {
            params.id = [item.id];
          }
          this.callDataService(this.data.delete, (rs: any) => {
            if (rs.result.status === 'success') {
              this.items = this.items.filter((e) => {
                return e !== item;
              });
            }
          }, params);
        }
      }
    });
  }

  entered(event: CdkDragEnter<number>) {
    if (this.data.draggable) {
      const dropList = event.container;
      const dragIndex = event.item.data;
      const dropIndex = dropList.data;

      const container = dropList.element.nativeElement;
      const element = container.querySelector('.cdk-drag-placeholder');
      container.removeChild(element);
      container.parentElement.insertBefore(element, container);

      moveItemInArray(this.items, dragIndex, dropIndex);
    }
  }

  dropped() {
    if (this.data.reorder) {
      const images = [];
      for (const image of this.items) {
        images.push({
          id: image.id
        });
      }
      this.callDataService(
        this.data.reorder,
        (rs: any) => { },
        { images }
      );
    }
  }
}
