import { Injectable } from '@angular/core';
import { GetQueryParamsAsObjectPipe } from './pipes/query-params.pipe';
import { ObjectToQueryParamsPipe } from './pipes/object-to-query.pipe';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class QueryService {
    public queryUrl: any;

    // Map for the operators to use in url params
    private operatorsMap: object = {
        gt: '>',
        gteq: '>=',
        lteq: '<=',
        lt: '<',
        eq: '=',
        like: 'like',
        btw: 'between'
    };

    constructor(
        private queryParamsAsObject: GetQueryParamsAsObjectPipe,
        public objectToQueryParams: ObjectToQueryParamsPipe,
        private activatedRoute: ActivatedRoute,
    ) {
        this.updateQueryUrl(activatedRoute);
    }

    // Record an object from current page query params
    updateQueryUrl(activatedRoute) {
        if (typeof activatedRoute.snapshot !== 'undefined') {
            this.queryUrl = this.queryParamsAsObject.transform(
                activatedRoute.snapshot.queryParams
            );
        } else {
            this.queryUrl = this.queryParamsAsObject.transform(
                activatedRoute.queryParams
            );
        }
    }

    // Transforms query params into a object for further manipulations
    getQueryParamsAsObject() {
        let queryObject = {};
        const snapshot = this.activatedRoute.snapshot.queryParams;
        queryObject = this.queryParamsAsObject.transform(snapshot);
        return queryObject;
    }

    // Return query parameters to be used in url
    prepareQueryParams(type: string, params: any = null): object {
        const queryObject = this.getQueryParamsAsObject();

        if (type !== 'sort') {
            // Replace whole mutation group in url if different than sort
            delete queryObject[type];
        }

        if (typeof queryObject[type] === 'undefined') {
            // Create ff current query type is missing
            queryObject[type] = [];
        }

        for (const k in params) {
            if (k === 'mutations') {
                // tslint:disable-next-line: forin
                for (const i in params[k]) {
                    let operator = '';
                    let values = null;
                    if (
                        Array.isArray(params[k][i].params[2])
                        || typeof params[k][i].params[2] === 'string'
                    ) {
                        operator = params[k][i].params[1];
                        values = params[k][i].params[2];
                    } else if (
                        typeof params[k][i].params[1] === 'object'
                        && !Array.isArray(params[k][i].params[1])
                    ) {
                        if (params[k][i].params[1].params.length > 2) {
                            values = {
                                column: params[k][i].params[1].params[0],
                                constraint: params[k][i].params[1].constraint,
                                operator: params[k][i].params[1].params[1],
                                values: '(' + params[k][i].params[1].params[2] + ')'
                            };
                        } else {
                            values = {
                                column: params[k][i].params[1].params[0],
                                constraint: params[k][i].params[1].constraint,
                                values: '(' + params[k][i].params[1].params[1] + ')'
                            };
                        }
                    } else if (typeof params[k][i].params[2] === 'undefined') {
                        values = params[k][i].params[1];
                    }

                    if (typeof values === 'string') {
                        if (values === '' || values === '%%') {
                            delete queryObject[type][params[k][i].params[0]];
                            continue;
                        }
                        values = [values];
                    }

                    if (typeof values === 'undefined') {
                        // if mutation has was passed without value
                        // it should be deleted
                        delete queryObject[type][params[k][i].params[0]];
                    } else {
                        queryObject[type][params[k][i].params[0]] = {
                            constraint: params[k][i].constraint,
                            column: params[k][i].params[0],
                            operator,
                            values
                        };
                    }
                }
            } else {
                queryObject[type][k] = params[k];
            }
        }

        if (Object.keys(queryObject[type]).length === 0) {
            // if group is empty remove it
            delete queryObject[type];
        }

        // Transforms and returns manipulated object into two dimensional query object
        return this.objectToQueryParams.transform(queryObject);
    }

    getCurrentSortDir(column, sortingDefinition) {
        if (typeof this.queryUrl.sort !== 'undefined'
            && typeof this.queryUrl.sort[column] !== 'undefined'
        ) {
            return this.queryUrl.sort[column].values[0];
        }
    }
    // Returns the sort durection
    getSortDirection(column, sortingDefinition) {
        if (typeof this.queryUrl.sort !== 'undefined'
            && typeof this.queryUrl.sort[column] !== 'undefined'
        ) {
            if (this.queryUrl.sort[column].values[0] === 'desc') {
                return 'asc';
            } else {
                return 'desc';
            }
        } else {
            if (sortingDefinition.starting) {
                if (sortingDefinition.direction === 'desc') {
                    return 'asc';
                } else {
                    return 'desc';
                }
            } else {
                return sortingDefinition.direction;
            }
        }
    }

    // Returns generated params from queries in url for post params
    prepareParams() {
        const preparedParams = {
            mutations: []
        };
        for (const group in this.queryUrl) {
            if (group) {
                const elements = this.queryUrl[group];
                for (const param in elements) {
                    if (typeof elements[param] === 'object') {
                        if (typeof elements[param].values !== 'undefined') {
                            let params = [];
                            let values;
                            if (elements[param].values.length === 1) {
                                values = elements[param].values[0];
                            } else {
                                values = elements[param].values;
                            }
                            if (elements[param].operator !== '') {
                                const operator = this.operatorsMap[elements[param].operator];
                                params = [param, operator, values];
                            } else {
                                params = [param, values];
                            }

                            // const splitedName = param.split(';');
                            // if (splitedName.length > 1) {
                            // preparedParams.mutations.push({
                            //     constraint: 'whereHas',
                            //     params: [splitedName[0], {
                            //         constraint: elements[param].constraint,
                            //         params: [splitedName[1], values]
                            //     }]
                            // });
                            // } else {
                            preparedParams.mutations.push({
                                constraint: elements[param].constraint,
                                params
                            });
                            // }
                        }
                        if (typeof elements[param].subGroup !== 'undefined') {
                            const mutationParams = [
                                elements[param].column
                            ];
                            // tslint:disable-next-line: forin
                            for (const i in elements[param].subGroup) {
                                const subParams = [
                                    elements[param].subGroup[i].column
                                ];
                                if (typeof elements[param].subGroup[i].operator !== 'undefined'
                                    && elements[param].subGroup[i].operator !== '') {
                                    subParams.push(elements[param].subGroup[i].operator);
                                }
                                subParams.push(elements[param].subGroup[i].values);
                                const mutationSubParams = {
                                    constraint: elements[param].subGroup[i].constraint,
                                    params: subParams
                                };
                                mutationParams.push(mutationSubParams);
                            }
                            preparedParams.mutations.push({
                                constraint: elements[param].constraint,
                                params: mutationParams
                            });
                        }
                    } else {
                        preparedParams[param] = elements[param];
                    }
                }
            }
        }
        if (preparedParams.mutations.length === 0) {
            delete preparedParams.mutations;
        }
        return preparedParams;
    }

    /* Merge default mutations from the json config with query mutations
    if they are for different column and constraint
    */
    mergeMutations(defaultMutations, queryMutations) {
        let orderByInQuery = false;
        const mergedMutationsObject = {};
        const clonedMutations = [...queryMutations];

        for (const queryMutation of clonedMutations) {
            if (queryMutation.constraint.startsWith('orderBy')) {
                orderByInQuery = true;
                break;
            }
        }
        for (const mutation of defaultMutations) {
            if (!mutation.constraint.startsWith('orderBy') || !orderByInQuery) {
                mergedMutationsObject[mutation.params[0] + '-' + mutation.constraint]
                    = mutation;
            }
        }
        for (const queryMutation of clonedMutations) {
            if (queryMutation.constraint.startsWith('orderBy')) {
                orderByInQuery = true;
            }
            mergedMutationsObject[queryMutation.params[0] + '-' + queryMutation.constraint]
                = queryMutation;
        }

        return Object.values(mergedMutationsObject);
    }
}
