import { Component, OnInit, Input, SimpleChanges, OnDestroy } from '@angular/core';
import { ISlotComponent } from '../../slot/slot-component';
import { UntypedFormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { LanguageService } from '../../../backbone/language.service';
import { GetArrayPathPipe } from '../../../backbone/pipes/get-array-path.pipe';
import { DatePipe } from '@angular/common';
import { Subscription, Observable } from 'rxjs';
import { EventBusService } from '../../../backbone/event-bus.service';

@Component({
  selector: 'app-daterange',
  templateUrl: './daterange.component.html',
  styleUrls: ['./daterange.component.scss'],
  providers: [GetArrayPathPipe]
})
export class DaterangeComponent implements OnInit, OnDestroy, ISlotComponent {
  from: string;
  to: string;
  private subsc: Subscription;

  @Input() public data: any = {};
  @Input() public parentForm: UntypedFormGroup;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    public language: LanguageService,
    private getArrayPath: GetArrayPathPipe,
    private eventBus: EventBusService
  ) {
    this.dateAdapter.setLocale(language.activeLocale);
  }

  ngOnInit() {
    this.subsc = this.eventBus.on('actionBarControlReset', (data) => {
      if (
        typeof data.actionParams === 'undefined'
        || typeof data.actionParams.controls === 'undefined'
        || (
          typeof data.actionParams !== 'undefined'
          && typeof data.actionParams.controls !== 'undefined'
          && data.actionParams.controls.indexOf(this.data.actionId) >= 0
        )
      ) {
        this.from = this.data.from.originalValue;
        this.to = this.data.to.originalValue;
        this.prepValues();
        if (typeof this.data.change === 'function') {
          this.data.change({
            id: this.data.actionId,
            value: (this.from && this.to) ? this.from + ',' + this.to : undefined
          });
        }
      }
    });
    this.prepValues();
    if (typeof this.data.from.originalValue === 'undefined') {
      this.data.from.originalValue = this.from;
    }
    if (typeof this.data.to.originalValue === 'undefined') {
      this.data.to.originalValue = this.to;
    }
  }
  private prepValues() {
    if (typeof this.data.value !== 'undefined') {
      if (this.data.from.value instanceof Array) {
        this.data.from.value = this.getArrayPath.transform(
          this.data.value,
          this.data.from.value
        );
      }
      if (this.data.to.value instanceof Array) {
        this.data.to.value = this.getArrayPath.transform(
          this.data.value,
          this.data.to.value
        );
      }
    }

    let from;
    let when;

    if (typeof this.data.from.value === 'string') {
      if (this.data.from.value.startsWith('now')) {
        when = 'now';
      }
      if (this.data.from.value.startsWith('today')) {
        when = 'today';
      }
      if (when) {
        let interval = this.data.from.value.replace(when, '');
        from = new Date();
        interval = +interval;
        if (interval) {
          from.setHours(from.getHours() + interval);
        }
      } else {
        from = new Date(this.data.from.value);
      }

      let to;
      if (typeof this.data.to.value === 'number') {
        to = new Date(from.getTime());
        to.setHours(to.getHours() + this.data.to.value);
      } else {
        to = new Date(this.data.to.value);
      }

      this.from = new DatePipe('en-GB').transform(from, 'yyyy-MM-dd');
      this.to = new DatePipe('en-GB').transform(to, 'yyyy-MM-dd');
    }
  }
  change(event: any, key: string) {
    let shortDate: string;
    if (typeof event.target !== 'undefined') {
      shortDate = this.setDate(event.target.value);
    } else {
      shortDate = this.setDate(event);
    }

    if (typeof this.data.change === 'function') {
      if (key === 'from') {
        this.from = shortDate;
      }
      if (key === 'to') {
        this.to = shortDate;
      }

      if (typeof this.from !== 'undefined'
        && typeof this.to !== 'undefined'
        && this.from !== null
        && this.to !== null
        && this.from !== ''
        && this.to !== ''
      ) {
        if (!this.data.changeParams) {
          this.data.changeParams = {
            value: this.from + ',' + this.to
          };
        } else {
          this.data.changeParams.value = this.from + ',' + this.to;
        }
        const result = this.data.change(this.data.changeParams);
        if (result instanceof Observable) {
          result.subscribe();
        }
      }
    }
  }

  // Transforms the date object to string
  setDate(dateObject) {
    const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
    const date = ('0' + dateObject.getDate()).slice(-2);
    const year = dateObject.getFullYear();
    const shortDate = year + '-' + month + '-' + date;
    return shortDate;
  }

  // Changes the date range with the difference between start and end date
  changePeriod(type: string) {
    const from = new Date(this.from).getTime();
    const to = new Date(this.to).getTime();
    const diff = (to - from) || 24 * 60 * 60 * 1000;

    let fromDate: any;
    let toDate: any;
    switch (type) {
      case 'next':
        fromDate = new Date(from + diff);
        toDate = new Date(to + diff);
        break;
      case 'prev':
        fromDate = new Date(from - diff);
        toDate = new Date(to - diff);
        break;
    }
    this.change(fromDate, 'from');
    this.change(toDate, 'to');
  }
  click(event: any) { }

  ngOnDestroy() {
    this.subsc.unsubscribe();
  }
}
