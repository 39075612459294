import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NestedMenuComponent } from './nested-menu.component';
import { RoutingService } from '../../../backbone/routing.service';
import { MenuNodeComponent } from './menu-node/menu-node.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    NestedMenuComponent,
    MenuNodeComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class NestedMenuModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('NestedMenuComponent', NestedMenuComponent);
  }
}
