import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressAutocompleteComponent } from './address-autocomplete.component';
import { RoutingService } from '../../backbone/routing.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { PrimitiveModule } from '../primitive/primitive.module';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [AddressAutocompleteComponent],
  imports: [
    CommonModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    PrimitiveModule,
    ReactiveFormsModule
  ],
  exports: [AddressAutocompleteComponent]
})
export class AddressAutocompleteModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('AddressAutocompleteComponent', AddressAutocompleteComponent);
  }
}
