import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Observable, Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { ApiService } from '../../backbone/api.service';
import { CommunicationService, Message } from '../../backbone/communication.service';
import { EvalService } from '../../backbone/eval.service';
import { GetArrayPathService } from '../../backbone/get-array-path.service';
import { PermissionsService } from '../../backbone/permissions.service';
import { ISlotComponent } from '../slot/slot-component';
import { LanguageService } from '../../backbone/language.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, OnDestroy, ISlotComponent {
  @Input() public data: any = {};
  @ViewChild('stepper', { static: true }) stepper: MatStepper;

  public selfRef: any;
  public completed = [];
  public steps = [];

  private destroyed = new Subject<void>();

  constructor(
    public permissionService: PermissionsService,
    private comm: CommunicationService,
    private api: ApiService,
    private getArrayPath: GetArrayPathService,
    private evaluator: EvalService,
    public language: LanguageService,
  ) { }

  ngOnInit(): void {
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }
    this.selfRef = this;

    if (typeof this.data.dataSource !== 'undefined') {
      this.load();
    } else {
      let i = 0;
      const steps = [];
      for (const step of this.data.steps) {
        let render = true;
        if (typeof step.condition !== 'undefined' && step.condition.scope !== 'data') {
          render = this.evaluator.exec(null, step.condition);
        }
        if (render) {
          steps.push(step);
        } else {
          continue;
        }
        this.completed[i] = typeof step.completed === 'boolean' ? step.completed : false;
        i++;
      }
      this.steps = steps;
    }
  }

  private load() {
    this.api.callServiceMethod(this.data.dataSource)
      .pipe(
        take(1),
        finalize(() => {
          this.nextStep();
        })
      )
      .subscribe((response) => {
        this.data.dataObject = response.result.data;
        let i = 0;
        const steps = [];
        for (const step of this.data.steps) {
          let render = true;
          if (typeof step.condition !== 'undefined' && step.condition.scope === 'data') {
            render = this.evaluator.exec(this.data.dataObject, step.condition);
          }
          if (render) {
            steps.push(step);
          } else {
            continue;
          }
          if (typeof step.completed === 'object') {
            if (Array.isArray(step.completed) && typeof step.completed[0] !== 'object') {
              this.completed[i] = this.getArrayPath.get(
                this.data.dataObject,
                step.completed
              );
            } else {
              this.completed[i] = this.evaluator.exec(this.data.dataObject, step.completed);
            }
          }
          i++;
        }
        this.steps = steps;

        // execute loaded event handler if stepper is in an action
        if (typeof this.data.loaded === 'function') {
          if (!this.data.loadedParams) {
            this.data.loadedParams = {};
            this.data.loadedParams.event = 'loaded';
            if (this.data.dataObject) {
              this.data.loadedParams.dataObject = this.data.dataObject;
            }
          }
          const result = this.data.loaded(this.data.loadedParams);
          if (result instanceof Observable) {
            result.pipe(take(1)).subscribe();
          }
        }
      });
  }

  public evalStepsCompletion() {
    let i = 0;
    for (const step of this.data.steps) {
      if (typeof step.completed === 'object') {
        if (Array.isArray(step.completed) && typeof step.completed[0] !== 'object') {
          this.completed[i] = this.getArrayPath.get(
            this.data.dataObject,
            step.completed
          );
        } else {
          this.completed[i] = this.evaluator.exec(this.data.dataObject, step.completed);
        }
      }
      i++;
    }
    this.nextStep();
  }

  public nextStep() {
    setTimeout(() => {
      while (
        this.stepper.selected.completed
        && this.stepper.selected !== this.stepper.steps.last
      ) {
        this.stepper.next();
      }
    });
  }

  isLazy(step) {
    if (typeof step.lazy === 'undefined') {
      return true;
    } else {
      return step.lazy;
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
