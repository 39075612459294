import { Pipe, PipeTransform } from '@angular/core';
import { GetArrayPathService } from '../get-array-path.service';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'tooltipContent'
})
export class TooltipContentPipe implements PipeTransform {
  constructor(public getArrayPath: GetArrayPathService) { }

  transform(input: Array<any>, path: string | Array<any>, format: object = null): any {
    if (Array.isArray(path)) {
      let content;
      if (Array.isArray(path[0])) {
        content = [];
        for (const p of path) {
          const tmp = this.getArrayPath.get(input, p);
          if (Array.isArray(tmp)) {
            content = content.concat(tmp);
          } else if (tmp) {
            content.push(tmp);
          }
        }
      } else {
        content = this.getArrayPath.get(input, path);
      }
      if (Array.isArray(content)) {
        let result;
        if (format !== null) {
          result = content.map(i => {
            const str = [];
            for (const field in format) {
              if (typeof i[field] !== 'undefined') {
                switch (field) {
                  case 'date':
                    if (format[field] !== null) {
                      str.push(new DatePipe('en-GB').transform(i[field].replace(' ', 'T'), format[field]));
                    }
                    break;
                  default:
                    str.push(i[field]);
                    break;
                }
              }
            }
            return str.join(' ');
          });
        } else {
          result = content.map(i => {
            if (typeof i === 'string') {
              return i;
            } else {
              return Object.values(i).join(' ')
            }
          });
        }
        return result.join('\n');
      } else {
        return content;
      }
    } else {
      return path;
    }
  }
}
