import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlotDirective } from './slot.directive';
import { SlotComponent } from './slot.component';



@NgModule({
  declarations: [
    SlotDirective,
    SlotComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SlotComponent,
    SlotDirective
  ]
})
export class SlotModule { }
