import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

@Pipe({
  name: 'getQueryParamsAsObject',
  pure: true
})

export class GetQueryParamsAsObjectPipe implements PipeTransform {
  constructor() { }
  transform(params: object): object {
    const queryParamsObject = {};
    for (const group in params) {
      if (typeof params[group] !== 'undefined') {
        const paramsObject = {};
        const groupParams = params[group].split(';');

        groupParams.forEach(element => {
          const subGroupObjects = [];
          const values = element.match(/\((.*)\)/);
          if (values && values[1]) {
            const splitSubGroup = values[1].split(':');
            if (splitSubGroup.length > 1) {
              this.parseGroup(values[1], subGroupObjects);
              element = element.replace(values[0], '');
              this.parseGroup(element, paramsObject, subGroupObjects);
            } else {
              this.parseGroup(element, paramsObject);
            }
          }
        });

        queryParamsObject[group] = paramsObject;
      }
    }
    return queryParamsObject;
  }

  private parseGroup(element, paramsObject, subGroup = null) {
    const splitGroup = element.split(':');
    if (splitGroup.length === 2) {
      paramsObject[splitGroup[0]] = splitGroup[1].replace(/[()]/g, '');
    } else if (splitGroup.length >= 3) {
      let values = '';
      let operator = '';
      let valuesSplit = [];

      // detect if there is an operator present
      if (splitGroup[2].startsWith('(')) {
        values = splitGroup[2];
      } else {
        values = splitGroup[3];
        operator = splitGroup[2];
      }

      // extract values
      if (!subGroup) {
        const result = values.match(/\((.*?)\)/);
        if (typeof result[1] !== 'undefined') {
          valuesSplit = result[1].split(',');
        }
        paramsObject[splitGroup[0]] = {
          constraint: splitGroup[1],
          column: splitGroup[0],
          operator,
          values: valuesSplit
        };
      } else {
          paramsObject[splitGroup[0]] = {
            constraint: splitGroup[1],
            column: splitGroup[0],
            subGroup
          };
      }

    }
    return paramsObject;
  }
}
