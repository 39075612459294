import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { SessionService } from './session.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class GetArrayPathService {
  private route: ActivatedRouteSnapshot;

  constructor(
    private state: StateService,
    private session: SessionService,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.route = event.snapshot;
      }
    });
  }

  get(input: Array<any> | {[key:string]: any}, path: string | Array<any> = ''): any {
    if (
      (typeof input === 'undefined' || input === null)
      && Array.isArray(path)
    ) {
      const storage = this[path[0]];
      if (typeof storage === 'undefined') {
        return undefined;
      }
      const clonedPath = [...path];
      clonedPath.shift();
      if (['state', 'session'].indexOf(path[0]) >= 0) {
        const location = clonedPath.join('.');
        if (typeof storage.get !== 'undefined') {
          return storage.get(location);
        } else {
          return storage[location];
        }
      }
      if (storage instanceof ActivatedRouteSnapshot) {
        path = clonedPath;
        input = {
          params: storage.params,
          queryParams: storage.queryParams,
          fragment: storage.fragment
        }
      }
    }
    if (path) {
      if (typeof path === 'string') {
        if (typeof input === 'undefined' || input === null) {
          return undefined;
        }
        input = input[path];
      } else {
        for (const item of path) {
          if (input instanceof Array
            && typeof item === 'string'
          ) {
            if (input.length === 0) {
              return undefined;
            }
            const result = [];
            input.forEach((arrayItem) => {
              if (arrayItem) {
                result.push(arrayItem[item]);
              }
            });
            input = result;
          } else {
            if (typeof input === 'undefined') {
              return undefined;
            }
            if (input != null) {
              input = input[item];
            }
          }
        }
      }
    }
    return input;
  }
}
