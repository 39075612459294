<div class="row mx-0">
  <div *ngIf="this.data.upload" [class]="data.uploadClass">
    <app-dropzone [data]="dropzoneConfig"></app-dropzone>
  </div>
  <div [class]="data.contentClass">
    <div class="row pb-1" cdkDropListGroup>
      <div
        cdkDropList
        [cdkDropListData]="i"
        [class]="data.contentItemClass"
        *ngFor="let item of items; let i = index"
      >
        <div class="dotted" *ngIf="data.draggable"></div>
        <div
          cdkDrag
          [cdkDragData]="i"
          (cdkDragEntered)="entered($event)"
          (cdkDragDropped)="dropped()"
          [cdkDragDisabled]="!data.draggable"
        >
          <div *cdkDragPlaceholder></div>
          <mat-card [ngClass]="{ clickable: data.itemClick }">
            <ng-container *ngIf="!item.type || item.type === 'image'">
              <ng-container *ngIf="!isObject(item)">
                <img
                  mat-card-image
                  [src]="item"
                  (click)="data.itemClick ? data.itemClick(item) : ''"
                />
              </ng-container>
              <picture mat-card-image *ngIf="isObject(item)">
                <source srcset="{{item.url.webp}}" type="image/webp" />
                <source
                  *ngIf="
                    item.url[imageDisplaySize + 'original'].endsWith('.jpg') ||
                    item.url[imageDisplaySize + 'original'].endsWith('.jpeg')
                  "
                  srcset="{{item.url[imageDisplaySize + 'original']}}"
                  type="image/jpeg"
                />
                <source
                  *ngIf="item.url[imageDisplaySize + 'original'].endsWith('.png')"
                  srcset="{{item.url[imageDisplaySize + 'original']}}"
                  type="image/png"
                />
                <img
                  [src]="item.url[imageDisplaySize + 'original']"
                  (click)="data.itemClick ? data.itemClick(item) : ''"
                />
              </picture>
            </ng-container>
            <ng-container *ngIf="item.type === 'video'">
              <app-video [data]="{
                'src': {'filepath': item.url}, 
                'mimeType': 'video/mp4',
                'autoplay': 'false',
                'muted': 'false',
                'controls': 'true'
              }"></app-video>
            </ng-container>
            <div *ngIf="data.draggable" class="drag-handle" cdkDragHandle>
              <mat-icon>open_with</mat-icon>
            </div>
            <mat-card-content *ngIf="data.content" class="mt-auto">
              <app-slot
                [items]="data.content"
                [componentValue]="item"
              ></app-slot>
            </mat-card-content>
            <mat-card-actions *ngIf="this.data.delete" class="text-center">
              <button
                mat-flat-button
                color="primary"
                (click)="deleteImage(item)"
              >
                {{ language.getLabel("App_delete") }}
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
