<video
  [class]="data.class"
  [autoplay]="data.autoplay"
  [muted]="data.muted"
  [loop]="data.loop"
  [controls]="data.controls"
  (ended)="ended($event)"
  (timeupdate)="timeupdate($event)"
  (loadeddata)="loadedData($event)"
  [ngClass]="{ isOnBackground: this.data.isBackground }"
>
  <source [src]="src" [type]="data.mimeType" />
</video>
<div class="w-100 d-flex justify-content-center align-items-center">
  <div class="loader" *ngIf="!loaded">
    <app-loader [data]="{ diameter: 60 }"></app-loader>
  </div>
</div>
<div *ngIf="data.header" class="header" [class]="data.headerClass">
  <app-slot
    [items]="data.header"
    [componentValue]="data.dataObject | getArrayPath: data.headerPath"
  ></app-slot>
</div>
<div *ngIf="data.contentVisible && data.content" class="content" [class]="data.contentClass">
  <app-slot
    [items]="data.content"
    [componentValue]="data.dataObject | getArrayPath: data.contentPath"
  ></app-slot>
</div>
