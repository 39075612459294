import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { EventBusService } from '../../backbone/event-bus.service';
import { Subscription } from 'rxjs';
import { Event } from '../../backbone/event.class';
import { SlotItem } from '../slot/slot-item';
import { QueryService } from '../../backbone/query.service';

export interface ActionButton {
  event: string;
}
@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnInit, OnDestroy {
  @Input() public data: any;
  private values = {};
  private constraints = {};
  private subsc: Subscription[] = [];
  private controls = {};
  slots: { [key: string]: Array<SlotItem> } = {};

  constructor(
    private eventBus: EventBusService,
    private cdRef: ChangeDetectorRef,
    private query: QueryService,
    private elRef: ElementRef
  ) {
    this.subsc.push(this.eventBus.on('addToActionBar', (data) => {
      if (this.data.id === data.actionBar.id) {
        if (!this.slots[data.actionBar.slot]) {
          this.slots[data.actionBar.slot] = [];
        }
        this[data.actionBar.component](data);
        data.actionBar.data.actionId = data.id;
        const control = new SlotItem(
          data.actionBar.component,
          data.actionBar.data,
          data.actionBar.requiredPermissions
        );
        this.slots[data.actionBar.slot]
          .push(control);
        this.slots[data.actionBar.slot] = this.slots[data.actionBar.slot].slice();
        this.updateValue(data);
        this.cdRef.detectChanges();
        this.controls[control.data.actionId] = control;
      }
      this.subsc.push(this.eventBus.on('actionBarControlReset', (actionData) => {
        // TODO reset control
      }));
    }));
    this.subsc.push(this.eventBus.on('actionBarEnterKey', (control) => {
      if (typeof this.controls[control].data.click === 'function') {
        if (typeof this.controls[control].data.clickParams !== 'undefined') {
          this.controls[control].data.click(this.controls[control].data.clickParams);
        } else {
          this.controls[control].data.click();
        }
      }
    }));
  }

  ngOnInit() { }

  private CheckboxComponent(data) {
    this.prepAction('change', data);
  }
  private SlideToggleComponent(data) {
    this.prepAction('change', data);
  }
  private AutocompleteComponent(data) {
    this.prepAction('change', data);
  }
  private DatepickerComponent(data) {
    this.prepAction('change', data);
  }
  private DaterangeComponent(data) {
    this.prepAction('change', data);
  }
  private RadiobuttonComponent(data) {
    this.prepAction('change', data);
  }
  private SelectComponent(data) {
    this.prepAction('change', data);
    if (typeof data.actionBar.data.dataSource !== 'undefined') {
      if (typeof data.actionBar.data.dataSource.params === 'undefined') {
        data.actionBar.data.dataSource.params = {};
      }
    }
  }
  private ButtonComponent(data) {
    this.prepAction('click', data);
  }
  private InputComponent(data) {
    this.prepAction('click', data);
    this.prepAction('change', data);
  }

  private prepAction(action, data) {
    data.actionBar.data[action + 'Params'] = {
      event: data.event,
      id: data.id,
      label: data.label
    };

    if (typeof data.require !== 'undefined') {
      data.actionBar.data[action + 'Params'].require = data.require;
    }
    if (typeof data.actionParams !== 'undefined') {
      data.actionBar.data[action + 'Params'].actionParams = data.actionParams;
    }
    if (typeof data.apiCall !== 'undefined') {
      data.actionBar.data[action + 'Params'].apiCall = data.apiCall;
    }
    if (typeof data.confirmDialog !== 'undefined') {
      data.actionBar.data[action + 'Params'].confirmDialog = data.confirmDialog;
    }
    if (typeof data.actionBar.data.checked !== 'undefined') {
      data.actionBar.data[action + 'Params'].checked = data.actionBar.data.checked;
    }
    data.actionBar.data[action] = this.doAction.bind(this);
  }

  doAction(params) {
    if (typeof this.controls[params.id].data.toggle !== 'undefined') {
      // toggle a class on the specified in the configuration selectors
      const toggleConf = this.controls[params.id].data.toggle;
      const htmlEl = this.elRef.nativeElement as HTMLElement;
      for (const selector of toggleConf.selectors) {
        const elements = htmlEl.getElementsByClassName(selector);
        for (const el of Array.from(elements)) {
          el.classList.toggle(toggleConf.class);
        }
      }
    }
    if (typeof params.value !== 'undefined') {
      this.values[params.id] = params.value;

      if (typeof params.options !== 'undefined') {
        // If we want to change the constraint based on other field
        params.options.filter(option => {
          if (typeof option.constraint !== 'undefined'
            && option.value === params.value
          ) {
            this.constraints[params.id] = option.constraint;
          }
        });
      }
    } else {
      delete this.values[params.id];
      delete this.constraints[params.id];
    }
    let clonedParams;
    if (typeof params.actionParams !== 'undefined') {
      clonedParams = JSON.parse(JSON.stringify(params));
      // tslint:disable-next-line: forin
      for (const k in clonedParams.actionParams) {
        if (typeof this['prep_' + k] === 'function') {
          this['prep_' + k](clonedParams.actionParams[k]);
        } else {
          if (
            typeof clonedParams.actionParams[k] === 'string'
            && clonedParams.actionParams[k].search(/{.+}/g) >= 0
          ) {
            const valueKey = clonedParams.actionParams[k].replace(/[{}]/g, '');
            if (this.values[valueKey]) {
              clonedParams.actionParams[k] = this.values[valueKey];
            } else {
              delete clonedParams.actionParams[k];
            }
          }
        }
      }
    } else {
      clonedParams = params;
    }
    this.eventBus.fire(new Event(clonedParams.event, clonedParams));
    if (params.id === 'reset') {
      this.eventBus.fire(new Event('actionBarControlReset', clonedParams));
    }
  }

  private prep_mutations(mutations) {
    const pattern = /{(.*)}/i;
    for (const k in mutations) {
      if (mutations[k].constraint.startsWith('{')) {
        const constraintKey = mutations[k].constraint.replace('{', '').replace('}', '');
        if (typeof this.constraints[constraintKey] !== 'undefined') {
          mutations[k].constraint = this.constraints[constraintKey];
        }
      }
      if (mutations[k].params) {
        // tslint:disable-next-line: forin
        for (const i in mutations[k].params) {
          let mutationParamsStr;
          let complex;
          if (typeof mutations[k].params[i] !== 'string') {
            mutationParamsStr = JSON.stringify(mutations[k].params[i]);
            mutationParamsStr = mutationParamsStr.substr(1, (mutationParamsStr.length - 2));
            complex = true;
          } else {
            mutationParamsStr = mutations[k].params[i];
            complex = false;
          }

          const valueKey = pattern.exec(mutationParamsStr);

          if (valueKey && valueKey[1]) {
            const value = this.values[valueKey[1]];
            if (
              typeof value === 'undefined'
              || (Array.isArray(value) && value.length === 0)
            ) {
              delete mutations[k];
              continue;
            }
            if (complex) {
              mutationParamsStr = '{' + mutationParamsStr + '}';
              const res = mutationParamsStr.replace('{' + valueKey[1] + '}', value);
              mutations[k].params[i] = JSON.parse(res);
            } else {
              const res = mutationParamsStr.replace('{' + valueKey[1] + '}', value);
              mutations[k].params[i] = res;
            }
          }
        }
      }
    }
  }

  updateValue(data: any) {
    const queryObject = this.query.getQueryParamsAsObject();
    for (const key in queryObject) {
      if (typeof queryObject[key] !== 'undefined' && key !== 'sort') {
        for (const field in queryObject[key]) {
          if (data.actionBar.data.name === field) {
            let values = [];
            if (typeof queryObject[key][field].values !== 'undefined') {
              values = queryObject[key][field].values;
            } else if (typeof queryObject[key][field].subGroup !== 'undefined') {
              // tslint:disable-next-line: forin
              for (const i in queryObject[key][field].subGroup) {
                values = queryObject[key][field].subGroup[i].values;
              }
            } else {
              values.push(queryObject[key][field]);
            }
            if (values.length === 1) {
              let value = values[0].replace(/%/g, '');
              switch (data.actionBar.data.type) {
                // Switch by field type
                case 'select':
                  const casted = Number(value);
                  if (!isNaN(value)) {
                    value = casted;
                  }
                  data.actionBar.data.originalSelected = data.actionBar.data.selected;
                  data.actionBar.data.selected = value;
                  this.values[data.actionBar.data.actionId] = value;
                  break;
                default:
                  data.actionBar.data.originalValue = data.actionBar.data.value;
                  data.actionBar.data.value = value;
                  this.values[data.actionBar.data.actionId] = value;
                  break;
              }
            } else {
              // If multiple values
              switch (data.actionBar.data.type) {
                case 'select':
                  if (data.actionBar.data.multiple) {
                    data.actionBar.data.originalSelected = data.actionBar.data.selected;
                    data.actionBar.data.selected = [];
                    for (let value of values) {
                      const casted = Number(value);
                      if (!isNaN(value)) {
                        value = casted;
                      }
                      data.actionBar.data.selected.push(value);
                      if (Array.isArray(this.values[data.actionBar.data.actionId])) {
                        this.values[data.actionBar.data.actionId].push(value);
                      } else {
                        this.values[data.actionBar.data.actionId] = [value];
                      }
                    }
                  }
                  break;
                case 'daterange':
                  data.actionBar.data.from.originalValue = data.actionBar.data.from.value;
                  data.actionBar.data.to.originalValue = data.actionBar.data.to.value;
                  data.actionBar.data.from.value = values[0];
                  data.actionBar.data.to.value = values[1];
                  this.values[data.actionBar.data.actionId] = values.join(',');
                  break;
              }
            }
          }
        }
      }
    }
  }

  ngOnDestroy() {
    for (const subsc of this.subsc) {
      subsc.unsubscribe();
    }
  }
}
