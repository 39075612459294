import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SlotModule } from '../slot/slot.module';
import { PipesModule } from '../../backbone/pipes/pipes.module';
import { MatExpansionModule } from '@angular/material/expansion';

import { RoutingService } from '../../backbone/routing.service';
import { AccordionComponent } from './accordion.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [AccordionComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    SlotModule,
    PipesModule,
    MatIconModule
  ]
})
export class AccordionModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('AccordionComponent', AccordionComponent);
  }
}
