import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialSharingComponent } from './social-sharing.component';
import { RoutingService } from '../../backbone/routing.service';
import { SlotModule } from '../slot/slot.module';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

@NgModule({
  declarations: [SocialSharingComponent],
  imports: [
    CommonModule,
    SlotModule,
    ShareButtonModule,
    ShareButtonsModule,
    ShareIconsModule
  ],
  exports: [SocialSharingComponent]
})
export class SocialSharingModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('SocialSharingComponent', SocialSharingComponent);
  }
}
