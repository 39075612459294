<div
  #dropList
  [ngStyle]="{ 'min-height.px': minListHeight }"
  class="mat-elevation-z8"
>
  <mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
  >
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodePadding
      [ngClass]="{ selected: isNodeSelected(node) }"
      cdkDrag
      [cdkDragDisabled]="!data.draggable"
      [cdkDragData]="node"
      (cdkDragStarted)="dragStarted($event)"
      (cdkDragMoved)="dragMoved($event)"
      (cdkDragEnded)="dragEnded($event)"
      (mouseenter)="hover(node)"
      (mouseleave)="hoverEnd()"
      cdkDragLockAxis="y"
    >
      <div class="node-content">
        <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled></button>
        <ng-container *ngIf="!data.components">
          <div *ngIf="!data.link">{{ node.title }}</div>
          <a
            *ngIf="data.link && !data.queryParams"
            [routerLink]="node | link: data.link"
          >
            {{ node.title }}
          </a>
        </ng-container>
        <app-slot
          *ngIf="data.components"
          [items]="data.components"
          [componentValue]="node"
          [parentForm]="parentForm"
          [parentComponent]="selfRef"
          style="display: block; width: 100%; height: 100%"
        ></app-slot>
        <div class="treeActions" *ngIf="data.actions">
          <span *ngFor="let action of data.actions">
            <ng-container *ngIf="!action.denied">
              <a
                *ngIf="action.link && action.id != 'delete'"
                [routerLink]="[action.link, node.id]"
              >
                <button
                  mat-icon-button
                  [attr.alt]="language.getLabel(action.name)"
                  [attr.title]="language.getLabel(action.name)"
                >
                  <mat-icon [ngClass]="action.id + '-icon'">{{
                    action.id
                  }}</mat-icon>
                </button>
              </a>
              <button
                [attr.alt]="language.getLabel('App_delete')"
                [attr.title]="language.getLabel('App_delete')"
                *ngIf="action.id == 'delete'"
                mat-icon-button
                (click)="delete(action, node.id)"
              >
                <mat-icon class="delete-icon">{{
                  language.getLabel("delete")
                }}</mat-icon>
              </button>
              <app-slot
                *ngIf="action.component"
                [items]="[action]"
                [componentValue]="node | getArrayPath: action.path"
                [parentForm]="parentForm"
                style="display: block; width: 100%; height: 100%"
              ></app-slot>
            </ng-container>
          </span>
        </div>
        <!-- drag and drop placeholder template -->
        <div
          *cdkDragPlaceholder
          class="drag-placeholder"
          [ngStyle]="{
            'margin-left.px': dragPlaceholderOffset,
            'height.px': dragPlaceholderHeight
          }"
        ></div>
      </div>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
      [ngClass]="{ selected: isNodeSelected(node) }"
      cdkDrag
      [cdkDragDisabled]="!data.draggable"
      [cdkDragData]="node"
      (cdkDragStarted)="dragStarted($event)"
      (cdkDragMoved)="dragMoved($event)"
      (cdkDragEnded)="dragEnded($event)"
      (mouseenter)="hover(node)"
      (mouseleave)="hoverEnd()"
      cdkDragLockAxis="y"
    >
      <div class="node-content">
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
          </mat-icon>
        </button>
        <ng-container *ngIf="!data.components">
          <div *ngIf="!data.link">{{ node.title }}</div>
          <a
            *ngIf="data.link && !data.queryParams"
            [routerLink]="node | link: data.link"
          >
            {{ node.title }}
          </a>
        </ng-container>
        <app-slot
          *ngIf="data.components"
          [items]="data.components"
          [componentValue]="node"
          [parentForm]="parentForm"
          [parentComponent]="selfRef"
          style="display: block; width: 100%; height: 100%"
        ></app-slot>
        <div class="treeActions">
          <span *ngFor="let action of data.actions">
            <ng-container *ngIf="!action.denied && !data.noActionForExpandable">
              <a
                *ngIf="action.link && action.id != 'delete'"
                [routerLink]="[action.link, node.id]"
              >
                <button
                  mat-icon-button
                  [attr.alt]="language.getLabel(action.name)"
                  [attr.title]="language.getLabel(action.name)"
                >
                  <mat-icon [ngClass]="action.id + '-icon'">{{
                    action.id
                  }}</mat-icon>
                </button>
              </a>
              <button
                [attr.alt]="language.getLabel('App_delete')"
                [attr.title]="language.getLabel('App_delete')"
                *ngIf="action.id == 'delete'"
                mat-icon-button
                (click)="delete(action, node.id)"
              >
                <mat-icon class="delete-icon">{{
                  language.getLabel("delete")
                }}</mat-icon>
              </button>
              <app-slot
                *ngIf="action.component"
                [items]="[action]"
                [componentValue]="node | getArrayPath: action.path"
                [parentForm]="parentForm"
                style="display: block; width: 100%; height: 100%"
              ></app-slot>
            </ng-container>
          </span>
        </div>
        <div
          *cdkDragPlaceholder
          class="drag-placeholder"
          [ngStyle]="{
            'margin-left.px': dragPlaceholderOffset,
            'height.px': dragPlaceholderHeight
          }"
        ></div>
      </div>
    </mat-tree-node>
  </mat-tree>
  <div class="row" *ngIf="data.submit">
    <div class="col text-center">
      <app-button [data]="button" *ngFor="let button of buttons"></app-button>
    </div>
  </div>
</div>
