<div #containerRef [class]="data.class">
  <combo-chart-component
    [view]="[containerRef.offsetWidth, containerRef.offsetHeight]"
    [showGridLines]="data.showGridLines"
    [scheme]="data.comboBarScheme"
    [colorSchemeLine]="data.lineChartScheme"
    [results]="data.barChart"
    [animations]="data.animations"
    [lineChart]="data.lineChartSeries"
    [gradient]="data.gradient"
    [xAxis]="data.showXAxis"
    [yAxis]="data.showYAxis"
    [legend]="data.showLegend"
    [legendTitle]="data.legendTitle"
    [legendPosition]="data.legendPosition"
    [showXAxisLabel]="data.showXAxisLabel"
    [showYAxisLabel]="data.showYAxisLabel"
    [showRightYAxisLabel]="data.showRightYAxisLabel"
    [xAxisLabel]="data.xAxisLabel"
    [yAxisLabel]="langService.getLabel(data.yAxisLabel)"
    [yAxisLabelRight]="data.yAxisLabelRight"
    [yScaleMax]="100"
    [yRightScaleMax]="100"
    [tooltipDisabled]="false"
  >
  </combo-chart-component>
</div>



