import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboChartComponent } from './combo-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ComboSeriesVerticalComponent } from './combo-series-vertical.component';

@NgModule({
  declarations: [ComboChartComponent, ComboSeriesVerticalComponent],
  imports: [
    CommonModule,
    NgxChartsModule,
  ],
  exports: [
    ComboChartComponent,
    ComboSeriesVerticalComponent,
  ]
})
export class ComboChartModule {
  constructor() {
  }
}
