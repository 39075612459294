<mat-drawer-container [autosize]="autosize">
  <mat-drawer
    #start
    *ngIf="slots.start"
    [mode]="mode.start"
    (opened)="opened('start', $event)"
    (closed)="closed('start', $event)"
  >
    <app-slot [items]="slots.start"></app-slot>
  </mat-drawer>
  <mat-drawer
    #end
    *ngIf="slots.end"
    [mode]="mode.end"
    position="end"
    (opened)="opened('end', $event)"
    (closed)="closed('end', $event)"
  >
    <app-slot [items]="slots.end"></app-slot>
  </mat-drawer>
  <div class="page">
    <app-slot *ngIf="slots.header" [items]="slots.header"></app-slot>
    <app-slot *ngIf="slots.menu" [items]="slots.menu"></app-slot>
    <app-slot *ngIf="slots.main" [items]="slots.main" class="main"></app-slot>
    <app-slot *ngIf="slots.footer" [items]="slots.footer"></app-slot>
  </div>
</mat-drawer-container>
