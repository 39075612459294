import { Pipe, PipeTransform } from '@angular/core';
import { GetArrayPathService } from '../get-array-path.service';

@Pipe({
  name: 'link',
  pure: true
})
export class LinkPipe implements PipeTransform {
  constructor(public getArrayPath: GetArrayPathService) { }

  transform(input: Array<any>, linkDef: Array<string | string[]> = null): string {
    if (!linkDef) {
      return null;
    }
    // if definition is only url return it
    if (!Array.isArray(linkDef)) {
      return linkDef;
    }
    // if definition is not only url
    if (Array.isArray(linkDef[1])) {
      // if second key of definition is array use first as url
      linkDef = JSON.parse(JSON.stringify(linkDef));
      const link = [linkDef[0] as string];
      if (linkDef.length > 1) {
        // if definition has at least 2 keys remove first (url)
        linkDef.shift();

        // use second as array of parameter paths and append to url
        for (const paramKey in linkDef) {
          if (typeof linkDef[paramKey] !== 'undefined') {
            const placeholder = '{' + paramKey + '}';
            if (link[0].indexOf(placeholder) >= 0) {
              link[0] = link[0].replace(
                placeholder,
                this.getArrayPath.get(input, linkDef[paramKey])
              );
            } else {
              link.push(this.getArrayPath.get(input, linkDef[paramKey]));
            }
          }
        }
      }
      return link.join('/');
    } else {
      return this.getArrayPath.get(input, linkDef);
    }
  }
}
