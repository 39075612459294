import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RoutingService } from '../../backbone/routing.service';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SelectComponent } from './select/select.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SlideToggleComponent } from './slide-toggle/slide-toggle/slide-toggle.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { InputComponent } from './input/input.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { RadiobuttonComponent } from './radiobutton/radiobutton.component';
import { SliderComponent } from './slider/slider.component';
import { ColorboxComponent } from './colorbox/colorbox.component';
import { DaterangeComponent } from './daterange/daterange.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartBarHorizontalComponent } from './charts/chart-bar-horizontal/chart-bar-horizontal.component';
import { PipesModule } from '../../backbone/pipes/pipes.module';
import { ActionComponent } from './action/action.component';
import { SlotModule } from '../slot/slot.module';
import { ChartPieComponent } from './charts/pie/pie.component';
import { DropzoneComponent } from './dropzone/dropzone.component';
import { HtmlComponent } from './html/html.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ChartBarHorizontalStackedComponent } from './charts/chart-bar-horizontal-stacked/chart-bar-horizontal-stacked.component';
import { AlertComponent } from './alert/alert.component';
import { MultiAutocompleteComponent } from './multi-autocomplete/multi-autocomplete.component';
import { NgxPrintModule } from 'ngx-print';
import { DownloadComponent } from './download/download.component';
import { CkeditorComponent } from './ckeditor/ckeditor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { CustomDateAdapter } from '../../backbone/date-adapter';
import { ImageComponent } from './image/image.component';
import { LinkComponent } from './link/link.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DropzoneImagePreviewComponent } from './dropzone/dropzone-image-preview/dropzone-image-preview.component';
import { HorizontalLineComponent } from './horizontal-line/horizontal-line.component';
import { HeadingComponent } from './heading/heading.component';
import { CardComponent } from './card/card.component';
import { ImageBackgroundComponent } from './image-background/image-background.component';
import { IconComponent } from './icon/icon.component';
import { SliderRangeComponent } from './slider-range/slider-range.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ChartBarLinesComponent } from './charts/chart-bar-lines/chart-bar-lines.component';
import { ComboChartModule } from '../combo-chart/combo-chart.module';
import { ChartPolarComponent } from './charts/chart-polar/chart-polar.component';
import { VideoComponent } from './video/video.component';
import { LoaderModule } from '../loader/loader.module';

@NgModule({
  declarations: [
    ButtonComponent,
    SelectComponent,
    CheckboxComponent,
    SlideToggleComponent,
    AutocompleteComponent,
    InputComponent,
    DatepickerComponent,
    DaterangeComponent,
    RadiobuttonComponent,
    SliderComponent,
    SliderRangeComponent,
    ChartBarHorizontalComponent,
    ChartPieComponent,
    ColorboxComponent,
    ActionComponent,
    DropzoneComponent,
    HtmlComponent,
    ChartBarHorizontalStackedComponent,
    AlertComponent,
    MultiAutocompleteComponent,
    DownloadComponent,
    CkeditorComponent,
    ImageComponent,
    ImageBackgroundComponent,
    LinkComponent,
    TimepickerComponent,
    DropzoneImagePreviewComponent,
    HorizontalLineComponent,
    HeadingComponent,
    CardComponent,
    IconComponent,
    ChartBarLinesComponent,
    ChartPolarComponent,
    VideoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatBadgeModule,
    MatIconModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatRadioModule,
    MatInputModule,
    MatSliderModule,
    NgxChartsModule,
    PipesModule,
    SlotModule,
    NgxDropzoneModule,
    MatChipsModule,
    NgxPrintModule,
    MatDialogModule,
    MatCardModule,
    CKEditorModule,
    NgxMaterialTimepickerModule,
    NgxSliderModule,
    ComboChartModule,
    LoaderModule
  ],
  exports: [
    ButtonComponent,
    SelectComponent,
    CheckboxComponent,
    SlideToggleComponent,
    AutocompleteComponent,
    MultiAutocompleteComponent,
    InputComponent,
    DatepickerComponent,
    DaterangeComponent,
    RadiobuttonComponent,
    SliderComponent,
    SliderRangeComponent,
    ChartBarHorizontalComponent,
    ChartPieComponent,
    ColorboxComponent,
    ActionComponent,
    DropzoneComponent,
    HtmlComponent,
    ImageComponent,
    ImageBackgroundComponent,
    AlertComponent,
    DownloadComponent,
    CkeditorComponent,
    TimepickerComponent,
    HorizontalLineComponent,
    HeadingComponent,
    CardComponent,
    ChartBarLinesComponent,
    ChartPolarComponent,
    IconComponent,
    VideoComponent,
    LinkComponent
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter
    }
  ]
})
export class PrimitiveModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('ButtonComponent', ButtonComponent);
    routing.mapComponent('SelectComponent', SelectComponent);
    routing.mapComponent('CheckboxComponent', CheckboxComponent);
    routing.mapComponent('SlideToggleComponent', SlideToggleComponent);
    routing.mapComponent('AutocompleteComponent', AutocompleteComponent);
    routing.mapComponent('MultiAutocompleteComponent', MultiAutocompleteComponent);
    routing.mapComponent('InputComponent', InputComponent);
    routing.mapComponent('DatepickerComponent', DatepickerComponent);
    routing.mapComponent('DaterangeComponent', DaterangeComponent);
    routing.mapComponent('RadiobuttonComponent', RadiobuttonComponent);
    routing.mapComponent('SliderComponent', SliderComponent);
    routing.mapComponent('SliderRangeComponent', SliderRangeComponent);
    routing.mapComponent('ChartBarHorizontalComponent', ChartBarHorizontalComponent);
    routing.mapComponent('ChartBarHorizontalStackedComponent', ChartBarHorizontalStackedComponent);
    routing.mapComponent('ChartPieComponent', ChartPieComponent);
    routing.mapComponent('ColorboxComponent', ColorboxComponent);
    routing.mapComponent('ActionComponent', ActionComponent);
    routing.mapComponent('DropzoneComponent', DropzoneComponent);
    routing.mapComponent('HtmlComponent', HtmlComponent);
    routing.mapComponent('AlertComponent', AlertComponent);
    routing.mapComponent('DownloadComponent', DownloadComponent);
    routing.mapComponent('CkeditorComponent', CkeditorComponent);
    routing.mapComponent('LinkComponent', LinkComponent);
    routing.mapComponent('ImageComponent', ImageComponent);
    routing.mapComponent('ImageBackgroundComponent', ImageBackgroundComponent);
    routing.mapComponent('TimepickerComponent', TimepickerComponent);
    routing.mapComponent('HorizontalLineComponent', HorizontalLineComponent);
    routing.mapComponent('HeadingComponent', HeadingComponent);
    routing.mapComponent('CardComponent', CardComponent);
    routing.mapComponent('ChartBarLinesComponent', ChartBarLinesComponent);
    routing.mapComponent('ChartPolarComponent', ChartPolarComponent);
    routing.mapComponent('IconComponent', IconComponent);
    routing.mapComponent('VideoComponent', VideoComponent);
  }
}
