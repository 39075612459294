import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridListComponent } from './grid-list.component';
import { RoutingService } from '../../../backbone/routing.service';
import { SlotModule } from '../../slot/slot.module';
import { PipesModule } from '../../../backbone/pipes/pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PrimitiveModule } from '../../primitive/primitive.module';



@NgModule({
  declarations: [GridListComponent],
  imports: [
    CommonModule,
    SlotModule,
    PipesModule,
    MatTooltipModule,
    PrimitiveModule
  ]
})
export class GridListModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('GridListComponent', GridListComponent);
  }
}
