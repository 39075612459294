import { Component, OnInit, Inject, Input, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISlotComponent } from '../slot/slot-component';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, ISlotComponent {
  @Input() data: any = {};
  public diameter = 40;
  public color = 'accent';
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public dialogData) { }

  ngOnInit() {
    if (this.data?.diameter) {
      this.diameter = this.data.diameter;
    }
    if (this.data?.color) {
      this.color = this.data.color;
    }
  }

}
