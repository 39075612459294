<ng-container *ngIf="data.children.length > 0">
  <ul class="dropdownMenu ul-level-{{data.depth + 2}}">
    <li *ngFor="let item of data.children" class="li-level-{{ data.depth + 2 }}">
      <a [routerLink]="getRouterLink(item)">
        <p>{{ item.title }}</p>
      </a>
      <app-menu-node 
        [data]="{
          link: data.link, 
          title: item.title, 
          children: item.children, 
          url: item.url,
          depth: item.depth
        }"></app-menu-node>
    </li>
  </ul>
</ng-container>