import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoteSlotComponent } from './remote-slot.component';
import { RoutingService } from '../../backbone/routing.service';
import { SlotModule } from '../slot/slot.module';

@NgModule({
  declarations: [RemoteSlotComponent],
  imports: [
    CommonModule,
    SlotModule
  ],
  exports: [
    RemoteSlotComponent
  ]
})
export class RemoteSlotModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('RemoteSlotComponent', RemoteSlotComponent);
  }
}
