import { Injectable } from '@angular/core';
import { DatePipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { GetArrayPathService } from './get-array-path.service';
import { LanguageService } from './language.service';

@Injectable({
    providedIn: 'root'
})
export class TransformService {
    constructor(
        private currencyPipe: CurrencyPipe,
        private decimalPipe: DecimalPipe,
        private getArrayPath: GetArrayPathService,
        private language: LanguageService
    ) { }

    transform(data: any, params: any) {
        if (typeof data === 'undefined') {
            return;
        }
        let value;
        // extract value
        if (typeof params.transform !== 'undefined') {
            value = this.getArrayPath.get(data, params.path);
            // extract transformation params
            params = { ...params.transform };
        } else {
            // backword compatibility
            value = data;
        }
        let formatedData;
        switch (params.type) {
            case 'date':
                if (value === null || value === ''){
                    return value;
                }
                formatedData = new DatePipe('en-GB')
                    .transform(value.replace(' ', 'T'), params.format);
                break;
            case 'currency':
                if (typeof value === 'string') {
                    return;
                }
                if (typeof value === 'undefined' || value === null) {
                    value = 0;
                }
                if (
                    typeof data === 'object'
                    && data !== null
                    && typeof params.currency === 'undefined'
                    && typeof params.currencyPath !== 'undefined'
                ) {
                    // extract currency from currency path if provided
                    params.currency = this.getArrayPath.get(data, params.currencyPath);
                }
                if (typeof params.currency !== 'undefined' && params.currency !== null) {
                    formatedData = this.currencyPipe
                        .transform(
                            value,
                            params.currency,
                            params.display,
                            undefined,
                            this.language.getFullLocale()
                        );
                } else {
                    formatedData = value.toFixed(2);
                }
                break;
            case 'number':
                formatedData = this.decimalPipe.transform(value, params.digits);
                break;
            case 'timeFormat': {
                if (value === 0) {
                    formatedData = 'N/A';
                    break;
                }
                switch (params.timeValueType) {
                    case 'minutes': {
                        value *= 60;
                        break;
                    }
                    case 'miliseconds': {
                        value /= 1000;
                        break;
                    }
                }

                const d = Math.floor(value / (3600 * 24));
                const h = Math.floor(value % (3600 * 24) / 3600);
                const m = Math.floor(value % 3600 / 60);

                if (typeof params.format === 'undefined') {
                    formatedData = `${d}d ${h}h ${m}m`;
                } else {
                    formatedData = params.format.replace('${d}', d);
                    formatedData = formatedData.replace('${h}', h);
                    formatedData = formatedData.replace('${m}', m);
                }
            }
        }
        return formatedData;
    }
}
