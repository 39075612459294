import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel.component';
import { RoutingService } from '../../backbone/routing.service';
import { SwiperModule } from 'swiper/angular';
import { SlotModule } from '../slot/slot.module';
import { MatCardModule } from '@angular/material/card';
import { PipesModule } from '../../backbone/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PrimitiveModule } from '../primitive/primitive.module';

@NgModule({
  declarations: [CarouselComponent ],
  imports: [
    CommonModule,
    FormsModule,
    SwiperModule,
    SlotModule,
    MatCardModule,
    PipesModule,
    MatIconModule,
    MatProgressBarModule,
    PrimitiveModule
  ]
})
export class CarouselModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('CarouselComponent', CarouselComponent);
  }
}
