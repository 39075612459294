import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacebookLoginComponent } from './facebook-login.component';
import { RoutingService } from '../../backbone/routing.service';
import { SlotModule } from '../slot/slot.module';
import { MatButtonModule } from '@angular/material/button';
import { PrimitiveModule } from '../primitive/primitive.module';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import { ApiService } from '../../backbone/api.service';

function fbLoginProvider(api: ApiService): SocialAuthServiceConfig {
  return {
    autoLogin: false,
    providers: [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(api.getEnv().facebookLoginConfig.appId)
      }
    ],
    onError: (err) => {
      console.error(err);
    }
  };
};

@NgModule({
  declarations: [FacebookLoginComponent],
  imports: [
    CommonModule,
    SlotModule,
    MatButtonModule,
    PrimitiveModule,
    SocialLoginModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: fbLoginProvider,
      deps: [ApiService]
    }
  ],
  exports: [FacebookLoginComponent]
})
export class FacebookLoginModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('FacebookLoginComponent', FacebookLoginComponent);
  }
}
