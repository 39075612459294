import { Injectable, InjectionToken, Inject } from '@angular/core';

export const SESSION_STORAGE = new InjectionToken<Storage>('Session Storage', {
  providedIn: 'root',
  factory: () => sessionStorage
});

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  globalSession: { [k: string]: any } = {};

  constructor(
    @Inject(SESSION_STORAGE) private storage: Storage
  ) { }
  get(key: string): any {
    let path = key.split('.');
    let entry;
    try {
      entry = JSON.parse(this.storage.getItem(path[0]));
    } catch (e) {
      entry = this.storage.getItem(path[0]);
    }
    if (path.length > 1) {
      path.shift();
      for (const i of path) {
        let item:string|number = i;
        if (!isNaN(+i)) {
          item = parseInt(i);
        }
        if (entry instanceof Array
          && typeof item === 'string'
        ) {
          if (entry.length === 0) {
            return undefined;
          }
          const result = [];
          entry.forEach((arrayItem) => {
            if (arrayItem) {
              result.push(arrayItem[item]);
            }
          });
          entry = result;
        } else {
          if (typeof entry === 'undefined') {
            return undefined;
          }
          if (entry != null) {
            entry = entry[item];
          }
        }
      }
    }
    return entry;
  }
  set(key: string, value: string|object): void {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    this.storage.setItem(key, value);
  }
  remove(key: string) {
    this.storage.removeItem(key);
  }

  setGlobalVar(key, value) {
    this.globalSession[key] = value;
  }
  getGlobalVar(key) {
    return this.globalSession[key];
  }

  removeGlobalVar(key) {
    delete this.globalSession[key];
  }
}
