import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommunicationService, Message } from '../../../backbone/communication.service';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../backbone/api.service';
import { BaseComponent } from '../../base.component';
import { LanguageService } from '../../../backbone/language.service';
import { ScrollService } from '../../../backbone/scroll.service';
import { GetArrayPathService } from '../../../backbone/get-array-path.service';
import { LinkPipe } from '../../../backbone/pipes/link.pipe';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent extends BaseComponent implements OnInit, OnDestroy {
  public src;
  public loaded = false;

  constructor(
    private comm: CommunicationService,
    private route: ActivatedRoute,
    public language: LanguageService,
    protected api: ApiService,
    public scroll: ScrollService,
    private getArrayPath: GetArrayPathService,
    public link: LinkPipe
  ) {
    super(language, scroll, api, link);
  }

  ngOnInit(): void {
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }
    this.src = this.data.src;
    if (typeof this.data.src === 'object') {
      if (Array.isArray(this.data.src)) {
        this.src = this.getArrayPath.get(this.data.dataObject, this.data.src);
      } else {
        let params = {...this.data.src.params};
        if (typeof this.data.src.params !== 'undefined') {
          this.route.params.pipe(take(1)).subscribe(urlParams => {
            let stringParams = JSON.stringify(this.data.src.params);
            for (const key of Object.keys(urlParams)) {
              const search = ':' + key;
              stringParams = stringParams.replace(new RegExp(search, 'g'), urlParams[key]);
            }
            params = JSON.parse(stringParams);
          });
        }
        let src = this.data.src.filepath;
        // tslint:disable-next-line: forin
        for (const i in params) {
          src = src.replace('{' + i + '}', params[i]);
        }
        this.src = src;
      }
    }
    if (typeof this.data.dataSource !== 'undefined') {
      this.load();
    }
  }

  timeupdate(event) {
    if (typeof this.data.timeUpdate === 'function') {
      if (!this.data.timeUpdateParams) {
        this.data.timeUpdateParams = {
          timeStamp: event.timeStamp
        };
      } else {
        this.data.timeUpdateParams.timeStamp = event.timeStamp;
      }
      this.data.timeUpdateParams.event = 'timeUpdate';
      const result = this.data.timeUpdate(this.data.timeUpdateParams);
      if (result instanceof Observable) {
        result.pipe(take(1)).subscribe();
      }
    }
  }

  ended(event) {
    if (typeof this.data.ended === 'function') {
      if (!this.data.endedParams) {
        this.data.endedParams = {
          timeStamp: event.timeStamp
        };
      } else {
        this.data.endedParams.timeStamp = event.timeStamp;
      }
      this.data.endedParams.event = 'ended';
      const result = this.data.ended(this.data.endedParams);
      if (result instanceof Observable) {
        result.pipe(take(1)).subscribe();
      }
    }
  }

  loadedData(event) {
    this.loaded = true;
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
