<div #containerRef [class]="data.class">
  <ngx-charts-polar-chart
    [view]="[containerRef.offsetWidth, containerRef.offsetHeight]"
    [scheme]="data.colorScheme"
    [legend]="data.legend"
    [showXAxisLabel]="data.showXAxisLabel"
    [showYAxisLabel]="data.showYAxisLabel"
    [xAxis]="data.xAxis"
    [yAxis]="data.yAxis"
    [xAxisLabel]="data.xAxisLabel"
    [yAxisLabel]="data.yAxisLabel"
    [results]="data.results"
    [showGridLines]="data.showGridLines"
    [tooltipDisabled]="data.tooltipDisabled">
  </ngx-charts-polar-chart>
</div>
