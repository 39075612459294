import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiService } from '../../backbone/api.service';
import { LanguageService } from '../../backbone/language.service';
import { PermissionsService } from '../../backbone/permissions.service';
import { StateService } from '../../backbone/state.service';
import { ISlotComponent } from '../slot/slot-component';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, ISlotComponent {
  @Input() public data: any = {};
  public countdown: Observable<number>;

  constructor(
    public language: LanguageService,
    private router: Router,
    private api: ApiService,
    private state: StateService,
    private permissions: PermissionsService
  ) { }

  ngOnInit(): void {
    this.api.callServiceMethod(this.data.apiCall)
      .pipe(take(1))
      .subscribe(
        () => {
          this.permissions.checkSession();
          this.state.set('auth', 'false');
        },
        () => {
          this.permissions.checkSession();
          this.state.set('auth', 'false');
        }
      );
    if (typeof this.data.delay === 'undefined') {
      this.data.delay = 5;
    }
    this.countdown = interval(1000)
      .pipe(
        map(i => this.data.delay - i),
        take(this.data.delay + 1)
      );

    this.countdown.subscribe({
      complete: () => {
        this.router.navigateByUrl(decodeURI(this.data.redirectTo));
      }
    });
  }
}
