<a
  *ngIf="!external"
  [class]="data.class"
  [routerLink]="data.dataObject | link: data.url"
  [attr.target]="data.target"
>
  <ng-container *ngIf="!data.path">
    <ng-container *ngIf="data.text">
      {{ data.text }}
    </ng-container>
    <ng-container *ngIf="data.label">
      {{ language.getLabel(data.label) }}
    </ng-container>
  </ng-container>
  <ng-container *ngIf="data.path && !data.components">
    {{ data.dataObject | getArrayPath: data.path }}
  </ng-container>
  <app-slot
    *ngIf="data.components"
    [items]="data.components"
    [componentValue]="data.dataObject | getArrayPath: data.path"
    >
  </app-slot>
</a>
<a
  *ngIf="external"
  [class]="data.class"
  [href]="data.url"
  [attr.target]="data.target"
>
  <ng-container *ngIf="!data.path">
    <ng-container *ngIf="data.text">
      {{ data.text }}
    </ng-container>
    <ng-container *ngIf="data.label">
      {{ language.getLabel(data.label) }}
    </ng-container>
  </ng-container>
  <ng-container *ngIf="data.path && !data.components">
    {{ data.dataObject | getArrayPath: data.path }}
  </ng-container>
  <app-slot
    *ngIf="data.components"
    [items]="data.components"
    [componentValue]="data.dataObject | getArrayPath: data.path"
  ></app-slot>
</a>
