<div class="pieChart">
  <ngx-charts-pie-chart
    [view]="data.view"
    [scheme]="data.colorScheme"
    [results]="data.value"
    [legend]="data.showLegend"
    [explodeSlices]="data.explodeSlices"
    [labels]="data.showLabels"
    [doughnut]="data.doughnut"
    [gradient]="data.gradient"
    (select)="onSelect($event)"
  >
  </ngx-charts-pie-chart>
</div>
