import { Injectable } from '@angular/core';
import { fromEvent, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private scroll$;
  constructor() {
    this.scroll$ = fromEvent(window, 'scroll');
  }

  public subscribe(until$, callback) {
    this.scroll$.pipe(takeUntil(until$)).subscribe(callback);
  }

  public scrollToElement(item) {
    setTimeout(() => {
      document.getElementById(item.replace("#", "")).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }, 100);
  }
}
