import { Injectable } from '@angular/core';
import { StateService } from './state.service';
import { ApiService } from './api.service';
import { GetArrayPathService } from './get-array-path.service';
import { Event } from './event.class';
import { EventBusService } from './event-bus.service';
interface Label {
  key: string;
  data?: { [key: string]: any };
  params: { [key: string]: any };
};

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  labels = {};
  public activeLocale;
  private showLabelKeyIfNotFound = false;

  constructor(
    private api: ApiService,
    private state: StateService,
    private getArrayPath: GetArrayPathService,
    private eventBus: EventBusService,
  ) { }

  public setLanguage(locale?: string) {
    let activeLanguage = this.state.get('language');
    if (typeof locale !== 'undefined') {
      activeLanguage = locale;
    }

    if (activeLanguage === null) {
      this.state.set('language', this.state.get('languages.default'));
      activeLanguage = this.state.get('languages.default');
    } else {
      this.state.set('language', activeLanguage);
    }
    this.getLabels({ locale: activeLanguage });
    this.activeLocale = activeLanguage;
  }

  public getLanguages() {
    const environment = this.api.getEnv();
    if (environment.showLabelKeyIfNotFound) {
      this.showLabelKeyIfNotFound = environment.showLabelKeyIfNotFound;
    };
    return new Promise<void>((resolve, reject) => {
      const localeService = this.api.getService('LocaleService');
      localeService.getLocales()
        .subscribe((response: any) => {
          response.result.full_locale_map = JSON.parse(JSON.stringify(
            response.result.full_locale_map
          ).replace('_', '-'));
          this.state.set('languages', response.result);
          resolve();
        });
    });
  }

  public getFullLocale() {
    const map = this.state.get('languages.full_locale_map');
    return map[this.activeLocale];
  }

  public getLabels(params?: any) {
    const labelService = this.api.getService('LabelService');
    labelService.getPublished(params)
      .subscribe((response: any) => {
        this.labels = response.result;

        // Fire labels loaded event
        this.eventBus.fire(new Event('labelsLoaded', this.labels));
      });
  }

  private flatten(obj: {}) {
    const flat = {};
    for (const i in obj) {
      if (obj.hasOwnProperty(i)) {
        if (typeof obj[i] === 'object' && obj[i] !== null && !Array.isArray(obj[i])) {
          const flattened = this.flatten(obj[i]);
          for (const ii in flattened) {
            if (flattened.hasOwnProperty(ii)) {
              flat[i + '.' + ii] = flattened[ii];
            }
          }
        } else {
          flat[i] = obj[i];
        }
      }
    }
    return flat;
  }

  public getLabel(label: string | Label, params?: { [key: string]: any }) {
    let key: string;
    let data;
    if (typeof label === 'object' && 'key' in label) {
      params = label.params;
      data = label.data;
      key = label.key;
    } else {
      key = label;
      data = null;
    }
    if (typeof key === 'undefined') {
      return this.showLabelKeyIfNotFound ? key : '';
    }
    for (const index in params) {
      if (key.search('{') < 0) {
        break;
      } else {
        let replacement;
        if (Array.isArray(params[index])) {
          replacement = this.getArrayPath.get(data, params[index]);
        } else {
          replacement = params[index];
        }
        key = key.replace('{' + index + '}', replacement);
      }
    }
    if (
      typeof this.labels[key] === 'undefined'
      && (
        this.showLabelKeyIfNotFound
        || (!key.includes('::') && !key.includes('_')) // return the key if it is not a label but and actual text
      )
    ) {
      return key;
    }
    let text = this.labels[key];
    if (typeof params !== 'undefined') {
      params = this.flatten(params);
      const placeholders = text.match('{.+?}');
      if (Array.isArray(placeholders)) {
        for (const p of placeholders) {
          if (p.search('%') >= 0) {
            // if placeholder is a sub label that varies by a param
            // parse it and extract is text
            let subLabel = p;
            for (const index in params) {
              if (subLabel.search('%') < 0) {
                break;
              } else {
                let replacement;
                if (Array.isArray(params[index])) {
                  replacement = this.getArrayPath.get(data, params[index]);
                } else {
                  replacement = params[index];
                }
                subLabel = subLabel.replace('%' + index + '%', replacement)
                  .replace('{', '')
                  .replace('}', '');
              }
            }
            // replace placeholder with the sub label text
            const subLabelText = this.getLabel(subLabel, params);
            text = text.replace(p, subLabelText);
          }
        }
      }
      for (const index in params) {
        if (text.search('{') < 0) {
          break;
        } else {
          let replacement;
          if (Array.isArray(params[index])) {
            replacement = this.getArrayPath.get(data, params[index]);
          } else {
            replacement = params[index];
          }
          text = text.replace('{' + index + '}', replacement);
        }
      }
    }
    return text;
  }
}
