<mat-form-field
  appearance="fill"
  *ngIf="parentForm"
  [formGroup]="parentForm"
  (click)="datepicker.open()"
>
  <mat-label>{{ language.getLabel(data.placeholder) }}</mat-label>

  <input
    matInput
    [matDatepicker]="datepicker"
    [placeholder]="language.getLabel(data.placeholder)"
    [(value)]="data.value"
    (dateChange)="change($event)"
    [disabled]="data.inputDisabled"
    [formControlName]="data.name"
  />

  <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>

  <mat-datepicker
    #datepicker
    [color]="data.color"
    [opened]="data.opened"
    [disabled]="data.disabled"
    [startView]="data.startView"
  >
  </mat-datepicker>
</mat-form-field>

<mat-form-field
  appearance="fill"
  *ngIf="!parentForm"
  (click)="datepicker.open()"
>
  <mat-label>{{ language.getLabel(data.placeholder) }}</mat-label>

  <input
    matInput
    [matDatepicker]="datepicker"
    [placeholder]="language.getLabel(data.placeholder)"
    [(value)]="data.value"
    (dateChange)="change($event)"
    [disabled]="data.inputDisabled"
  />

  <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>

  <mat-datepicker
    #datepicker
    [color]="data.color"
    [opened]="data.opened"
    [disabled]="data.disabled"
    [startView]="data.startView"
  >
  </mat-datepicker>
</mat-form-field>
