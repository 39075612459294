import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map.component';
import { RoutingService } from '../../backbone/routing.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { PrimitiveModule } from '../primitive/primitive.module';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [MapComponent],
  imports: [
    CommonModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    PrimitiveModule,
    ReactiveFormsModule
  ],
  exports: [MapComponent]
})
export class MapModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('MapComponent', MapComponent);
  }
}
