<mat-toolbar color="primary">
  <button *ngIf="data.hasLeft" mat-icon-button (click)="toggle('left')">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="navbar-brand">
    <a class="navbar-item" [routerLink]="['']">
      <img class="mainLogo" src="assets/img/{{state.get('light_logo')}}" />
    </a>
  </div>
  <!-- This fills the remaining space of the current row -->
  <span class="example-fill-remaining-space"></span>
  <mat-menu #appMenu="matMenu">
    <button mat-menu-item [routerLink]="'/users/profile'">{{language.getLabel('Access_users_profile')}}</button>
    <button mat-menu-item (click)="logout()">{{language.getLabel('App_logout')}}</button>
  </mat-menu>

  <button mat-icon-button [matMenuTriggerFor]="appMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <button *ngIf="data.hasRight" mat-icon-button (click)="toggle('right')">
    <mat-icon>menu</mat-icon>
  </button>
</mat-toolbar>
