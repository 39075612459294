import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommunicationService, Message } from '../../../backbone/communication.service';
import { SlotItem } from '../../slot/slot-item';


interface Slots {
  start: SlotItem[];
  end: SlotItem[];
  header: SlotItem[];
  menu: SlotItem[];
  main: SlotItem[];
  footer: SlotItem[];
}

@Component({
  selector: 'app-center-header-main-footer-sidenav',
  templateUrl: './center-header-main-footer-sidenav.component.html',
  styleUrls: ['./center-header-main-footer-sidenav.component.scss']
})
export class CenterHeaderMainFooterSidenavComponent implements OnInit, OnDestroy {
  @ViewChild('start') private start: MatDrawer;
  @ViewChild('end') private end: MatDrawer;
  private destroyed = new Subject<void>();
  private styleTag: HTMLStyleElement;

  public slots: Slots;
  public autosize = true;
  public mode = {
    start: 'side',
    end: 'side'
  };


  constructor(
    private route: ActivatedRoute,
    private comm: CommunicationService
  ) {
    this.styleTag = this.buildStyleElement();
  }

  ngOnInit(): void {
    this.route.data.pipe(take(1)).subscribe(data => {
      if (typeof data.channel !== 'undefined') {
        this.comm.getChannel(data.channel)
          .pipe(takeUntil(this.destroyed))
          .subscribe((message: Message) => this.comm.processMessage(message, this));
      }
      this.slots = data.slots;
      if (typeof data.autosize !== 'undefined') {
        this.autosize = data.autosize;
      }
      if (typeof data.mode.start !== 'undefined') {
        this.mode.start = data.mode.start;
      }
      if (typeof data.mode.end !== 'undefined') {
        this.mode.end = data.mode.end;
      }
    });
  }

  sidenav(params) {
    this[params.side][params.method]();
  }

  opened(side, event) {
    if (this.mode[side] === 'over') {
      document.body.appendChild(this.styleTag);
    }
  }
  closed(side, event) {
    if (this.mode[side] === 'over') {
      document.body.removeChild(this.styleTag);
    }
  }

  private buildStyleElement(): HTMLStyleElement {

    const style = document.createElement('style');

    style.textContent = `
			body {
				overflow: hidden !important ;
			}
		`;

    return (style);

  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.comm.resetChannels();
  }
}
