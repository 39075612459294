import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '../../../backbone/language.service';
import { ISlotComponent } from '../../slot/slot-component';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent implements OnInit, ISlotComponent {
  @Input() public data: any;

  constructor(public language: LanguageService) { }

  ngOnInit(): void {
  }

}
