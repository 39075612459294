import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  public permissions: [];
  public roles: any;
  public superAdmin: boolean;

  constructor(
    private api: ApiService,
    private state: StateService
  ) { }

  setPermissions(permissions: any) {
    this.permissions = permissions;
  }

  setRoles(roles: Array<object>) {
    this.roles = roles;
  }

  setSession(session: any) {
    this.setRoles(session.user.roles);
    this.setPermissions(session.permissions);
    this.isSuperAdmin();
  }

  checkSession() {
    return new Promise<void>((resolve) => {
      const userService = this.api.getService('UserService');
      userService.checkSession()
        .subscribe((response: any) => {
          this.setSession(response.result);
          if (typeof response.result.user.id !== 'undefined') {
            this.state.set('auth', 'true');
          }
          resolve();
        });
    });
  }

  checkPermissions(object: any) {
    let result = true;
    if (!this.isSuperAdmin()
      && typeof object.requiredPermissions !== 'undefined'
    ) {
      if (
        typeof object.requireAnyPermission !== 'undefined'
        && object.requireAnyPermission === true
      ) {
        // match any permission to allow access
        result = false;
        object.requiredPermissions.forEach(element => {
          if (typeof this.getPermissions(element) !== 'undefined') {
            result = true;
          }
        });
      } else {
        // match all permissions to allow access
        object.requiredPermissions.forEach(element => {
          if (typeof this.getPermissions(element) === 'undefined') {
            result = false;
          }
        });
      }
    }
    return result;
  }

  getPermissions(key?: string) {
    if (typeof key !== 'undefined' && key !== '') {
      return this.permissions[key];
    }
    return this.permissions;
  }

  isSuperAdmin() {
    this.superAdmin = false;
    this.roles.forEach(role => {
      if (role.key === 'super_admin') {
        this.superAdmin = true;
      }
    });
    return this.superAdmin;
  }
}
