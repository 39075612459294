import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';
import { PermissionsService } from './permissions.service';
import { Title } from '@angular/platform-browser';
import { Router, RoutesRecognized } from '@angular/router';
import { SessionService } from './session.service';
import { StateService } from './state.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SetupService {

  constructor(
    public language: LanguageService,
    public permissions: PermissionsService,
    private state: StateService,
    private session: SessionService,
    private titleService: Title,
    private router: Router,
    private api: ApiService
  ) {
    this.router.events.subscribe(e => {
      if (e instanceof RoutesRecognized) {
        let lastUrlStack;
        try {
          lastUrlStack = this.session.get('lastUrl');
          if (lastUrlStack[lastUrlStack.length - 1] !== e.urlAfterRedirects) {
            lastUrlStack.push(e.urlAfterRedirects);
            if (lastUrlStack.length > 2) {
              lastUrlStack.shift();
            }
            lastUrlStack = JSON.stringify(lastUrlStack);
            this.session.set('lastUrl', lastUrlStack);
          }
        } catch (err) {
          lastUrlStack = JSON.stringify([e.urlAfterRedirects]);
          this.session.set('lastUrl', lastUrlStack);
        }
      }
    });
  }

  init() {
    const environment = this.api.getEnv();
    this.state.set('light_logo', environment.light_logo);
    this.state.set('dark_logo', environment.dark_logo);
    this.titleService.setTitle(environment.title);
    this.language.setLanguage();
  }
}
