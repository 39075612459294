import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoutingService } from '../../backbone/routing.service';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { PrimitiveModule } from '../primitive/primitive.module';

@NgModule({
  declarations: [
    BreadcrumbsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PrimitiveModule
  ]
})
export class BreadcrumbsModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('BreadcrumbsComponent', BreadcrumbsComponent);
  }
}
