import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommunicationService, Message } from '../../../backbone/communication.service';

@Component({
  selector: 'app-html',
  templateUrl: './html.component.html',
  styleUrls: ['./html.component.scss']
})
export class HtmlComponent implements OnInit, OnDestroy {
  @Input() public data: any = {};
  public rawHTML: any;
  private destroyed: Subject<void> = new Subject();

  constructor(private comm: CommunicationService, public sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }

    this.rawHTML = this.sanitizer.bypassSecurityTrustHtml(this.data.dataObject);
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}