import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'join',
  pure: true
})
export class JoinPipe implements PipeTransform {

  transform(input: any, separator: string = '_'): any {
    if (!Array.isArray(input)) {
      return input;
    }
    return input.join(separator);
  }

}
