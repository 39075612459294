import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ISlotComponent } from '../../slot/slot-component';
import { UntypedFormGroup } from '@angular/forms';
import { LanguageService } from '../../../backbone/language.service';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { GetArrayPathService } from '../../../backbone/get-array-path.service';
import { CommunicationService, Message } from '../../../backbone/communication.service';
import { ApiService } from '../../../backbone/api.service';

@Component({
  selector: 'app-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss']
})
export class RadiobuttonComponent implements OnInit, OnDestroy, ISlotComponent {
  @Input() public data: any = {};
  @Input() public parentForm: UntypedFormGroup;
  public dataObject;
  public selected;
  protected destroyed = new Subject<void>();

  constructor(
    public language: LanguageService,
    private getArrayPath: GetArrayPathService,
    private comm: CommunicationService,
    private api: ApiService
  ) { }

  ngOnInit() {
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }
    this.load();
  }

  public removeSelected() {
    this.selected = null;
  }

  public load(selected = null) {
    if (typeof this.data.dataSource !== 'undefined') {
      this.api.callServiceMethod(this.data.dataSource)
        .pipe(take(1))
        .subscribe((response: any) => {
          this.dataObject = response.result.data;
          this.data.options = this.getArrayPath.get(this.dataObject, this.data.optionsPath);
          if (selected !== null) {
            this.selected = selected;
          } else if (typeof this.data.group.selected !== 'undefined') {
            if (this.data.group.selected === 'first') {
              this.selected = this.getValue(this.data.options[0]);
            } else {
              this.selected = this.data.group.selected;
            }
          }
        });
    } else if (typeof this.data.dataObject !== 'undefined') {
      // if options come from dataObject of the parent component
      this.data.options = this.getArrayPath.get(this.data.dataObject, this.data.optionsPath);

      // if selected is an array of paths that point to the property that has to be truthy
      // get the option that is truthy
      if (Array.isArray(this.data.group.selected)) {
        const selectedOption: any = this.data.options.find(option => {
          if (this.getArrayPath.get(option, this.data.group.selected)) {
            return true;
          } else {
            return false;
          }
        });

        // selected is the value of the option that has the truthy propery
        this.selected = this.getArrayPath.get(selectedOption, this.data.valuePath);
      } else if (typeof this.data.group.selected === 'number' ||
                 typeof this.data.group.selected === 'string'){
        // if selected is hardcoded string or number, that is the value of the checked radio button
        this.selected = this.data.group.selected;
      }
    } else {
      if (this.data.group?.selected) {
        this.selected = this.data.group.selected
      }
    }
  }

  change(event: any) {
    if (typeof this.data.change === 'function') {
      if (!this.data.changeParams) {
        this.data.changeParams = {
          value: event.value
        };
      } else {
        this.data.changeParams.value = event.value;
      }
      this.data.changeParams.event = 'change';
      const result = this.data.change(this.data.changeParams);
      if (result instanceof Observable) {
        result.pipe(take(1)).subscribe();
      }
    }
  }

  public getValue(option) {
    if (typeof option.option !== 'undefined') {
      return option.option;
    }
    if (typeof this.data.valuePath !== 'undefined') {
      return this.getArrayPath.get(option, this.data.valuePath);
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
