<ng-container *ngIf="parentForm" [formGroup]="parentForm">
  <mat-slide-toggle
    [color]="data.color"
    [labelPosition]="data.labelPosition"
    [disabled]="data.disabled"
    [(checked)]="data.checked"
    (change)="change($event)"
    [formControlName]="data.name"
  >
    {{ language.getLabel(data.label) }}
  </mat-slide-toggle>
</ng-container>
<ng-container *ngIf="!parentForm">
  <mat-slide-toggle
    [color]="data.color"
    [labelPosition]="data.labelPosition"
    [disabled]="data.disabled"
    [(checked)]="data.checked"
    (change)="change($event)"
  >
    {{ language.getLabel(data.label) }}
  </mat-slide-toggle>
</ng-container>
