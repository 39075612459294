import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableListComponent } from './table-list.component';
import { RoutingService } from '../../../backbone/routing.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { PrimitiveModule } from '../../primitive/primitive.module';
import { PipesModule } from '../../../backbone/pipes/pipes.module';
import { SlotModule } from '../../slot/slot.module';



@NgModule({
  declarations: [TableListComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    PrimitiveModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    PipesModule,
    SlotModule
  ]
})
export class TableListModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('TableListComponent', TableListComponent);
  }
}
