import { NgModule, ErrorHandler, APP_INITIALIZER, COMPILER_OPTIONS, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
   RoutingService,
   ApiService,
   ErrorService,
   AlertDialogModule,
   ToastModule,
   PrimitiveModule,
   LanguageService,
   PermissionsService,
   LoaderInterceptor,
   LoaderModule,
   SlotDialogModule,
   QueryPipesModule,
   LayoutsModule,
   GoogleTranslateService,
   GOOGLE_TRANSLATE_API_KEY
} from 'ng-viv-ux';
import LocaleBG from '@angular/common/locales/bg';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

registerLocaleData(LocaleBG);


export function initApp(
   routing: RoutingService,
   permissions: PermissionsService,
   language: LanguageService,
   api: ApiService
) {
   return (): Promise<any> => {
      return api.loadSdk(environment).then(() => {
         return language.getLanguages().then(() => {
            return permissions.checkSession().then(() => {
               return routing.generateRoutes();
            });
         });
      });
   };
}

@NgModule({
   declarations: [
      AppComponent
   ],
   imports: [
      BrowserModule,
      HttpClientModule,
      BrowserAnimationsModule,
      CommonModule,
      AppRoutingModule,
      PrimitiveModule,
      LayoutsModule,
      AlertDialogModule,
      SlotDialogModule,
      LoaderModule,
      ToastModule,
      QueryPipesModule,
      RecaptchaV3Module
   ],
   providers: [
      RoutingService,
      LanguageService,
      PermissionsService,
      CurrencyPipe,
      DecimalPipe,
      GoogleTranslateService,
      {
         provide: APP_INITIALIZER,
         useFactory: initApp,
         deps: [RoutingService, PermissionsService, LanguageService, ApiService],
         multi: true
      },
      {
         provide: COMPILER_OPTIONS,
         useValue: {},
         multi: true
      },
      {
         provide: ErrorHandler,
         useClass: ErrorService
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: LoaderInterceptor,
         multi: true
      },
      { provide: LOCALE_ID, useValue: 'en-EN' },
      { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaApiToken },
      { provide: GOOGLE_TRANSLATE_API_KEY, useValue: environment.googleTranslateKey }
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
