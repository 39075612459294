import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getObjectToQueryParams',
    pure: true
})
export class ObjectToQueryParamsPipe implements PipeTransform {
    transform(queryObject: object): object {
        // tslint:disable-next-line: forin
        for (const group in queryObject) {
            let result = '';
            let count = 0;

            // tslint:disable-next-line: forin
            for (const param in queryObject[group]) {
                count++;

                if (typeof queryObject[group][param] === 'object') {
                    let tmpResult = param + ':' + queryObject[group][param].constraint;
                    if (typeof queryObject[group][param].values !== 'undefined') {
                        let values = queryObject[group][param].values;
                        if (Array.isArray(values)) {
                            values = values.join(',');
                        } else if (typeof values === 'object') {
                            if (values.values === '()') {
                                continue;
                            }
                            values = Object.values(values).join(':');
                        }

                        let operator = '';
                        if (queryObject[group][param].operator) {
                            operator = ':' + queryObject[group][param].operator;
                        }
                        tmpResult += operator + ':(' + values + ')';
                    } else if (typeof queryObject[group][param].subGroup !== 'undefined') {
                        tmpResult += ':';
                        // tslint:disable-next-line: forin
                        for (const subGroup in queryObject[group][param].subGroup) {
                            let values = queryObject[group][param].subGroup[subGroup].values;
                            if (Array.isArray(values)) {
                                values = values.join(',');
                            } else if (typeof values === 'object') {
                                values = Object.values(values).join(':');
                            }
                            let operator = '';
                            if (queryObject[group][param].subGroup[subGroup].operator) {
                                operator = ':' + queryObject[group][param].subGroup[subGroup].operator;
                            }
                            tmpResult += '(' + subGroup + operator + ':';
                            tmpResult += queryObject[group][param].subGroup[subGroup].constraint;
                            tmpResult += ':(' + values + '))';
                        }
                    }
                    result += tmpResult;
                } else {
                    result += param + ':(' + queryObject[group][param] + ')';
                }

                if (Object.keys(queryObject[group]).length !== count) {
                    // if not last group
                    result += ';';
                }
            }

            queryObject[group] = result;
        }
        return queryObject;
    }
}
