<ngx-charts-bar-horizontal
  [view]="data.view"
  [scheme]="data.colorScheme"
  [results]="data.value"
  [gradient]="data.gradient"
  [xAxis]="data.showXAxis"
  [yAxis]="data.showYAxis"
  [legend]="data.showLegend"
  [showXAxisLabel]="data.showXAxisLabel"
  [showYAxisLabel]="data.showYAxisLabel"
  [xAxisLabel]="data.xAxisLabel"
  [yAxisLabel]="data.yAxisLabel"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)">
</ngx-charts-bar-horizontal>